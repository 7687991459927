<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="col-span-12 lg:col-span-6">
                        <div class="box">
                            <div class="box-header">

                                <button  :style="myscreenSize<=990?'width: 100%!important;':''"  v-if="Items.docnum!==''" @click="Misecommandeenattente" class="mt-2 mb-2 ti-btn ti-btn-danger-full" >
                                  Ajouter la commande à la liste d'attente
                                </button>

                                <button :style="myscreenSize<=990?'width: 100%!important;':''"  @click="btnupdatefactureselected" class="mt-2 mb-2 ti-btn ti-btn-purple-full" >
                                 Commandes en file d'attente <span v-if="nbrefactureenattante>0" style="color: red">{{nbrefactureenattante}}</span>
                                </button>

                                <button :style="myscreenSize<=990?'width: 100%!important;':''"   @click="btnupdateentetecommande"  class="mt-2 mb-2 ti-btn ti-btn-primary-full">
                                    &nbsp;&nbsp;Entête commande
                                </button>


                            </div>
                            <div class="box-body">


                                <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                    <div>
                                        {{messagesucces}}
                                    </div>
                                </div>

                                <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                    <div>
                                        {{messageerror}}
                                    </div>
                                </div>


                                <div class="alert alert-info text-center mt-3">
                                    <p style="color: black;font-size: 15px;">Date : {{Items.datedoc}}  <template v-if="Items.docnum!==''"> / {{Items.docnum}}</template> </p>
                                </div>

                                <p class="text-[.9375rem] font-semibold mb-2">CLIENT :  {{Items.jsonv.client}}   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   Mode de réglement :  {{(Items.jsonv.modereglement!==null)?Items.jsonv.modereglement.name:''}}</p>

                                <p class="text-[.9375rem] font-semibold mb-2">Serveur :  {{(Items.jsonv.collaborateur!==null)?Items.jsonv.collaborateur.name:''}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Table :  {{(Items.jsonv.commandetable!==null)?Items.jsonv.commandetable.name:''}}</p>




                                <div class="relative">



                                    <div class="relative rounded-sm overflow-auto">


                                        <!--div class="sm:grid space-y-8 sm:space-y-0 grid-rows-4 grid-flow-col gap-4 font-mono text-defaulttextcolor
                                        text-sm text-center font-bold rounded-sm"-->


                                        <div class="sm:grid space-y-8 sm:space-y-0 grid-cols-3 grid-flow-col gap-4 font-mono text-defaulttextcolor
                                        text-sm text-center font-bold rounded-sm">



                                            <div class="p-4 rounded-sm  bg-light space-y-8 sm:space-y-0 col-span-2">


                                                <div  v-if="myscreenSize>990"   class="box-body !p-0 mybodyheight500px">


                                                    <div class="table-responsive">

                                                        <div class="flex flex-col h-screen550">
                                                            <div class="flex-grow overflow-auto">

                                                                <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                                                    <thead>
                                                                    <tr>

                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">ETAT</th>
                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center" colspan="2">DESIGNATION</th>
                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">PU</th>
                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">QUANTITE</th>
                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">REMISE</th>
                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">MONTANT</th>
                                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">ACTION</th>

                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    <template v-if="listedatatable.length">

                                                                        <template  v-for="(datavalue,index) in listedatatable" :key="index">
                                                                            <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light">

                                                                                <td  data-label="ETAT  : "   >

                                                                                    <span v-if="datavalue.etatline===1 || datavalue.etatline==='1'" class="badge mbadge !rounded-full bg-success/10 text-success">OK</span>
                                                                                    <span v-if="datavalue.etatline===2 || datavalue.etatline==='2' || datavalue.etatline===4 || datavalue.etatline==='4'" class="badge mbadge !rounded-full bg-danger/10 text-danger">Erreur</span>
                                                                                    <span v-if="datavalue.etatline===0 || datavalue.etatline==='0'" class="badge mbadge !rounded-full bg-info/10 text-info">En attente</span>

                                                                                </td>
                                                                                <td  data-label="DESIGNATION  : " colspan="2"  >
                                                                                    <template v-if="datavalue.autredesignation===''">
                                                                                        {{ datavalue.article.libelle }}
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        {{ datavalue.article.libelle+' ( '+datavalue.autredesignation+' )'}}
                                                                                    </template>

                                                                                </td>
                                                                                <td  data-label="PU  : "   >{{ datavalue.pu }}</td>
                                                                                <td  data-label="QUANTITE  : "   >{{ formatNumber2(datavalue.qtite) }}</td>
                                                                                <td  data-label="REMISE  : "   >{{ datavalue.remise }}</td>
                                                                                <td  data-label="MONTANT  : "   >{{ currencyFormatDE(datavalue.calmtntht) }}</td>
                                                                                <!--td  data-label="MONTANT  : "   >{{ calculSomme(datavalue.mtnt,datavalue.remise) }}</td-->


                                                                                <td  data-label="ACTION  : "   >


                                                                                    <a @click="btnupdateqteitem(datavalue)" aria-label="anchor" href="javascript:void(0);" class="ti-btn ti-btn-icon ti-btn-sm ti-btn-info">
                                                                                        <i class="ri-edit-line"></i>
                                                                                    </a>
                                                                                    <a @click="deleteItemArticle(datavalue)" aria-label="anchor" href="javascript:void(0);" class="ti-btn ti-btn-icon ti-btn-sm ti-btn-danger ml-5">
                                                                                        <i class="ri-delete-bin-line"></i>
                                                                                    </a>


                                                                                </td>

                                                                            </tr>
                                                                            <tr v-if="datavalue.etatline===2">
                                                                                <td colspan="20"><div class="alert alert-danger text-center" v-html="datavalue.linemessage"></div></td>
                                                                            </tr>

                                                                        </template>

                                                                    </template>


                                                                    <template v-else>
                                                                        <tr >
                                                                            <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                        </tr>

                                                                    </template>



                                                                    </tbody>

                                                                    <tfoot v-if="listedatatable.length">


                                                                    <tr class="border border-defaultborder">
                                                                        <td style="text-align: end;" colspan="6" >MONTANT HT</td>
                                                                        <td>{{currencyFormatDE(Items.mtnt.doccalmtnttotalht)}}</td>
                                                                        <!--td>{{calculSomme(Items.mtnt.docmtnttotalht,Items.mtnt.docmtntremise)}}</td-->
                                                                        <td></td>
                                                                    </tr>

                                                                    <tr v-if="parseFloat(Items.mtnt.docmtntremise)>0" class="border border-defaultborder">
                                                                        <td style="text-align: end;" colspan="6" >REMISE</td>
                                                                        <td>{{currencyFormatDE(Items.mtnt.docmtntremise)}}</td>
                                                                        <td></td>
                                                                    </tr>


                                                                    <tr class="border border-defaultborder">
                                                                        <td style="text-align: end;" colspan="6" >NET A PAYER</td>
                                                                        <td>{{currencyFormatDE(Items.mtnt.docmtnttotalttc)}}</td>
                                                                        <td></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style="    border-bottom: 0px solid #e9e9e9 ! important" colspan="4"></td>
                                                                        <td colspan="3"> <p class="text-center mt-0 mb-0" style="text-transform: uppercase">Montant reçu</p>  </td>
                                                                        <td colspan="2">   <input v-model="Items.jsonotherdonne.montantrecu" style="text-align: end;" min="0" class="form-control" type="number"> </td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td style="    border-bottom: 0px solid #e9e9e9 ! important" colspan="4"></td>
                                                                        <td colspan="3"> <p class="text-center mt-0 mb-0 text-uppercase" style="text-transform: uppercase">Reliquat</p>  </td>
                                                                        <td colspan="2">   <h2  style="text-align: end;">{{reliquat}}</h2> </td>
                                                                    </tr>




                                                                    </tfoot>


                                                                </table>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>




                                            </div>

                                            <div class="flex flex-col gap-2">
                                            <div class="p-4 rounded-sm bg-light  space-y-8 sm:space-y-0 " style="overflow-x: scroll">

                                                <div class="flex items-start gap-2 overflow-x-auto">
                                                    <nav aria-label="Tabs" class="flex flex-nowrap space-x-2">

                                                        <a @click="selectedcatalogue=''" class=" hs-tab-active:bg-primary/10 inline-flex hs-tab-active:text-primary  py-[0.4rem]
                                                        px-2 text-sm font-medium text-center rounded-sm hover:text-primary
                                                            hover:bg-primary hover:text-white hover:cursor-pointer"
                                                           :class="selectedcatalogue===''?'activebtn':''"
                                                           style="width: max-content!important;cursor: pointer!important;"  >
                                                            All
                                                        </a>

                                                        <a v-for="(line,index) in datatablearticlegroupe" :key="index" @click="selectedcatalogue=index"
                                                           class=" hs-tab-active:bg-primary/10 inline-flex hs-tab-active:text-primary  py-[0.4rem]
                                                        px-2 text-sm font-medium text-center rounded-sm hover:text-primary
                                                            hover:bg-primary hover:text-white hover:cursor-pointer"
                                                           :class="selectedcatalogue===index?'activebtn':''"
                                                           style="width: max-content!important;cursor: pointer!important;"
                                                           >
                                                            {{index}}
                                                        </a>





                                                        <!-- Répétez les éléments ci-dessus si nécessaire -->
                                                    </nav>
                                                </div>



                                            </div>
                                            <div class="p-4 rounded-sm  bg-light space-y-8 sm:space-y-0 ">


                                                <div class="mt-4 mb-4">
                                                    <input v-model="searchword" ref="modalInput" class="form-control form-control-lg custom-placeholder" type="text"
                                                           placeholder="Rechercher un article"
                                                           aria-label="form-control-lg example">
                                                </div>
                                                <div class="box-body !p-0 mybodyheight400px">



                                                    <div class="table-responsive">

                                                        <div class="flex flex-col h-screen">
                                                            <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">

                                                                <ul class="list-group list-group-flush max-h-[22.5rem]">
                                                                    <li class="list-group-item !border-t-0 !border-s-0 !border-e-0 bottomline"
                                                                        v-for="(datavalue,j) of filterdatatable" :key="j"
                                                                        @dblclick="selectionitemforfacturation(datavalue)"
                                                                    >
                                                                        <div class="flex items-center flex-grow leading-none
                                                                             hoveritemsarticle rounded-sm  "
                                                                             style="cursor: pointer!important;" >


                                                                            <div class="leading-none flex-grow">
                                                                                <span class="mt-2 mb-2 font-semibold block mb-2">{{datavalue.libelle}}</span>
                                                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Stock: {{ datavalue.rstck }}</span>
                                                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Accompagnement: {{(datavalue.accompagnement.length>0)?'Oui':'Non' }}</span>
                                                                            </div>
                                                                            <div class="text-end me-3">

                                                                             <span class="block font-semibold mb-1 ql-color-red">
                                                                                {{formatNumber(datavalue.pu) }}
                                                                            </span>
                                                                            </div>
                                                                            <!--div>
                                                                                                            <span class="text-[0.875rem]">
                                                                                                                <i class="bi bi-star-fill text-[#8c9097] dark:text-white/50 opacity-50"></i>
                                                                                                            </span>
                                                                            </div-->
                                                                        </div>
                                                                    </li>



                                                                </ul>






                                                            </div>
                                                        </div>

                                                    </div>




                                                </div>



                                            </div>


                                        </div>


                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="ti-modal-footer"  :style="myscreenSize<=990?'display: contents!important;':''" >

                    <button style="background: red;" @click="close"  type="button" class="mt-2 mb-2 hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        <i class="ri-close-fill"></i>  Fermer
                    </button>


                    <button v-if="Items.docnum!==''"  :disabled="compteprocess>0"  @click="Misecommandeenattente"  class="mt-2 mb-2 ti-btn ti-btn-purple-full" >
                        <i class="ri-add-fill"></i>  Ajouter la commande à la liste d'attente <template v-if="compteprocess>0">({{compteprocess}})</template>
                    </button>

                    <button :disabled="compteprocess>0" @click="note"  class="mt-2 mb-2 ti-btn ti-btn-primary-full">
                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="ri-printer-line"></i> Note &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<template v-if="compteprocess>0">({{compteprocess}})</template>
                    </button>

                    <button style="background: green" :disabled="compteprocess>0"  class="mt-2 mb-2 ti-btn ti-btn-primary-full" @click="saveItems">
                        <i class="ri-calculator-fill"></i>  Comptabilisé <template v-if="compteprocess>0">({{compteprocess}})</template>
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modalentetecommande ref="modalentetecommande"  @updateentetecommande="updateentetecommande"></modalentetecommande>
    <modalselectprice ref="modalselectprice"  @chekprice="checkprice"></modalselectprice>
    <modalaccompagnement ref="modalaccompagnement"  @selectaccompagnement="checkaccompagnement"></modalaccompagnement>
    <modalmodifierquantite ref="modalmodifierquantite"  @updateqteitem="updateqteitem"></modalmodifierquantite>
    <modalfactureenattente ref="modalfactureenattente"  @selectedfacture="updatefactureselected"></modalfactureenattente>

    <modalfacturecomptoirepost ref="modalfacturecomptoirepost" @closed="closeimpression" ></modalfacturecomptoirepost>




</template>

<script>


    import modalfacturecomptoirepost from "@/components/views/pages/caisse/rapport/modal_facturecomptoirepost";

    const axios =require('axios');

    import modalentetecommande from "@/components/views/pages/caisse/facturation/2/modal_entete";
    import modalselectprice from "@/components/views/pages/caisse/facturation/2/modal_selectprice";
    import modalaccompagnement from "@/components/views/pages/caisse/facturation/2/modal_accompagnement";
    import modalmodifierquantite from "@/components/views/pages/caisse/facturation/2/modal_modifierquantite";
    import modalfactureenattente from "@/components/views/pages/caisse/facturation/2/modal_factureenattente";

    import {
        array_search,
        cloneobjet,
        currencyFormatDE,
        FormatNumber,
        groupByKey,
        isset,
        randomString
    } from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import {mapGetters} from "vuex";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            ...mapGetters(['listedesarticlespourlacaisse','myscreenSize']),



            datatablearticlegroupe(){

                //let mvalue=cloneobjet(this.listedesarticlespourlacaisse);

                this.datatablearticle=cloneobjet(this.listedesarticlespourlacaisse);

                let $groupbycatalogue=groupByKey(this.listedesarticlespourlacaisse,'catalogue');

                //this.datatablearticlegroupe=$groupbycatalogue

                return $groupbycatalogue ;
            },

            nbrefactureenattante(){
               return  this.datatable.length
            },

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=[];

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /*if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.removeline===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.selectedcatalogue!==''){
                    let t=this;


                    for (let [index, datavalue] of Object.entries(this.datatablearticlegroupe)) {
                        console.log("index ",index)
                        // Code à exécuter pour chaque entrée
                        console.log(index, datavalue);

                        if (index.toLowerCase()===this.selectedcatalogue.toLowerCase()){


                            for (let line of datavalue){

                                mvalue.push(line)
                            }


                        }



                    }



                }
                else{

                    for (let [index, datavalue] of Object.entries(this.datatablearticlegroupe)) {



                        for (let line of datavalue){

                            mvalue.push(line)
                        }


                    }


                }


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.libelle.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }


                return mvalue

                //return this.paginate(mvalue) ;
            },

            montant()
            {




                let montancalcul=0;

                if(this.ItemsArticle.article!==null ){


                    if (this.ItemsArticle.qtite!==0 )
                    {

                        montancalcul=parseFloat(this.ItemsArticle.pu)*parseFloat(this.ItemsArticle.qtite);


                    }


                    if (isNaN(this.ItemsArticle.remise)) {
                        this.ItemsArticle.remise=0;
                    }


                    if (this.ItemsArticle.remise!==0 && !isNaN(this.ItemsArticle.remise) )
                    {
                        montancalcul=montancalcul-parseFloat(this.ItemsArticle.remise);
                    }

                }
                else {
                    this.ItemsArticle.pu=0;
                }
                montancalcul= Math.round(montancalcul)
                //this.ItemsArticle.mtnt=montancalcul;

                return montancalcul


            },

            reliquat(){

                return this.calculMonnaie(this.Items.mtnt.docmtnttotalttc,this.Items.jsonotherdonne.montantrecu);
            },




        },
        components: {
            modalselectprice,
            modalaccompagnement,
            modalentetecommande,
            modalmodifierquantite,
            modalfactureenattente,
            modalfacturecomptoirepost,


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                otherdatatable:{},
                datatablearticle:[],
                /*datatablearticlegroupe:[],*/
                datatiers:[],
                listedatatable:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                compteprocess:0,

                selectedcatalogue:"",


                ItemsArticle:{
                    accompagnement:[],
                    autredesignation:'',
                    mycheckkey:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    calmtntht:0,
                    etatline:0,
                    pid:''





                },
                Items: {},





                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 4,
                Defaultpage: 1,
                DefaultperPage: 4,
                pages: [],
            };
        },
        methods: {


            calculSomme(p1,p2,p3){

                var number=parseFloat(p1)+parseFloat(p2)+parseFloat(p3)
                return currencyFormatDE(number)
            },
            calculMonnaie(p1,p2){
                var number=parseFloat(p2)-parseFloat(p1)

                return currencyFormatDE(number)
            },

            note(){

                let t=this
                if (this.listedatatable.length>0){

                    let items=[];

                    this.listedatatable.forEach(function (line) {
                        if (line.etatline!==2){ items.push(line) }
                    })

                    let tabvalue={
                        numdoc:this.Items.docnum,
                        date:this.Items.datedoc,
                        client:this.Items.jsonv.client,
                        ifu:this.Items.jsonv.ifu,
                        params:'',
                        params4:'',
                        boutique:'',
                        serveur:(this.Items.jsonv.collaborateur!==null)?this.Items.jsonv.collaborateur.name:'',
                        commandetable:(this.Items.jsonv.commandetable!==null)?this.Items.jsonv.commandetable.name:'',
                        vendeur:this.$store.state.appusername,
                        items:items,
                        montantht:this.calculSomme(this.Items.mtnt.docmtnttotalht,this.Items.mtnt.docmtntremise,0),
                        montanttva:0,
                        montantremise:currencyFormatDE(this.Items.mtnt.docmtntremise),
                        montantnetapayer:this.Items.mtnt.docmtnttotalttc,
                        reglement:this.Items.jsonotherdonne.montantrecu,
                        /*montantmonnaie:this.calculMonnaie(this.Items.mtnt.docmtnttotalttc,this.Items.jsonotherdonne.montantrecu),*/
                        montantmonnaie:0,
                        pourcentageprisenecharge:0,
                        docsaisiepar:this.$store.state.appusername,

                        normalise:'',
                        mecefsignature:'',
                        mecefnim:'',
                        mecefcompteur:'',
                        mecefheure:'',
                        mecefqrr:"",
                    }


                    if (t.Items.jsonv.param!==null){tabvalue.params=t.Items.jsonv.param.name}


                    this.$refs.modalfacturecomptoirepost.show(tabvalue,'note');


                }


            },

            closeimpression(element){


                if (element!=='note' && element==='facture'){

                    this.newinvoice()

                    this.load()

                }

            },

             btnupdatefactureselected(){

                this.$refs.modalfactureenattente.show(this.datatable);

            },

            btnupdateentetecommande(){



                this.$refs.modalentetecommande.show(this.Items,this.otherdatatable);


            },

            btnupdateqteitem(datavalue){

               let mitems=cloneobjet(datavalue);

                this.$refs.modalmodifierquantite.show(mitems);

                },


            checkmycheckkey($mycheckkey){
                let resvalue={};
                if(this.listedatatable.length !==0){

                    let dataSearch=[];
                    dataSearch = this.listedatatable.filter(function (article) {

                        return  article.mycheckkey ===$mycheckkey
                    });
                    if(dataSearch.length !==0){
                        //resvalue=dataSearch[0];
                        let mvalue=dataSearch[0];


                        resvalue={
                            linekey:mvalue.linekey,
                            etatline:mvalue.etatline,
                            calmtntht:mvalue.calmtntht,
                            accompagnement:mvalue.accompagnement,
                            mycheckkey:mvalue.mycheckkey,
                            article:mvalue.article,
                            qtite:mvalue.qtite,
                            diqte:0,
                            pu:mvalue.ppu,
                            pa:mvalue.pa,
                            mtnt:mvalue.mtnt ,
                            docnumother:"" ,
                            remise:mvalue.remise,
                            //docnum:value.numdoc,
                            autredesignation:mvalue.autredesignation,
                            doctp:mvalue.doctyp,
                            pid:mvalue.pid,
                            docpid:mvalue.docnum,
                            docnum:mvalue.docnum
                        };

                        //console.log(resvalue)


                    }

                }

                return resvalue;

            },

            updateqteitem($datavalue){

               // console.log("$datavalue ***************** ",$datavalue)

                this.ItemsArticle=$datavalue.item
                this.ItemsArticle.qtite=$datavalue.newqte

                this.ItemsArticle.mtnt=this.montant;
                this.SaveArticle();


            },

           async updatefactureselected($datavalue){

               // console.log("$datavalue ***************** ",$datavalue)

               let value=$datavalue.item

               this.Items=value


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+value.pid+'/'+this.$store.state.appsociete+'/allline895418init51comptoirerefdepdpcc11515/option2/'+randomString(280);
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                       // t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            //value.alllignedoc=res.data.m;


                            t.Items.jsonotherdonne.montantrecu=0

                            //  console.log("value-Items ********* ",Items)

                            t.ItemsArticle.docnum=value.numdoc;
                            t.Items.docnum=value.numdoc;
                            t.Items.docpid=value.pid;
                            t.ItemsArticle.docnum=value.pid;
                            t.ItemsArticle.docpid=value.pid;
                            t.Items.pid=value.pid;

                            t.listedatatable=res.data.m
                            t.calcullistedatatable();


                            t.Items.jsonotherdonne.mtypprixdevente='prixvente'
                        }
                        else{
                           // t.datatable=[];
                           // t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })



            },

            checkaccompagnement($datavalue){


             if ($datavalue.checkprice===1){




                 let $mycheckkey=$datavalue.item.pid+'--prix--'+$datavalue.dataprice[0]+'--accompagnement--'+$datavalue.accompagnement.pid



                 let $reschek=this.checkmycheckkey($mycheckkey)

                 if (Object.keys($reschek).length>0){

                     this.ItemsArticle=$reschek
                     this.ItemsArticle.qtite++
                     //this.ItemsArticle.article=$item
                     this.gestionPu($datavalue.dataprice[0])
                     this.ItemsArticle.mtnt=this.montant;
                     this.SaveArticle();

                 }
                 else{

                     this.ItemsArticle.accompagnement.push($datavalue.accompagnement)

                     this.ItemsArticle.autredesignation=$datavalue.accompagnement.name

                     this.ItemsArticle.mycheckkey=$mycheckkey
                     this.ItemsArticle.qtite=1
                     this.ItemsArticle.article=$datavalue.item
                     this.gestionPu($datavalue.dataprice[0])
                     this.ItemsArticle.mtnt=this.montant;
                     this.SaveArticle();


                 }

                }
                else{

                 //this.ItemsArticle.accompagnement.push($datavalue.accompagnement)

                 //this.ItemsArticle.autredesignation=$datavalue.accompagnement.name




                 this.$refs.modalselectprice.show($datavalue.dataprice,$datavalue.item,$datavalue.accompagnement);

                }

            },

            checkprice($datavalue){

               // console.log("$datavalue ************ ",$datavalue)




                let $mycheckkey="";

                if (Object.keys($datavalue.accompagnement).length>0){


                    $mycheckkey=$datavalue.item.pid+'--prix--'+$datavalue.price+'--accompagnement--'+$datavalue.accompagnement.pid
                    this.ItemsArticle.autredesignation=$datavalue.accompagnement.name

                    console.log("$mycheckkey ********** ",$mycheckkey)
                }
                else{
                    $mycheckkey=$datavalue.item.pid+'--prix--'+$datavalue.price+'--accompagnement--'

                }

                let $reschek=this.checkmycheckkey($mycheckkey)

                //console.log("$reschek ***************** ",$reschek)

                if (Object.keys($reschek).length>0){

                    this.ItemsArticle=$reschek
                    this.ItemsArticle.qtite++
                    //this.ItemsArticle.article=$item
                    this.gestionPu($datavalue.price)
                    this.ItemsArticle.mtnt=this.montant;
                    this.SaveArticle();

                }
                else{
                    this.ItemsArticle.mycheckkey=$mycheckkey
                    this.ItemsArticle.qtite=1
                    this.ItemsArticle.article=$datavalue.item



                    this.gestionPu($datavalue.price)
                    this.ItemsArticle.mtnt=this.montant;
                    this.SaveArticle();


                }





               /* this.gestionPu($datavalue.price)
                this.ItemsArticle.mtnt=this.montant;
                this.SaveArticle();*/

            },

            updateentetecommande($datavalue){

                this.Items=$datavalue.item



            },

            selectionitemforfacturation($item){

              //  console.log($item)


                if (this.Items.jsonotherdonne.cltfrs===null ){


                    toastr['error']( 'Veuillez séléctionner un client valide',"<b></b>");
                    return
                }
                else{


                    if (this.Items.jsonv.client===''){
                        this.Items.jsonv.client=this.Items.jsonotherdonne.cltfrs.name
                        this.Items.jsonv.ifu=this.Items.jsonotherdonne.cltfrs.ifu


                    }


                }

                if (this.Items.jsonotherdonne.moderglt===null ){


                    toastr['error']( 'Veuillez séléctionner le mode de réglement',"<b></b>");
                    return
                }

                if (this.Items.jsonotherdonne.collaborateur===null ){

                    toastr['error']( 'Veuillez séléctionner le mode de réglement',"<b></b>");
                    return
                }










                let $checkprice=0;
                let $dataprice=[];

                if ($item.prixpromo!==0){$checkprice++;$dataprice.push($item.prixpromo)}
                if ($item.pu!==0){$checkprice++;$dataprice.push($item.pu)}
                if ($item.pu1!==0){$checkprice++;$dataprice.push($item.pu1)}
                if ($item.pu2!==0){$checkprice++;$dataprice.push($item.pu2)}
                if ($item.pu3!==0){$checkprice++;$dataprice.push($item.pu3)}
                if ($item.pu4!==0){$checkprice++;$dataprice.push($item.pu4)}
                if ($item.pu5!==0){$checkprice++;$dataprice.push($item.pu5)}
                if ($item.pu6!==0){$checkprice++;$dataprice.push($item.pu6)}
                if ($item.pu7!==0){$checkprice++;$dataprice.push($item.pu7)}



                if ($checkprice===1){




                    if ($item.accompagnement.length>0){

                        this.$refs.modalaccompagnement.show($item.accompagnement,$item,$checkprice,$dataprice);


                    }
                    else{

                        let $mycheckkey=$item.pid+'--prix--'+$dataprice[0]+'--accompagnement--'

                        let $reschek=this.checkmycheckkey($mycheckkey)

                        if (Object.keys($reschek).length>0){

                            this.ItemsArticle=$reschek
                            this.ItemsArticle.qtite++
                            //this.ItemsArticle.article=$item
                            this.gestionPu($dataprice[0])
                            this.ItemsArticle.mtnt=this.montant;
                            this.SaveArticle();

                        }
                    else{
                            this.ItemsArticle.mycheckkey=$item.pid+'--prix--'+$dataprice[0]+'--accompagnement--'
                            this.ItemsArticle.qtite=1
                            this.ItemsArticle.article=$item
                            this.gestionPu($dataprice[0])
                            this.ItemsArticle.mtnt=this.montant;
                            this.SaveArticle();


                        }



                    }




                }


                if ($checkprice>1){


                    if ($item.accompagnement.length>0){

                        this.$refs.modalaccompagnement.show($item.accompagnement,$item,$checkprice,$dataprice);


                    }
                    else{


                        this.$refs.modalselectprice.show($dataprice,$item,{});


                    }



                }



            },


            changeItem(value){

                let pid=this.ItemsArticle.pid;
                // let value=event;
                if (pid!=='') return;
                let dataSearch=[];

                if(value!==null){

                    this.ItemsArticle.article=value

                    if(this.listedatatable.length !==0){


                        dataSearch = this.listedatatable.filter(function (article) {

                            return  article.article.pid ===value.pid
                            //return article.pidar.includes(value.pid);
                        });


                        if(dataSearch.length !==0){
                            toastr['error']( value.libelle+' existe déjà dans la liste.',"<b></b>");
                            this.ItemsArticle.article=null;
                            this.ItemsArticle.qtite=0;
                            this.ItemsArticle.pu= 0;
                            this.storeqtite=0;

                            let mvalue=dataSearch[0];
                            ///  let qte=this.formatNumber2(Number(value.qtite));
                            let qte=mvalue.qtite;
                            var mitemarticle=this.ItemsArticle
                            this.disabled=true;

                            this.ItemsArticle={
                                article:mvalue.article,
                                qtite:qte,
                                diqte:0,
                                pu:mvalue.ppu,
                                pa:mvalue.pa,
                                mtnt:mvalue.mtnt ,
                                docnumother:mitemarticle.docnumother ,
                                remise:mvalue.remise,
                                calmtntht:mvalue.calmtntht,
                                //docnum:value.numdoc,
                                autredesignation:value.autredesignation,
                                doctp:mvalue.doctyp,
                                pid:mvalue.pid,
                                docpid:mvalue.docnum,
                                docnum:mvalue.docnum
                            };

                            this.storeqtite=qte;






                        }else{
                            // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                            //this.itemArticle.pu= this.itemArticle.article.pu

                            this.gestionPu();


                        }


                    }
                    else{
                        // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                        // this.itemArticle.pu=this.itemArticle.article.pu
                        //this.itemArticle.pu= this.itemArticle.article.pu
                        this.gestionPu();
                    }


                }



            },


            gestionPu($puprixvente){



                //***************************** le bon ok ******************************
                if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                    if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                        if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                            // let cal=   parseFloat(this.itemArticle.article.pu)/1.18
                            let cal=   parseFloat($puprixvente)/1.18
                            // let cal=   parseFloat(this.itemArticle.article.pu)/parseFloat(this.itemArticle.article.typttc)
                            let fixedcal=cal.toFixed(8);
                            //this.itemArticle.pu=Math.round(cal)
                            //this.itemArticle.pu=Math.round(fixedcal)
                            this.ItemsArticle.pu=fixedcal
                            //this.itemArticle.pa=this.itemArticle.article.pu
                            this.ItemsArticle.pa=0

                        }
                        else{
                            //let cal=   parseFloat(this.itemArticle.article.pu)*0.18
                            let cal=   parseFloat($puprixvente)*0.18
                            //this.itemArticle.pu=this.itemArticle.article.pu
                            this.ItemsArticle.pu=$puprixvente
                            this.ItemsArticle.pa=Math.round(cal)

                        }



                    }
                    else{

                        this.ItemsArticle.pu=$puprixvente
                        this.ItemsArticle.pa=0
                        /*this.itemArticle.pu=this.itemArticle.article.pu
                        this.itemArticle.pa=this.itemArticle.article.pu*/

                    }


                }
                else{

                   this.ItemsArticle.pu=$puprixvente
                    this.ItemsArticle.pa=0
                    /*this.itemArticle.pu=this.itemArticle.article.pu
                    this.itemArticle.pa=this.itemArticle.article.pu*/


                }

            },



            calcullistedatatable() {

                let vm=this;


                let $montantnontaxable=0,
                    $montantaib=0,
                    $montantcdtionreglemen=0,
                    $montanttva=0,
                    $montanthtarpvht=0,
                    $montanthtarpvttc=0,
                    $montanttotalht=0,
                    $montanttotalttc=0,
                    $montanttotaltrt =0,
                    $smontantremise=0,
                    $istva=0;

                if (this.listedatatable.length>0){

                    this.listedatatable.forEach(function (line) {

                        if (parseFloat(line.etatline)===0 || parseFloat(line.etatline)===1 || parseFloat(line.etatline)===4 ){




                            // $istva=0;
                            let $datajsonarticle=line.article;
                            let $lignemontantht=line.mtnt;
                            let $mtnt_remise=line.remise;

                            if($datajsonarticle.taxedimposition!=="A"){ //verification si l'article est taxable
                                if(vm.Items.jsonotherdonne.appliquetva==="1" || vm.Items.jsonotherdonne.appliquetva===1) /// verification si on applique la tva sur ce document
                                {
                                    //si oui

                                    $istva=1;

                                    if(parseFloat($datajsonarticle.tptva)===1) /// verification de l'application du tva sur cet article
                                    {
                                        //si oui
                                        $montanthtarpvttc=parseFloat($montanthtarpvttc)+parseFloat($lignemontantht);  //montant total des montants HT sur lequelle on applique la tva

                                    }
                                    else{
                                        $montanthtarpvht=parseFloat($montanthtarpvht)+parseFloat($lignemontantht) ;//montant total des montants HT sur lequelle on pas applique la tva

                                    }



                                }
                                else{ // On applique pas la tv a sur le produit


                                    $montanthtarpvttc=0;
                                    $montanthtarpvht=parseFloat($montanthtarpvht)+parseFloat($lignemontantht); //montant total des montants HT sur lequelle on pas applique la tva

                                }

                            }
                            else{

                                $montantnontaxable=parseFloat($montantnontaxable)+parseFloat($lignemontantht) ;

                            }

                            $smontantremise=parseFloat($smontantremise)+parseFloat($mtnt_remise);
                        }
                    })



                    // $montanttotalht= floatval($montanthtarpvttc)+ floatval($montanthtarpvht);/// calcul du montant total ht sans le montant non taxable
                    $montanttotalht= parseFloat($montanthtarpvttc)+ parseFloat($montanthtarpvht)+parseFloat($montantnontaxable);/// calcul du montant total ht avec le montant non taxable

                    $montanttotaltrt="";

                    if ( $istva===1){

                        $montanttva= parseFloat($montanthtarpvttc)*0.18;////// calcul du montant de la TVA

                    }

                    let $aib=parseInt(vm.Items.jsonotherdonne.aib); // taux de AIB


                    let  $libaib="AIB";
                    if ($aib!==0){
                        $libaib="Montant AIB ("+$aib+" %)";

                        $montantaib=parseFloat($montanttotalht)*(parseInt($aib)/100);


                    }else {$montantaib=0;}


                    let $netfinancier=0;


                    if (this.typedoc==="DAF" || this.typedoc==="BCF" ||
                        this.typedoc==="RF" ||  this.typedoc==="FR" ||
                        this.typedoc==="FRF")
                    {
                        $montantaib=-$montantaib;
                        $netfinancier=$montanttotalht+$montantaib;



                        if ( $istva===1){
                            $montanttva= parseFloat($netfinancier)*0.18;////// calcul du montant de la TVA
                        }

                        $montanttotalttc=parseFloat($netfinancier)+parseFloat($montanttva);

                    }
                    else{

                        /////// calcul du montant ttc

                        $montanttotalttc=parseFloat($montanttotalht)+parseFloat($montantaib)+parseFloat($montanttva);


                    }


                    vm.Items.mtnt={
                        doctva:Math.round($montanttva),
                        docmtnttotalttc:Math.round($montanttotalttc),
                        docmtntaib:Math.round($montantaib),
                        docmtntremise:Math.round($smontantremise),
                        docmtnttotalht:Math.round($montanttotalht),
                        doccalmtnttotalht:Math.round($montanttotalht)+Math.round($smontantremise),
                        libaib:$libaib
                    };

                }
                else{
                    this.Items.mtnt={
                        doctva:Math.round(0),
                        docmtnttotalttc:Math.round(0),
                        docmtntaib:Math.round(0),
                        docmtntremise:Math.round(0),
                        docmtnttotalht:Math.round(0),
                        doccalmtnttotalht:Math.round(0),
                        libaib:"Montant AIB (0 %)"
                    }

                }




            },

            newArticle(){
                this. ItemsArticle.docmag=this.mydocmag;
                this. ItemsArticle.article=null;
                this. ItemsArticle.qtite=0;
                this. ItemsArticle.pu=0;
                this. ItemsArticle.remise=0;
                this. ItemsArticle.calmtntht=0;
                this. ItemsArticle.mtnt=0;
                this. ItemsArticle.pid='';
                this.storeqtite=0;
                this. ItemsArticle.autredesignation='';
                this. ItemsArticle.linekey='';
                this. ItemsArticle.etatline=0;
                this.ItemsArticle.spid='';
                this.disabled=false;

            },

            async SaveArticle(){


                if (this.Items.jsonotherdonne.cltfrs===null ){


                    toastr['error']( 'Veuillez séléctionner un client',"<b></b>");
                    return
                }

                if (this.Items.jsonotherdonne.moderglt===null ){


                    toastr['error']( 'Veuillez séléctionner le mode de réglement',"<b></b>");
                    return
                }

                if (this.ItemsArticle.article===null)
                {

                    toastr['error']( 'Veuillez sélectionner un article valide',"<b></b>");
                    return

                }

                if (parseFloat(this.ItemsArticle.qtite)===0 ||  parseFloat(this.ItemsArticle.mtnt)===0  ||  parseFloat(this.ItemsArticle.pu)===0)
                {

                    toastr['error']( 'Veuillez saisir toutes les informations(la quantité ou le prix)',"<b></b>");
                    return

                }




                let vm=this,
                    linekey="",
                    newqtite=0,
                    $mtnt=0,
                    $remise=0,
                    oldqtite=0,
                    mitems,
                    t=this;


                if (t.ItemsArticle.linekey===""){
                    linekey= randomString(20);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=t.ItemsArticle.pu;
                    //t.ItemsArticle.pu=t.ItemsArticle.pu;
                    t.ItemsArticle.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);

                }
                else{

                    linekey=t.ItemsArticle.linekey;
                    newqtite=t.ItemsArticle.qtite;

                    t.listedatatable.forEach(function (line) {

                        if (line.linekey===linekey){
                            line.etatline=0;
                            oldqtite=line.qtite;
                            line.qtite=newqtite;
                            line.ppu=t.ItemsArticle.pu;
                            line.pu=t.ItemsArticle.pu;
                            line.autredesignation=t.ItemsArticle.autredesignation
                            line.mtnt=t.ItemsArticle.mtnt
                            line.mtntclt=t.ItemsArticle.mtntclt
                            line.remise=t.ItemsArticle.remise
                            line.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);

                        }
                    })

                    mitems=cloneobjet(t.ItemsArticle);

                }

                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour



                let url="",donneval={},response;

                if(t.Items.pid!==""){
                    url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/vline895418init51comptoirerefdepdpcc11515/option2/'+randomString(280);

                    response =   axios  .post(url,mitems,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                }
                else{

                    this.$store.dispatch('lancerchargement',true)


                    t.Items.jsonotherdonne.tpdoc="facture"
                    t.Items.jsonotherdonne.tredocument="vente"
                    t.Items.cf=this.$store.state.caisseinfo
                    t.Items.jsonv.modereglement=t.Items.jsonotherdonne.moderglt
                    t.Items.jsonv.typ="VENC"
                    t.Items.jsonv.comptabilise=0

                    donneval={  'itemdoc':t.Items, 'itemArticle':mitems, }

                    url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/add895418init51comptoirerefdepdpcc11515/option2/'+randomString(280);

                    response =   axios  .post(url,donneval,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                }

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                          t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");


                            t.compteprocess--;



                            if(t.Items.pid===""){

                                t.ItemsArticle.docnum=res.data.numdoc;
                                t.Items.docnum=res.data.numdoc;
                                t.Items.docpid=res.data.keypid;
                                t.ItemsArticle.docnum=res.data.keypid;
                                t.ItemsArticle.docpid=res.data.keypid;
                                t.Items.pid=res.data.keypid;

                            }

                            //  if (line.pid===""){line.pid=res.data.key;}

                            let $search=""

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=1;

                                    $search=line.article.pid

                                    if (line.pid===""){

                                        line.pid=res.data.keyline;
                                        line.docnum=res.data.keypid;
                                        line.docpid=res.data.keypid;

                                    }

                                }
                            })

                            let $checkindex=array_search($search,t.datatablearticle,'pid')

                            if ($checkindex>=0){


                                if (parseFloat(t.datatablearticle[$checkindex].instock)===1){
                                    t.datatablearticle[$checkindex].rstck=res.data.pk

                                   t.$store.commit('listedesarticlespourlacaisse', { value:t.datatablearticle });



                                }


                            }





                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                            t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },

            async deleteItemArticle(ItemsArticle){




                if (ItemsArticle.article===null)
                {

                    toastr['error']( 'Veuillez sélectionner un article valide',"<b></b>");
                    return

                }

                if (parseFloat(ItemsArticle.qtite)===0 ||  parseFloat(ItemsArticle.mtnt)===0  ||  parseFloat(ItemsArticle.pu)===0)
                {

                    toastr['error']( 'Veuillez saisir toutes les informations(la quantité ou le prix)',"<b></b>");
                    return

                }




                ItemsArticle.spid=ItemsArticle.docnum


                const t = this;



                let vm=this,
                    linekey="",
                    newqtite=0,
                    $mtnt=0,
                    $remise=0,
                    oldqtite=0,
                    mitems ;


                if (ItemsArticle.linekey===""){
                    /*linekey= randomString(8);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                    t.ItemsArticle.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);*/

                }
                else{

                    linekey=ItemsArticle.linekey;
                    newqtite=ItemsArticle.qtite;

                    t.listedatatable.forEach(function (line) {


                        if (line.linekey===linekey){
                            line.etatline=0;
                            oldqtite=line.qtite;


                        }
                    })

                    mitems=cloneobjet(ItemsArticle);

                }

                if (ItemsArticle.pid===""){ // suppression d'un article non enregistrer dans la base de donnée

                    t.listedatatable.forEach(function (line,index) {

                        if (line.linekey===linekey){

                            t.listedatatable.splice(index,1);
                        }

                    })

                    this.newArticle();
                    this.calcullistedatatable();
                    return
                }


                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour

                //this.$store.dispatch('lancerchargement',true)
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/svline895418init51comptoirerefdepdpcc11515/option2/'+randomString(280);
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        //  t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");
                            let $search=""
                            t.compteprocess--;
                            t.listedatatable.forEach(function (line,index) {

                                if (line.linekey===linekey){

                                    $search=line.article.pid

                                    t.listedatatable.splice(index,1);
                                }
                            })

                            t.calcullistedatatable();

                            let $checkindex=array_search($search,t.datatablearticle,'pid')

                            if ($checkindex>=0){


                                if (parseFloat(t.datatablearticle[$checkindex].instock)===1){
                                    t.datatablearticle[$checkindex].rstck=res.data.pk


                                    t.$store.commit('listedesarticlespourlacaisse', { value:t.datatablearticle });

                                }


                            }





                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                            // t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        //t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },

            async  saveItems(){

                this.$store.dispatch('lancerchargement',true)

                this.Items.cf=this.$store.state.caisseinfo;


                if(this.$store.state.authorizeprix0 === "false" || this.$store.state.authorizeprix0 === false){


                    if (this.Items.jsonotherdonne.montantrecu===0 || this.Items.jsonotherdonne.montantrecu==='' ){

                        toastr['error']( 'Veuillez saisir le montant reçu pour comptabiliser la vente.',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)

                        return
                    }

                }

                if(this.$store.state.authorizefacturactionpartiel === "false" || this.$store.state.authorizefacturactionpartiel === false){

                    if (this.Items.mtnt.docmtnttotalttc > this.Items.jsonotherdonne.montantrecu ){

                        toastr['error']( 'Le montant reçu est insuffisant, pour comptabiliser la facturation..',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)

                        return
                    }


                }


                let value={
                    'cf':this.$store.state.caisseinfo,
                    'dpid':this.Items.pid,
                    'dmtnt':this.Items.jsonotherdonne.montantrecu,
                    'jsonotherdonne':this.Items.jsonotherdonne,
                    'jsonv':this.Items.jsonv,
                    'document':"FCOMPTOIRE",
                    'item':this.Items,
                }


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/doc/caisse/addfkdfkdozojdj_refdepdpcc/'+this.$store.state.appsociete+'/add895418init51_refdepdpcc11515/'+randomString();
                let response =   axios  .post(url,value,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){

                            t.messagesucces="";
                            t.messageerror="";

                            t.messagesucces=res.data.message;

                            toastr['success']( t.messagesucces,"<b></b>");

                            let normalise=0
                            let mecefsignature=""
                            let mecefnim=""
                            let mecefcompteur=""
                            let mecefheure=""
                            let mecefqrr=""


                            if (t.$store.state.authorizenormalisation==="true" || t.$store.state.authorizenormalisation===true){
                                mecefsignature=res.data.m.mecef.mecefsignature
                                mecefnim=res.data.m.mecef.mecefnim
                                mecefcompteur=res.data.m.mecef.mecefcompteur
                                mecefheure=res.data.m.mecef.mecefheure
                                mecefqrr=res.data.m.mecef.mecefqrr
                                normalise=res.data.m.mecef.normalise
                            }


                            let items=[];

                            t.listedatatable.forEach(function (line) {
                                if (line.etatline!==2){ items.push(line) }
                            })


                            let tabvalue={
                                numdoc:t.Items.docnum,
                                date:t.Items.datedoc,
                                client:t.Items.jsonv.client,
                                ifu:t.Items.jsonv.ifu,
                                params:'',
                                params4:'',
                                boutique:'',
                                serveur:(t.Items.jsonv.collaborateur!==null)?t.Items.jsonv.collaborateur.name:'',
                                commandetable:(t.Items.jsonv.commandetable!==null)?t.Items.jsonv.commandetable.name:'',
                                vendeur:t.$store.state.appusername,
                                items:items,
                                montantht:t.calculSomme(t.Items.mtnt.docmtnttotalht,t.Items.mtnt.docmtntremise,0),
                                montanttva:0,
                                montantremise:t.currencyFormatDE(t.Items.mtnt.docmtntremise),
                                montantnetapayer:t.currencyFormatDE(t.Items.mtnt.docmtnttotalttc),
                                reglement:t.currencyFormatDE(t.Items.jsonotherdonne.montantrecu),
                                montantmonnaie:t.calculMonnaie(t.Items.mtnt.docmtnttotalttc,t.Items.jsonotherdonne.montantrecu),
                                pourcentageprisenecharge:0,
                                docsaisiepar:t.$store.state.appusername,


                                normalise:normalise,
                                mecefsignature:mecefsignature,
                                mecefnim:mecefnim,
                                mecefcompteur:mecefcompteur,
                                mecefheure:mecefheure,
                                mecefqrr:"https://chart.googleapis.com/chart?cht=qr&chl="+mecefqrr+"&chs=100x100&chld=L|0",

                            }

                            if (t.Items.jsonv.param!==null){tabvalue.params=t.Items.jsonv.param.name}

                            t.$refs.modalfacturecomptoirepost.show(tabvalue,'facture');




                        }
                        else{

                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");

                            this.isdisplay=false;

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))











            },

            currencyFormatDE(p1){
                var number=Number(p1)
                return currencyFormatDE(number)
            },
            formatNumber2(p1){
                var number=Number(p1)
                return FormatNumber(number)
            },


            closeform(value){

                this.datatablearticle=value
                this.load();

            },


            formatNumber(num) {

                return  currencyFormatDE(num)
            },


            async loadline(value){

                ///m/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/:pidcaisse/:keyuser/add895418init51comptoirerefdepdpcc11515/:ftoken

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+value.pid+'/'+this.$store.state.appsociete+'/allline895418init51comptoirerefdepdpcc11515/'+randomString(280);
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            value.alllignedoc=res.data.m;


                           let mname='Facturation au comptoir';


                            t.$refs.modalform.show(mname,"update",value,t.datatablearticle,t.otherdatatable);


                        }
                        else{
                            t.datatable=[];
                            t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },


            async load(){

                var caisse={
                    cf:this.$store.state.caisseinfo,
                    document:"FV",
                }

                ///m/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/:pidcaisse/:keyuser/add895418init51comptoirerefdepdpcc11515/:ftoken

               // this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/load895418init51comptoirerefdepdpcc11515/option2/'+randomString(280);
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                       // t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            t.datatable=[];
                            t.datatable=res.data.m;

                        }
                        else{
                            t.datatable=[];


                        }


                    })
                    .catch((error)=>{

                          //  t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                       // t.$store.dispatch('lancerchargement',false)


                    })




            },

            async loadreturnotherdata(){

                  const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/other111dksdk/option2/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                            t.otherdatatable=[];
                            t.otherdatatable=res.data.otherdata;
                        }
                        else{
                            t.otherdatatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },

            async loadinputlibelleforms(){



                let $VALUEDATAPRICE=JwtService.getVALUEDATAPRICE()

                if (isset($VALUEDATAPRICE)){

                    this.datainputlibelleforms=JSON.parse($VALUEDATAPRICE);


                }
                else{
                    const t = this;
                    let url=process.env.VUE_APP_API_URL+'/12478855libelinputindex2iofdofiloadexp2/'+this.$store.state.appsociete+'/'+randomString();
                    let response =   axios  .get(url,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                    let res= await response
                        .then(function (res){
                            t.$store.dispatch('lancerchargement',false)


                            if(res.data.success){


                                t.datainputlibelleforms=[];

                                t.datainputlibelleforms=res.data.valuedataprice;

                                JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))


                            }
                            else{
                                t.datainputlibelleforms=[];

                            }


                        })
                        .catch((error)=>{

                                t.$store.dispatch('lancerchargement',false)
                            }
                        )
                        .finally(() =>{

                            t.$store.dispatch('lancerchargement',false)


                        })
                }



            },

            btnimprimer(value){

                let t=this

                if (t.typedoc==='DPCC' || t.typedoc==='REF'){
                    value.alldepense.push({motif:value.motif, montant:value.montant});
                }


                let tabvalue={
                    nbon:value.nbon,
                    numdoc:value.numdoc,
                    date:value.dateop,
                    montanttotal:value.montant,
                    montanttotaldelafacure:value.montant,
                    items:value.alldepense,
                    docsaisiepar:value.saisirpar,
                    benrficiaire:value.beneficiaire,
                    qrr:value.docnum,
                    patient:value.beneficiaire.name,
                    observations:value.motif,
                    typdoc:t.typedoc


                }



                this.$refs.modalrapportdeprefdpcc.show(tabvalue);


            },



            close(){

                this.isdisplay=false;

                this.$emit('closed',this.datatablearticle);

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },



            Misecommandeenattente(){

                this.listedatatable=[];
                this.ItemsArticle={
                    accompagnement:[],
                    autredesignation:'',
                    mycheckkey:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    calmtntht:0,
                    etatline:0,
                    pid:''
                }
                this.Items= {
                    pid:'',
                    mag:null,
                    datedoc:this.$store.state.dateouverturecaisse,
                    docobservation:'',
                    docnum:'',
                    docnumother:'',
                    docpid:0,
                    doctyp:'',
                    infodoc:{
                        infolibre:'',
                        infolibre1:'',
                        infolibre2:'',
                        infolibre3:'',
                    },
                    jsonotherdonne:{
                        montantrecu:0,
                        tpdoc:"",
                        tredocument:"",
                        mtypprixdevente:"prixvente",
                        magvers:null,
                        collaborateur:null,
                        commandetable:null,
                        cltfrs:null,
                        moderglt:null,
                        cdtreglement:null,
                        appliquetva:0,
                        aib:0,
                        adresseexpedition:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },
                        infodoc:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },



                    },
                    mtnt:{
                        montanthtassurance: 0,
                        docmtntaib: 0,
                        docmtntremise: 0,
                        docmtnttotalht: 0,
                        docmtnttotalttc: 0,
                        doctva: 0,
                        libaib: "AIB"


                    },
                    params:{
                        param1:null,
                        param2:null,
                        param3:null,
                        param4:null,
                    },
                    jsondoc:{},
                    jsonv:{

                        ifu:'',
                        client:'',
                        collaborateur:null,
                        modereglement:null,
                        commandetable:null,
                        indicateur:'',
                        indicateur1:'',
                        param:null,
                        param1:null,


                    }
                }

                toastr['success']( "La commande a été correctement mise en attente","<b></b>");




            },



            newinvoice(){

                this.listedatatable=[];
                this.ItemsArticle={
                    accompagnement:[],
                    autredesignation:'',
                    mycheckkey:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    calmtntht:0,
                    etatline:0,
                    pid:''
                }
                this.Items= {
                    pid:'',
                    mag:null,
                    datedoc:this.$store.state.dateouverturecaisse,
                    docobservation:'',
                    docnum:'',
                    docnumother:'',
                    docpid:0,
                    doctyp:'',
                    infodoc:{
                        infolibre:'',
                        infolibre1:'',
                        infolibre2:'',
                        infolibre3:'',
                    },
                    jsonotherdonne:{
                        montantrecu:0,
                        tpdoc:"",
                        tredocument:"",
                        mtypprixdevente:"prixvente",
                        magvers:null,
                        collaborateur:null,
                        commandetable:null,
                        cltfrs:null,
                        moderglt:null,
                        cdtreglement:null,
                        appliquetva:0,
                        aib:0,
                        adresseexpedition:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },
                        infodoc:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },



                    },
                    mtnt:{
                        montanthtassurance: 0,
                        docmtntaib: 0,
                        docmtntremise: 0,
                        docmtnttotalht: 0,
                        docmtnttotalttc: 0,
                        doctva: 0,
                        libaib: "AIB"


                    },
                    params:{
                        param1:null,
                        param2:null,
                        param3:null,
                        param4:null,
                    },
                    jsondoc:{},
                    jsonv:{

                        ifu:'',
                        client:'',
                        collaborateur:null,
                        modereglement:null,
                        commandetable:null,
                        indicateur:'',
                        indicateur1:'',
                        param:null,
                        param1:null,


                    }
                }

            },



            show(datatablearticle){


                if(this.$store.state.caisseencour==='' ||
                    this.$store.state.caisseencour===''){

                    toastr['error']( "Veuillez sélectionner une caisse valide.")
                     this.$router.push({ name: 'tcaisse' });
                }
                else{


                    this.messagesucces="";
                    this.messageerror="";
                    //this.datatablearticle=datatablearticle;
                    this.titlemodal="Liste des facturations"



                    /*

                    let $groupbycatalogue=groupByKey(this.datatablearticle,'catalogue');

                    this.datatablearticlegroupe=$groupbycatalogue

                    */

                      this.Items= {
                        pid:'',
                        mag:null,
                        datedoc:this.$store.state.dateouverturecaisse,
                        docobservation:'',
                        docnum:'',
                        docnumother:'',
                        docpid:0,
                        doctyp:'',
                        infodoc:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },
                        jsonotherdonne:{
                            montantrecu:0,
                            tpdoc:"",
                            tredocument:"",
                            mtypprixdevente:"prixvente",
                            magvers:null,
                            collaborateur:null,
                            commandetable:null,
                            cltfrs:null,
                            moderglt:null,
                            cdtreglement:null,
                            appliquetva:0,
                            aib:0,
                            adresseexpedition:{
                                infolibre:'',
                                infolibre1:'',
                                infolibre2:'',
                                infolibre3:'',
                            },
                            infodoc:{
                                infolibre:'',
                                infolibre1:'',
                                infolibre2:'',
                                infolibre3:'',
                            },



                        },
                        mtnt:{
                            montanthtassurance: 0,
                            docmtntaib: 0,
                            docmtntremise: 0,
                            docmtnttotalht: 0,
                            docmtnttotalttc: 0,
                            doctva: 0,
                            libaib: "AIB"


                        },
                        params:{
                            param1:null,
                            param2:null,
                            param3:null,
                            param4:null,
                        },
                        jsondoc:{},
                        jsonv:{

                            ifu:'',
                            client:'',
                            collaborateur:null,
                            modereglement:null,
                            commandetable:null,
                            indicateur:'',
                            indicateur1:'',
                            param:null,
                            param1:null,


                        }
                    }

                    this.loadinputlibelleforms()
                    this.loadreturnotherdata()
                    this.load()
                    this.isdisplay=true;




                }

            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 100%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .h-screen550{

        height: 550px!important;

    }

    .h-screen600{

        height: 600px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    .mybodyheight700px{
        height: 700px;
    }






    ::-webkit-scrollbar {
        height: 15px!important;

    }

    .activebtn {
        color: white;
        background-color: #00d1b2;
    }


    .hoveritemsarticle:hover {
        color: white;
        background-color: #00d1b2;
        border-radius: 20px;
    }



    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen550,.h-screen600,.h-screen,.mybodyheight700px,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>