<template>
    <router-view/>
    <vloadingcomponent :loading="$store.state.lancerchargement"></vloadingcomponent>
</template>

<script>
    import {mapGetters} from 'vuex';
    import vloadingcomponent from "./loadingcomponent";

export default {

  name: 'App',
    created() {
        // Initialize the screen size
        this.screenSize = window.innerWidth;

        // Watch for screen size changes
        window.addEventListener('resize', this.handleResize);
    },
   /* destroyed() {
        // Clean up the event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },*/

    watch: {
        screenSize(newSize) {
            //document.body.removeAttribute('class')

            let html = document.querySelector("html");


            if (html.getAttribute("data-vertical-style") !== 'overlay'){



                html.setAttribute("data-vertical-style", "overlay");

            }


            if (newSize <= 990) { // ecran mobile
                // Execute your code here for screen size <= 990px
                this.$store.dispatch('myscreenSize',newSize)
               /* this.$store.dispatch('myscreenSize',newSize)


                this.$store.dispatch('classdefautmenu',"sidebar-mini layout-fixed  sidebar-closed sidebar-collapse")
                this.$store.dispatch('niveauclickmenu',parseInt(0))
                document.body.classList.add('sidebar-mini', 'layout-fixed','sidebar-closed', 'sidebar-collapse')

                if (this.isAuthenticated===false){
                    this.$store.dispatch('classdefautmenu',"login-page")
                    document.body.classList.add('login-page')
                }else {

                    this.$store.dispatch('classdefautmenu'," sidebar-closed sidebar-collapse")
                    document.body.classList.add('sidebar-mini', 'layout-fixed','sidebar-closed', 'sidebar-collapse')

                }*/


                if (html.getAttribute("data-toggled") === 'icon-overlay-close'  ){

                    html.removeAttribute('data-toggled');
                    html.setAttribute("data-toggled", "close");

                }


                if (html.getAttribute("data-toggled") !== 'icon-overlay-close' &&
                    html.getAttribute("data-toggled") !== 'close'){

                    html.removeAttribute('data-toggled');
                    html.setAttribute("data-toggled", "close");

                }




            }
            else{//ecran large

                if (html.getAttribute("data-toggled") === 'close'  ){

                    html.removeAttribute('data-toggled');

                }






                this.$store.dispatch('myscreenSize',newSize)


                /*

                this.$store.dispatch('niveauclickmenu',parseInt(1))

                if (this.isAuthenticated===false){
                    this.$store.dispatch('classdefautmenu',"login-page")
                    document.body.classList.add('login-page')
                }
                else{

                    this.$store.dispatch('classdefautmenu',"sidebar-mini layout-fixed sidebar-collapse")
                    document.body.classList.add('sidebar-mini', 'layout-fixed','sidebar-collapse')
                }*/

            }
        },
        isAuthenticated(newVal) {
            if (newVal) {
                this.startInterval();
            } else {
                this.stopInterval();
            }
        },
    },
    data(){
        return {
            screenSize: null,
            intervalId: null, // ID pour stocker l'intervalle
        }

    },
    computed:{
        ...mapGetters(['lancerchargement','classmenupc','classdefautmenu','niveauclickmenu','myscreenSize','isAuthenticated']),



    },
    components:{
        vloadingcomponent
    },
    mounted() {


    },




    methods: {
        handleResize() {
            this.screenSize = window.innerWidth;
        },


        startInterval() {
            try {
                this.intervalId = setInterval(() => {
                    // Code qui pourrait lancer une erreur
                   // console.log('Fonction exécutée toutes les 30 secondes');

                    this.$store.dispatch('loadevoluationdata')

                    // Simuler une erreur pour l'exemple
                    // throw new Error('Something went wrong');
                }, 600000); // 30 000 ms = 30 s // 600.000 ms= 10min
            } catch (error) {
                console.error('Erreur dans l\'intervalle:', error);
                this.stopInterval(); // Nettoyer l'intervalle en cas d'erreur
            }
        },
        stopInterval() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },




    },
}
</script>

<style>
#app {
 /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;*/
}
</style>
