<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <div class="content">
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70 dark:text-white dark:hover:text-white text-[1.125rem] font-semibold">TABLEAU DE BORD</h3>
                        </div>

                    </div>
                    <!-- Page Header Close -->

                    <div class="grid grid-cols-12 gap-x-6">
                        <div class="xxl:col-span-12 xl:col-span-12 col-span-12">


                            <div class="grid grid-cols-12 gap-x-6">




                                <div class="xxl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                                    <div class="box hrm-main-card primary">
                                        <div class="box-body">
                                            <div class="flex items-start" v-bind:class="[nbredejours <30 ? 'col-md-6 alert alert-danger' : 'col-md-6']">

                                                <div class="flex-grow">
                                                    <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Société : {{$store.state.etatraisonsocial}}</span>

                                                    <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Expiration de la licence : {{$store.state.paramsystem.licence}}</span>
                                                    <h5 class="font-semibold mb-1 text-[1.25rem]">Il vous reste {{nbredejours}} jours avant l'expiration de votre licence.</h5>
                                                    <!--p class="mb-0">
                                                        <span class="badge bg-primary/10 text-primary">This Month</span>
                                                    </p-->
                                                </div>
                                                <!--div>
                                                    <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                                </div-->
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!--div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Evolution du montant des </div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">



                                            </div>
                                        </div>
                                    </div>
                                </div-->


                                <!--div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Evolution de la masse salariale net à payer des occasionnels sur
                                                les 6 derniers mois</div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">




                                            </div>
                                        </div>
                                    </div>
                                </div-->


                                <div class="xxl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-12 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">MISE A JOUR</div>
                                        </div>
                                        <div class="box-body">


                                            <ul class="list-none timeline-widget mb-0 my-5">

                                                <template v-if="datatodolist.length>0">

                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">02</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Mon</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">You have an announcement - Ipsum Est Diam Eirmod</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50">10:00AM<span class="badge bg-primary/10 text-primary ms-2">Announcement</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">15</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Sun</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">National holiday - Vero Jayanti</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50"><span class="badge text-warning bg-warning/10">Holiday</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">23</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Mon</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">John pup birthday - Team Member</p>
                                                                <p class="mb-4 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50">09:00AM<span class="badge text-success bg-success/10 ms-2">Birthday</span></p>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">Amet sed no dolor kasd - Et Dolores Tempor Erat</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50">04:00PM<span class="badge text-primary bg-primary/10 ms-2">Announcement</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">31</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Tue</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">National Holiday - Dolore Ipsum</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50"><span class="badge text-warning bg-warning/10">Holiday</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                </template>

                                                <template v-else>

                                                    <li class="timeline-widget-list">
                                                        <div class="flex items-start">
                                                            <div class="me-[3rem] text-center">
                                                                <span class="block text-[1.25rem] font-semibold text-primary">-</span>
                                                                <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">-</span>
                                                            </div>
                                                            <div class="flex flex-wrap flex-grow items-start justify-between">
                                                                <div>
                                                                    <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap"><span style="color: red">-</span></p>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>

                                                </template>

                                            </ul>



                                        </div>
                                    </div>
                                </div>







                                <div class="xl:col-span-12 col-span-12">
                                    <div class="box">
                                        <div class="box-header justify-between">
                                            <div class="box-title mb-2 sm:mb-0">
                                                Évolution de votre consommation de données sur HSIGCOM <span style="color: red">( {{totalmoconsomme}} Mo )</span>
                                            </div>
                                            <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                                                <button type="button" @click="loadevoluationdata('1M')" :class="indexbtnsuividata==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 Mois</button>
                                                <button type="button" @click="loadevoluationdata('2M')" :class="indexbtnsuividata==='2M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">2 Mois</button>
                                                <button type="button" @click="loadevoluationdata('3M')" :class="indexbtnsuividata==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">3 Mois</button>
                                            </div>
                                        </div>
                                        <div class="box-body">


                                            <apexcharts ref="mychart"  type="area" height="350"
                                                        :options="computedChartOptions"
                                                        :series="computedChartSeries"></apexcharts>



                                        </div>
                                    </div>
                                </div>







                            </div>
                        </div>

                    </div>
                    <div class="grid grid-cols-12 gap-x-6 col-span-12">

                    </div>

                </div>
            </div>



        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";


    import {cloneobjet, currentMonth, currentYear, getnombredejours, getYear, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    import VueApexCharts from "vue3-apexcharts";
    import {mapGetters} from 'vuex';

    export default {
        /* eslint-disable */
        name: "index",
        components: {
            templatesite,
            appheader,
            appsidebar,

            apexcharts: VueApexCharts,

        },

        computed:{
            ...mapGetters(['totalmoconsomme','indexbtnsuividata','graphssuiviedata']),

            filtercalendar(){
                let mvalue=this.datafiltercalendar;

                if (this.searchwordcalendar!==''){

                    // ref
                    let t=this
                    //mvalue = this.datatablesearch.filter( post => post.magasin.toLowerCase().includes(this.searchword))
                    mvalue = mvalue.filter( function (post) {
                        //return    post.name.toLowerCase().includes(t.searchwordcalendar.toLowerCase())
                        return    post.service.toLowerCase().includes(t.searchwordcalendar.toLowerCase())
                    })
                    //return this.postList.filter( post => post.intitule.toLowerCase().includes(this.searchword))
                }

                return mvalue ;
            },



            // Computed property for chart options
            computedChartOptions() {

                if (Object.keys(this.graphssuiviedata).length>0){

                    return  cloneobjet(this.graphssuiviedata.chartOptions);
                }
                else{
                    return  {};

                }



            },
            // Computed property for chart series data
            computedChartSeries() {
                // Simuler une modification des données

                if (Object.keys(this.graphssuiviedata).length>0){

                    return  cloneobjet(this.graphssuiviedata.series);
                }else{
                    return  [];

                }


            }

        },

        watch: {
            computedChartOptions(newOptions) {
                      this.updateChartOptions(newOptions);
                      // this.updateChart();
            },
            computedChartSeries(newSeries) {
                this.updateChartSeries(newSeries);
                //this.updateChart();
            }
        },

        data() {
            return {

                datatutoriel:[],
                nbredejours:getnombredejours(this.$store.state.paramsystem.licence,this.$store.state.appserverdate),

                imgurlrdv:'/icon/rdv.svg',
                imgurlpatient:'/icon/patient.svg',
                imgurlstaff:'/icon/staffs.svg',
                imgurllit:'/icon/lit.svg',


                datatablefindecontratpermannent:[],

                countp:0,
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
                btnnextmonth:{mm:currentMonth(),manne:currentYear()},
                btnprevmonth:{mm:currentMonth(),manne:currentYear()},


                searchwordcalendar:'',
                searchtransaction:'',
                searchwordconge:'',
                searchwordabsence:'',
                nbrepersonnel:0,
                nbreoccasionnel:0,
                nbretotalemploye:0,
                nbrepermanentdebauche:0,
                nbreoccasionneldebaucher:0,
                listemassesalarialpermanent:[],

                mgetyear:getYear(),
                mmois:"",
                manne:"",
                dataPointObjects: [],
                datatodolist: [],
                datasuiviconge: [],
                datademandedecongenontraite: [],
                datademandedeabsencenontraite: [],
                datacalendar: {},
                datafiltercalendar: [],
                dataPointObjectsoccas: [],
                chartWidth: 600,
                chartHeight: 200,
               // url:this.$store.state.baseurl+'api/api_hpaie_v1.0.3.EXE',



                posts : [''],
                page: 1,
                perPage: 2,
                Defaultpage: 1,
                DefaultperPage: 2,
                pages: [],


                mposts : [''],
                mpage: 1,
                mperPage: 2,
                mDefaultpage: 1,
                mDefaultperPage: 2,
                mpages: [],





            };
        },
        methods: {


            updateChartOptions(newOptions) {

                // Mettre à jour les options du graphique
                //this.$refs.mychart.updateOptions(newOptions);

                if (this.$refs.mychart) {
                    this.$refs.mychart.updateOptions(this.computedChartOptions);

                }


            },
            updateChartSeries(newSeries) {

                // Mettre à jour les séries de données du graphique
              //  this.$refs.mychart.updateSeries(newSeries);

                if (this.$refs.mychart) {
                    this.$refs.mychart.updateSeries(this.computedChartSeries);

                }



            },


            async loadevoluationdata(value) {

                this.$store.dispatch('indexbtnsuividata',value)
                this.$store.dispatch('loadevoluationdata')
/*
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/suividata/'+this.indexbtnsuividata+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.graphssuivieca=response.data.allgraphs
                            t.totalmoconsomme=response.data.ttmo


                        }
                        else{


                            t.graphssuivieca={}

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))
*/
            },



            btnpreviousplanning(){

                this.loadplanning(this.btnprevmonth.mm,this.btnprevmonth.manne);

            },
            btnnextplanning(){


                this.loadplanning(this.btnnextmonth.mm,this.btnnextmonth.manne);


            },

            async loaddata(){

                const t = this;

                //let response =   axios  .get(process.env.VUE_APP_API_URL+'/egl745874dsfd5771714dfe/'+allfunction.randomString())
                let response =   axios  .get(process.env.VUE_APP_API_URL+'/')
                let res= await response
                    .then(function (res){

                        //console.log("res  ********* ",res)

                       /* if(res.data.res){
                            t.allchurch=res.data.m;
                        }else{
                            t.allchurch=[];
                        }

                        */


                    })
                    .catch((error)=>{
                            this.allchurch=[];


                        }
                    )
                    .finally(() =>{




                    })



            },

            async loaddatacountpersonnel(){


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/countpers/'+this.$store.state.appsociete+'/'+randomString();
                let response = axios.get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })
                let res= await response
                    .then(function (res){


                        t.nbrepersonnel=res.data.m.allpermanents
                        t.nbreoccasionnel=res.data.m.alloccasionnels
                        t.nbretotalemploye=res.data.m.allemploye
                        t.nbrepermanentdebauche=res.data.m.alldebaucherpermanents
                        t.nbreoccasionneldebaucher=res.data.m.alldebaucheroccasionnels



                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })



            },

            async loadplanning($month,$year){



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/planning/'+this.$store.state.appsociete+'/'+$month+'/'+$year+'/'+randomString();
                let response = axios.get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })
                let res= await response
                    .then(function (res){

                        if(res.data[0].success){

                            t.datacalendar=res.data[0];

                            t.btnnextmonth=t.datacalendar.next
                            t.btnprevmonth=t.datacalendar.prev

                            t.datafiltercalendar=res.data[0].infoper

                        }
                        else{


                            t.datacalendar={}
                            t.datafiltercalendar=[]

                            t.btnnextmonth={mm:currentMonth(),manne:currentYear()}
                            t.btnprevmonth={mm:currentMonth(),manne:currentYear()}

                        }



                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })



            },



            async loadfincontratdans6mois(){



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/personnel/'+this.$store.state.appsociete+'/findecontrat/personnel/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){

                        t.datatablefindecontratpermannent=[];


                        if(res.data.success){

                            t.datatablefindecontratpermannent=res.data.m;

                        }
                        else{
                            t.datatablefindecontratpermannent=[];

                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },




        },
        mounted() {

            //this.loadfincontratdans6mois();
            //this.loaddatacountpersonnel();
            //this.loadplanning(currentMonth(),currentYear());


            // Initialisation du graphique avec les données et options initiales

            //this.updateChartOptions(this.computedChartOptions);
            //this.updateChartSeries(this.computedChartSeries);

        }






    }
</script>

<style scoped>

    /*********************  CALENDA  ********************/

    .calendar-team tbody tr td.service {
        background: #f7f7f7;
        color: #858585;
        border-top-color: #d0d0d0;
        border-right-color: #d0d0d0;
        border-left-color: #d0d0d0;
        padding-top: 1.5rem;
        font-size: 1.00625rem;
    }

    .out {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 4px;
        background: #eae9e9 !important;
        color: #ccc;
    }

    .calendar-team thead tr th.header-day {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        font-size: 12px;
        background: #f7f7f7;
        font-weight: normal;
        padding: 1px 5px;
        text-align: center;
        border: 1px solid #adadad;
    }
    .cell-block {
        width: 30px;
        height: 30px;
        overflow: hidden;
    }


    .calendar-team thead tr th.header-month {
        overflow: hidden;
        border: 1px solid #adadad;
    }
    .calendar-team>table>thead>tr {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
    }
    .calendar-team>table {
        padding: 0rem !important;
    }

    .calendar-team .table-sm th, .table-sm td {
        padding: 0rem;
        /*font-size: 12px!important;*/
        font-size: 11px;
    }

    .calendar-planningok{
        background-color: #00B666;
    }

    .calendar-planningnonlegal{
        background-color: tomato;
    }


    .calendar-planninglegal{
        background-color: darkorange;
    }





    .calendar-planningnok{
        background-color: #ffffff;
    }


    .calendar-planningnweekend{
       /* background-color: pink;*/
        background-color: #d9edf7;

    }

    .todayBorder {
        border-left: #a3cbdf 2px solid !important;
        border-left-color: #a3cbdf !important;
        border-right: #a3cbdf 2px solid !important;
        border-right-color: #a3cbdf !important;
        background-color: red!important;
    }

    .calendar-div{
        height: 350px;
        overflow: auto;
    }


    .header-month{
        font-size: 16px!important;
        text-align: center;
        font-weight: bold;

    }


</style>