<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h4 class="modal-title" id="staticBackdropLabel2" style="font-weight: bolder"> Entête commande
                    </h4>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="alert alert-info text-center mt-3">
                        <p style="color: black;font-size: 15px;">Date : {{Items.datedoc}}  <template v-if="Items.docnum!==''"> / {{Items.docnum}}</template> </p>
                    </div>

                    <div class="box-body">

                        <div class="grid lg:grid-cols-2 mt-5 mb-5 gap-6">

                            <div class="space-y-2">
                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-12" >
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Client</label>


                                        <input :placeholder="'Veuillez sélectionner un client'"  v-if="Items.jsonotherdonne.cltfrs===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.jsonotherdonne.cltfrs.name" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-12">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                        <button v-if="!disableddoc"   @click="btnmodallistedescltfrs()"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                        <button v-else   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                    </div>
                                </div>
                            </div>

                            <div class="space-y-2">

                                <div class="grid grid-cols-2  gap-x-6 mt-3">

                                    <div class="space-y-2 w-full">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Ifu client</label>
                                        <input v-model="Items.jsonv.ifu" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Ifu du client sur la facture"   required>


                                    </div>


                                    <div class="space-y-2 w-full">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Nom client</label>
                                        <input v-model="Items.jsonv.client" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Nom du client sur la facture"   required>

                                    </div>



                                </div>

                            </div>




                            <div class="space-y-2">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Serveur</label>

                                <select :disabled="disableddoc" v-model="Items.jsonv.collaborateur" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option  :value="null">Séléctionner un Serveur</option>
                                    <option v-for="(datavalue,index) of alldata.collaborateur" :key="index"
                                            :value="datavalue">{{ datavalue.typecollaborateur+' / '+ datavalue.name}}</option>

                                </select>
                            </div>


                            <div class="space-y-2">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Mode de réglement</label>

                                <select :disabled="disableddoc" v-model="Items.jsonv.modereglement" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option  :value="null">Séléctionner un mode de réglement</option>
                                    <option v-for="(datavalue,index) of alldata.modedereglement" :key="index"
                                            :value="datavalue">{{datavalue.name}}</option>

                                </select>
                            </div>


                            <div class="space-y-2">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Table</label>

                                <select :disabled="disableddoc" v-model="Items.jsonv.commandetable"
                                        class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option  :value="null">Séléctionner une table</option>
                                    <option v-for="(datavalue,index) of alldata.commandetable" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                </select>
                            </div>

                            <div class="space-y-2">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Paramétre d'indication</label>

                                <select :disabled="disableddoc" v-model="Items.jsonv.param"
                                        class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option  :value="null">Paramétre d'indication</option>
                                    <option v-for="(datavalue,index) of alldata.paramcaisse" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                </select>
                            </div>



                        </div>






                    </div>
                <div class="ti-modal-footer">
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        FERMER
                    </button>

                    <button @click="selected" type="button" class="hs-dropdown-toggle ti-btn ti-btn-success-full"  >
                        VALIDER
                    </button>


                </div>
            </div>
        </div>
    </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modallistfrsclt @elementSelectionnecltfrs="handleElementSelectedcltfrs" ref="modallistfrsclt"></modallistfrsclt>


</template>

<script>

    //import allfunction from "@/allfunction";
    import {isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modallistfrsclt from "@/components/views/pages/gestioncommerciale/achatvente/modal_listfrsclt";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",
        watch: {


        },

        computed:{
            disableddoc(){

                return this.Items.docnum ?true:false;
            },




        },
        components: {
            modallistfrsclt,

        },

        data() {
            return {
                isdisplay:false,
                datavalue:"",
                titlemodal:"",
                Items:{},
                alldata:{},
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                dataprice:[]
            };
        },
        methods: {

            selected(){

                this.Items.jsonotherdonne.moderglt=this.Items.jsonv.modereglement
                this.Items.jsonotherdonne.collaborateur=this.Items.jsonv.collaborateur
                this.Items.jsonotherdonne.commandetable=this.Items.jsonv.commandetable


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { item: this.Items};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('updateentetecommande', elementSelectionne);


                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },

            handleElementSelectedcltfrs(elementSelectionne) {

                this.Items.jsonotherdonne.cltfrs=elementSelectionne.value

                if(this.Items.jsonotherdonne.cltfrs!==null){


                    this.Items.jsonv.client=this.Items.jsonotherdonne.cltfrs.name
                    this.Items.jsonv.ifu=this.Items.jsonotherdonne.cltfrs.ifu

                }else{
                    this.Items.jsonv.client='';
                    this.Items.jsonv.ifu='';

                }




            },

            btnmodallistedescltfrs(){


                let listedatable=[],
                    titlemodal="",
                    option=this.typedoc,
                    typedoc="";

                titlemodal="Liste des clients"
                listedatable=this.alldata.beneficiairecl
                option='vvente'


                //this.$refs.modallistfrsclt.show(titlemodal,listedatable,this.typededocument,this.datainputlibelleforms);

                let $CUSTOMLIBELLEFRSCLT=JwtService.getCUSTOMLIBELLEFRSCLT()


                if (!isset($CUSTOMLIBELLEFRSCLT)){
                    $CUSTOMLIBELLEFRSCLT=JSON.parse($CUSTOMLIBELLEFRSCLT);

                }
                else{

                    $CUSTOMLIBELLEFRSCLT=this.$store.state.appcustomlibellefrsclt;

                    JwtService.setCUSTOMLIBELLEFRSCLT(JSON.stringify($CUSTOMLIBELLEFRSCLT))


                }


                this.$refs.modallistfrsclt.show(titlemodal,listedatable,option,this.datainputlibelleforms,$CUSTOMLIBELLEFRSCLT);

            },


            close(){

                this.isdisplay=false;


            },


            show(Items,alldata){



                this.Items=Items
                this.alldata=alldata


                this.isdisplay=true;

            },


            async  btnsuppression(){

                this.$store.dispatch('lancerchargement',true)

                //this.Items.cf=this.$store.state.caisseinfo;

                if (this.typedoc==='DEP'){this.typedoc="DP"}


                let value={
                    'cf':this.$store.state.caisseinfo,
                    'item':this.Items,
                    'document':'A'+this.typedoc,
                }


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/doc/caisse/addfkdfkdozojdj_refdepdpcc/'+this.$store.state.appsociete+'/add895418init51_refdepdpcc11515/'+randomString();
                let response =   axios  .post(url,value,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){

                            t.messagesucces="";
                            t.messageerror="";

                            t.messagesucces=res.data.message;

                            toastr['success']( t.messagesucces,"<b></b>");


                            t.$emit('closed',res.data.m);

                            t.isdisplay=false;


                        }
                        else{

                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");

                            t.isdisplay=false;

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))




            },







        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .modalstyle{
        max-width: 80%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }

</style>