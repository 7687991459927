<template>


    <div id="cover" class="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
        <div class="flex flex-col overflow-hidden bg-white rounded-md
                shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md"  >
            <div id="logincover" class="p-4 py-6 text-white bg-blue-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col
                    md:items-center md:justify-evenly">
                <div class="my-3 text-4xl font-bold tracking-wider text-center">
                    <a href="#">SIGCOM</a>
                </div>


                <p style="color: white;text-align: justify;" class="mt-6 font-normal text-gray-300 md:mt-0">

                    Avec Le logiciel de gestion commerciale SIGCOM, vous allez maitriser l’ensemble de votre activité
                    commerciale : Gestion des achats, Gestion des ventes, Administration et suivi des clients et Gestion du stock.


                </p>

                <p style="color: white;text-align: justify;" class="flex flex-col items-center justify-center mt-10 text-center">
                    <span>N'hésitez pas à nous contacter pour la création de votre dossier HSIGCOM et entamez ainsi votre parcours
                        vers une gestion simplifiée. Nous sommes là pour vous accompagner dès le début de cette collaboration.</span>
                    <a href="#" style="color: red;font-weight: bold" class="underline">Commencer!</a>
                </p>

            </div>
            <div class="p-5 bg-white md:flex-1">
                <h3 class="my-4 text-2xl font-semibold text-gray-700">Connexion au compte</h3>
                <div class="flex flex-col space-y-5">

                    <div v-if="datamessageerror.length>0" class="xxl:col-span-3 xl:col-span-6 col-span-12">
                        <div class="box border-0">
                            <div class="alert alert-danger border !border-danger mb-0 p-2" id="dismiss-alert60">
                                <div class="flex items-start">

                                    <div class="text-danger w-full">
                                        <div class="font-semibold flex justify-between">Erreur

                                            <!--button type="button" class="inline-flex bg-teal-50 rounded-sm text-teal-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-teal-50 focus:ring-teal-600" data-hs-remove-element="#dismiss-alert60">
                                                <span class="sr-only">Dismiss</span>
                                                <svg class="h-3 w-3" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path d="M0.92524 0.687069C1.126 0.486219 1.39823 0.373377 1.68209 0.373377C1.96597 0.373377 2.2382 0.486219 2.43894 0.687069L8.10514 6.35813L13.7714 0.687069C13.8701 0.584748 13.9882 0.503105 14.1188 0.446962C14.2494 0.39082 14.3899 0.361248 14.5321 0.360026C14.6742 0.358783 14.8151 0.38589 14.9468 0.439762C15.0782 0.493633 15.1977 0.573197 15.2983 0.673783C15.3987 0.774389 15.4784 0.894026 15.5321 1.02568C15.5859 1.15736 15.6131 1.29845 15.6118 1.44071C15.6105 1.58297 15.5809 1.72357 15.5248 1.85428C15.4688 1.98499 15.3872 2.10324 15.2851 2.20206L9.61883 7.87312L15.2851 13.5441C15.4801 13.7462 15.588 14.0168 15.5854 14.2977C15.5831 14.5787 15.4705 14.8474 15.272 15.046C15.0735 15.2449 14.805 15.3574 14.5244 15.3599C14.2437 15.3623 13.9733 15.2543 13.7714 15.0591L8.10514 9.38812L2.43894 15.0591C2.23704 15.2543 1.96663 15.3623 1.68594 15.3599C1.40526 15.3574 1.13677 15.2449 0.938279 15.046C0.739807 14.8474 0.627232 14.5787 0.624791 14.2977C0.62235 14.0168 0.730236 13.7462 0.92524 13.5441L6.59144 7.87312L0.92524 2.20206C0.724562 2.00115 0.611816 1.72867 0.611816 1.44457C0.611816 1.16047 0.724562 0.887983 0.92524 0.687069Z" fill="currentColor"></path>
                                                </svg>
                                            </button-->

                                        </div>
                                        <div class="text-[0.75rem] opacity-[0.8] mb-1">

                                            <ul class="mt-1.5 list-disc list-inside">
                                                <li v-for="(message,index) in datamessageerror" :key="index">{{message}}</li>

                                            </ul>


                                        </div>

                                        <div class="text-[0.75rem]">
                                            <!--a href="javascript:void(0);" class="text-info font-semibold me-2 inline-block">cancel</a>
                                            <a href="javascript:void(0);" class="text-danger font-semibold">open</a-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col space-y-1">
                        <label for="societe" class="text-sm font-semibold text-gray-500">Société</label>
                        <input
                                type="text"
                                v-model="items.societe"
                                id="societe"
                                autofocus
                                class="px-4 py-2 transition duration-300 border border-gray-300 rounded
                                focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />
                    </div>


                    <div class="flex flex-col space-y-1">
                        <label for="login" class="text-sm font-semibold text-gray-500">Login</label>
                        <input
                                type="text"
                                v-model="items.login"
                                id="login"
                                class="px-4 py-2 transition duration-300 border border-gray-300 rounded
                                focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />
                    </div>
                    <div class="flex flex-col space-y-1">
                        <div class="flex items-center justify-between">
                            <label for="password" class="text-sm font-semibold text-gray-500">Mot de passe</label>
                            <a href="#" class="text-sm text-blue-600 hover:underline focus:text-blue-800">Mot de passe oublié ?</a>
                        </div>
                        <input
                                type="password"
                                v-model="items.password"
                                id="password"
                                class="px-4 py-2 transition duration-300 border border-gray-300 rounded
                                focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                        />
                    </div>
                    <div class="flex items-center space-x-2">
                        <input  type="checkbox" v-model="items.remember"  id="remember" class="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0
                                focus:outline-none focus:ring-blue-200"
                        />
                        <label for="remember" class="text-sm font-semibold text-gray-500">Se souvenir</label>
                    </div>
                    <div>
                        <button @click="seconnecter" class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors
                                duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600
                                focus:outline-none focus:ring-blue-200 focus:ring-4"  >
                            Se connecter
                        </button>
                    </div>







                    <div class="flex flex-col space-y-5">

                    </div>
                </div>
            </div>
        </div>
    </div>



</template>

<script>
   // import allfunction from "@/allfunction";
    import {randomString} from "@/allfunction";
    import JwtService from "@/common/jwt.service";
    import templatesite from "@/components/template/apptemplate";
    //import {setAuthorization} from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "login",
        components: {
            templatesite
        },
        data() {
            return {
                items:{
                    societe:'',
                    login:'',
                    password:'',
                    remember:true
                },
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
            };
        },
        methods: {

            /*seconnecter(){
                if (this.items.password==='' || this.items.login===''){

                    this.presentToast("Veuillez saisir votre login et votre mot de passe")

                    return
                }

                this.$store.commit('isAuthenticated',{value:true});

                this.$router.push({path: '/'});

            },*/

            async  seconnecter(){
                this.datamessageerror=[];
                this.messagesuccess="";



                if (this.items.societe===''){

                    this.datamessageerror.push("Veuillez saisir la société.")

                }

                if (this.items.login===''){

                    this.datamessageerror.push("Veuillez saisir le login.")

                }

                if (this.items.password===''){

                    this.datamessageerror.push("Veuillez saisir le mot de passe.")

                }

                if (this.items.password.length<6){

                    this.datamessageerror.push("Le mot de passe doit être de 6 caractères minimum")

                }


                if ( this.datamessageerror.length>0){

                    return
                }


                this.$store.dispatch('lancerchargement',true)

                const t = this;

                t.$store.dispatch('lancerchargement',true)

                let response =   axios.post(process.env.VUE_APP_API_URL+'/login1478pojhgfderffg458755/'+randomString(),this.items,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ',
                        'csrftoken':''
                    },
                    withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })
                let res= await response
                    .then(function (res){

                        t.$store.dispatch('lancerchargement',true)

                        if(res.data.success){



                            t.messagesuccess=res.data.message;

                            t.$store.dispatch('lancerchargement',false)


                            JwtService.setAuthorization(res.data.datas._actk);
                            t.$store.commit('isAuthenticated',{value:true});
                            t.$store.commit('appusername',{name:res.data.datas._nme});
                            t.$store.commit('approle',{name:res.data.datas._rl});
                            t.$store.commit('appuserpicture',{name:t.$store.state.baseurlapifile+res.data.datas._picture});
                            t.$store.commit('appsociete',{name:res.data.datas._utk});
                            t.$store.commit('appserverdate',{name:res.data.datas.dateserv})




                            //**********************************


                            t.$store.commit('listeallcaisses',{value:res.data.datas.allcaisse})
                             t.$store.commit('paramsapp',{value:res.data.datas.__sssysapp})

                            t.$store.commit('datanotification',{value:res.data.datas.__notif})
                            t.$store.commit('nbrenotification',{value:res.data.datas.__countnotif})



                            t.$store.commit('accescomponent',{value:res.data.datas.iujhi7855})
                            t.$store.commit('nbrepersautorise',{value:res.data.datas.__nbrpers})

                            t.$store.commit('authorizechangementprix',{value:res.data.datas.__auth.authorizechangementprix})
                            t.$store.commit('authorizesignatureelectronique',{value:res.data.datas.__auth.authorizesignatureelectronique})
                            t.$store.commit('authorizenormalisation',{value:res.data.datas.__auth.authorizenormalisation})
                            t.$store.commit('authorizeprix0',{value:res.data.datas.__auth.authorizeprix0})
                            t.$store.commit('authorizefacturactionpartiel',{value:res.data.datas.__auth.authorizefacturactionpartiel})
                            t.$store.commit('authorizefacturepourgenereranalyse',{value:res.data.datas.__auth.authorizefacturepourgenereranalyse})
                            t.$store.commit('allccessapp',{value:res.data.datas.iujhi7855})


                            //**************************************



                            t.$store.commit('etatlogo',{etatlogo:t.$store.state.baseurlapifile+res.data.datas.__etatinfo.etatlogo})

                            t.$store.commit('etatraisonsocial',{etatraisonsocial:res.data.datas.__etatinfo.etatraisonsocial})
                            t.$store.commit('etatifu',{etatifu:res.data.datas.__etatinfo.etatifu})
                            t.$store.commit('etatrccm',{etatrccm:res.data.datas.__etatinfo.etatrccm})
                            t.$store.commit('etattelephone',{etattelephone:res.data.datas.__etatinfo.etattelephone})
                            t.$store.commit('etatadresse',{etatadresse:res.data.datas.__etatinfo.etatadresse})

                            t.$store.commit('sysmodule',{value:res.data.datas.__module})
                            t.$store.commit('paramsystem',{value:res.data.datas.__sssys})




                            JwtService.setToken(res.data.datas._actk);
                            JwtService.setTokenUser(res.data.datas._nme);
                            JwtService.setTokenRole(res.data.datas._rl);



                            t.$store.dispatch('loadevoluationdata')
                            t.$store.dispatch('loadlistedesarticles')

                            t.$router.push({name: 'acceuilpage'});




                        }
                        else{
                          //  t.datamessageerror=res.data.datamessage;
                            t.$store.dispatch('lancerchargement',false)
                            for (let message of res.data.datamessageerror){

                                t.datamessageerror.push(message)

                            }

                        }


                    })
                    .catch((error)=>{

                        this.$store.dispatch('lancerchargement',false)

                        }
                    )
                    .finally(() =>{

                        this.$store.dispatch('lancerchargement',false)

                    })




            },

        },
        mounted() {

          //  this.loaddata();
        }


    }
</script>

<style scoped>

  /*  @import url('https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.0.1/tailwind.min.css');*/
    @import url("@/assets/css/tailwindv2_0_1.css");


    #cover{
        background-image: url("@/assets/img/22.jpg");
        background-size: cover;
        width: 100%;
        height: 100%;
       /* position: fixed;*/
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    #logincover{
       /* background-image: url("@/assets/img/11.jpg");
        background-size: cover;
        width: 100%;
        height: 100%;
        position: fixed;
         top: 0;
        right: 0;
        bottom: 0;
        left: 0;*/
    }


</style>