<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">
                    <div class="md:flex block items-center justify-between my-[1.5rem] page-header-breadcrumb">
                        <div>


                        </div>
                        <div class="btn-list md:mt-0 mt-2">
                            <button @click="init" type="button" class="ti-btn bg-primary text-white btn-wave !font-medium !me-[0.375rem] !ms-0 !text-[0.85rem] !rounded-[0.35rem] !py-[0.51rem] !px-[0.86rem] shadow-none">
                                <i class="ri-refresh-fill  inline-block"></i>Actualiser
                            </button>
                        </div>
                    </div>


                    <div class="grid grid-cols-12 gap-6">


                        <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12">
                            <div class="box hrm-main-card primary">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                                <!--span class="avatar  !text-white">

                                                    <img :src="imgurlrdv" alt="">
                                                </span-->
                                        </div>
                                        <div class="flex-grow">
                                            <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">DEMANDE D'ACHAT EN COURS</span>
                                            <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbredocdaf}}</h5>
                                            <!--p class="mb-0">
                                                <span class="badge bg-primary/10 text-primary">This Month</span>
                                            </p-->
                                        </div>
                                        <!--div>
                                            <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12">
                            <div class="box hrm-main-card primary">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                                <!--span class="avatar  !text-white">

                                                    <img :src="imgurlrdv" alt="">
                                                </span-->
                                        </div>
                                        <div class="flex-grow">
                                            <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">BON DE COMMANDE FOURNISSEUR EN COURS</span>
                                            <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbredocbcf}}</h5>
                                            <!--p class="mb-0">
                                                <span class="badge bg-primary/10 text-primary">This Month</span>
                                            </p-->
                                        </div>
                                        <!--div>
                                            <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12">
                            <div class="box hrm-main-card primary">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                                <!--span class="avatar  !text-white">

                                                    <img :src="imgurlrdv" alt="">
                                                </span-->
                                        </div>
                                        <div class="flex-grow">
                                            <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">NOMBRE DE BON DE RECEPTION EN COURS</span>
                                            <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbredocbrf}}</h5>
                                            <!--p class="mb-0">
                                                <span class="badge bg-primary/10 text-primary">This Month</span>
                                            </p-->
                                        </div>
                                        <!--div>
                                            <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title" style="text-transform: uppercase">
                                        Bon de commande en cours
                                    </div>

                                </div>
                                <div class="box-body ">
                                    <div class="table-responsive mybodyheight300px">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>





                                                    <tr class="border-b border-defaultborder">
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° DOC</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">FOURNISSEUR</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° BON</th>
                                                        <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>


                                                    <template v-if="datatablebcf.length">

                                                        <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datatablebcf" :key="index">


                                                            <td  data-label="N° DOC  : "   >{{ datavalue.numdoc }}</td>
                                                            <td  data-label="FOURNISSEUR  : "   >{{ datavalue.nameclient }}</td>
                                                            <td  data-label="N° BON  : "   >{{ datavalue.numbon }}</td>
                                                            <!--td  data-label="ACTION  : "   >

                                                                <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                                        class=" ti-btn ti-btn-primary ">
                                                                    <i class="ri-folder-open-fill"></i> Ouvrir
                                                                </button>
                                                            </td-->

                                                        </tr>



                                                    </template>



                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="10"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>



                                                    </tbody>
                                                </table>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0">

                                    <div class="flex flex-col box-footer border-t-0 ">
                                        <div class="flex items-center flex-wrap overflow-auto">
                                            <div class="mb-2 sm:mb-0">

                                            </div>
                                            <div class="ms-auto">


                                                <nav aria-label="Page navigation">
                                                    <ul class="ti-pagination mb-0">
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Prev</button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                    :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                                {{ pageNumber }}
                                                            </button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title" style="text-transform: uppercase">
                                        Facture non réglée
                                    </div>

                                </div>
                                <div class="box-body">
                                    <div class="table-responsive mybodyheight300px">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>
                                                    <tr class="border-b border-defaultborder">

                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° DOC</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">FOURNISSEUR</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° BON</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MONTANT RESTANT</th>
                                                        <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <template v-if="datatablefacturefournisseurnonreglee.length">

                                                        <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datatablefacturefournisseurnonreglee" :key="index">





                                                            <td  data-label="N° DOC  : "   >{{ datavalue.numdoc }}</td>
                                                            <td  data-label="FOURNISSEUR  : "   >{{ datavalue.nameclient }}</td>
                                                            <td  data-label="N° BON  : "   >{{ datavalue.numbon }}</td>
                                                            <td  data-label="MONTANT RESTANT  : "   >{{ currencyFormatDE(datavalue.mtntttctrt) }}</td>
                                                            <!--td  data-label="ACTION  : "   >

                                                                <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                                        class=" ti-btn ti-btn-primary ">
                                                                    <i class="ri-folder-open-fill"></i> Ouvrir
                                                                </button>
                                                            </td-->



                                                        </tr>



                                                    </template>


                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>



                                                    </tbody>
                                                </table>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0">

                                    <div class="flex flex-col box-footer border-t-0 ">
                                        <div class="flex items-center flex-wrap overflow-auto">
                                            <div class="mb-2 sm:mb-0">

                                            </div>
                                            <div class="ms-auto">


                                                <nav aria-label="Page navigation">
                                                    <ul class="ti-pagination mb-0">
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Prev</button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                    :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                                {{ pageNumber }}
                                                            </button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>




                    <div class="grid grid-cols-12 gap-6">


                        <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12">
                            <div class="box hrm-main-card primary">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                                <!--span class="avatar  !text-white">

                                                    <img :src="imgurlrdv" alt="">
                                                </span-->
                                        </div>
                                        <div class="flex-grow">
                                            <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">BON DE COMMANDE EN COURS</span>
                                            <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbredocbc}}</h5>
                                            <!--p class="mb-0">
                                                <span class="badge bg-primary/10 text-primary">This Month</span>
                                            </p-->
                                        </div>
                                        <!--div>
                                            <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12">
                            <div class="box hrm-main-card primary">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                                <!--span class="avatar  !text-white">

                                                    <img :src="imgurlrdv" alt="">
                                                </span-->
                                        </div>
                                        <div class="flex-grow">
                                            <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">BON DE PREPATION DE LIVRAISON EN COURS</span>
                                            <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbredocbl}}</h5>
                                            <!--p class="mb-0">
                                                <span class="badge bg-primary/10 text-primary">This Month</span>
                                            </p-->
                                        </div>
                                        <!--div>
                                            <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 col-span-12">
                            <div class="box hrm-main-card primary">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                                <!--span class="avatar  !text-white">

                                                    <img :src="imgurlrdv" alt="">
                                                </span-->
                                        </div>
                                        <div class="flex-grow">
                                            <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">NOMBRE DE FACTURE CLIENT EN COURS DE PAYEMENT</span>
                                            <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbredocf}}</h5>
                                            <!--p class="mb-0">
                                                <span class="badge bg-primary/10 text-primary">This Month</span>
                                            </p-->
                                        </div>
                                        <!--div>
                                            <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                        </div>














                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title" style="text-transform: uppercase">
                                        Bon de préparation de livraison en cours
                                    </div>

                                </div>
                                <div class="box-body ">
                                    <div class="table-responsive mybodyheight300px">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>





                                                    <tr class="border-b border-defaultborder">
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° DOC</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CLIENT</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° BON</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">QTE RESTANTE</th>
                                                        <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>


                                                    <template v-if="datatablebpl.length">

                                                        <tr class="border-t hover:bg-gray-200 dark:hover:bg-light"
                                                            v-for="(datavalue,index) in datatablebpl" :key="index">



                                                            <td  data-label="N° DOC  : "   >{{ datavalue.numdoc }}</td>
                                                            <td  data-label="FOURNISSEUR  : "   >{{ datavalue.nameclient }}</td>
                                                            <td  data-label="N° BON  : "   >{{ datavalue.numbon }}</td>
                                                            <td  data-label="QTE RESTANTE  : "   >{{ datavalue.qterestant }}</td>
                                                            <!--td  data-label="ACTION  : "   >

                                                                <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                                        class=" ti-btn ti-btn-primary ">
                                                                    <i class="ri-folder-open-fill"></i> Ouvrir
                                                                </button>
                                                            </td-->

                                                        </tr>



                                                    </template>



                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="10"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>



                                                    </tbody>
                                                </table>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0">

                                    <div class="flex flex-col box-footer border-t-0 ">
                                        <div class="flex items-center flex-wrap overflow-auto">
                                            <div class="mb-2 sm:mb-0">

                                            </div>
                                            <div class="ms-auto">


                                                <nav aria-label="Page navigation">
                                                    <ul class="ti-pagination mb-0">
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Prev</button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                    :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                                {{ pageNumber }}
                                                            </button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title"  style="text-transform: uppercase">
                                        Facture non réglée
                                    </div>

                                </div>
                                <div class="box-body">
                                    <div class="table-responsive mybodyheight300px">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>
                                                    <tr class="border-b border-defaultborder">

                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° DOC</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">FOURNISSEUR</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° BON</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MONTANT RESTANT</th>
                                                        <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <template v-if="datatablefactureclientnonreglee.length">

                                                        <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datatablefactureclientnonreglee" :key="index">



                                                            <td  data-label="N° DOC  : "   >{{ datavalue.numdoc }}</td>
                                                            <td  data-label="FOURNISSEUR  : "   >{{ datavalue.nameclient }}</td>
                                                            <td  data-label="N° BON  : "   >{{ datavalue.numbon }}</td>
                                                            <td  data-label="MONTANT RESTANT  : "   >{{ currencyFormatDE(datavalue.mtntttctrt) }}</td>
                                                            <!--td  data-label="ACTION  : "   >

                                                                <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                                        class=" ti-btn ti-btn-primary ">
                                                                    <i class="ri-folder-open-fill"></i> Ouvrir
                                                                </button>
                                                            </td-->



                                                        </tr>



                                                    </template>


                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>



                                                    </tbody>
                                                </table>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0">

                                    <div class="flex flex-col box-footer border-t-0 ">
                                        <div class="flex items-center flex-wrap overflow-auto">
                                            <div class="mb-2 sm:mb-0">

                                            </div>
                                            <div class="ms-auto">


                                                <nav aria-label="Page navigation">
                                                    <ul class="ti-pagination mb-0">
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Prev</button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                    :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                                {{ pageNumber }}
                                                            </button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>






                </div>
            </div>
        </template>

    </templatesite>


    <modalopenform ref="modalopenform"  ></modalopenform>

</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/gestioncommerciale/menu";


    import * as JwtService from "@/common/jwt.service";
    import {currencyFormatDE, randomString} from "@/allfunction";
    import modalopenform from "@/components/views/pages/stock/mvtstock/modal_openform";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalopenform,

        },
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },

        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatablestockminimum(){

                let mvalue=this.datatablestockminimum;

                return this.paginate(mvalue) ;

            },
        },

        data() {
            return {
                searchword:"",
                datatablefindecontratpermannent:[],
                datatabledocday:[],
                datatablestockminimum:[],

                datatablefactureclientnonreglee:[],
                datatablefacturefournisseurnonreglee:[],
                datatablebpl:[],
                datatablebcf:[],

                nbredocdaf:0,
                nbredocbcf:0,
                nbredocbrf:0,
                nbredocbc:0,
                nbredocbpl:0,
                nbredocbl:0,
                nbredocf:0,



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],

            };
        },
        methods: {

            currencyFormatDE(p1){
                var number=Number(p1)
                return currencyFormatDE(number)
            },

            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },


            async loadcountdoc() {

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egldsds74gescom8571714dfe/gescom/tb/count/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.nbredocdaf=response.data.m.nbredocdaf;
                            t.nbredocbcf=response.data.m.nbredocbcf;
                            t.nbredocbrf=response.data.m.nbredocbrf;
                            t.nbredocbc=response.data.m.nbredocbc;
                            t.nbredocbpl=response.data.m.nbredocbpl;
                            t.nbredocbl=response.data.m.nbredocbl;
                            t.nbredocf=response.data.m.nbredocf;

                        }
                        else{
                            t.nbredocdaf=0;
                            t.nbredocbcf=0;
                            t.nbredocbrf=0;
                            t.nbredocbc=0;
                            t.nbredocbpl=0;
                            t.nbredocbl=0;
                            t.nbredocf=0;
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            async loadreturndoc($mtypdoc) {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egldsds74gescom8571714dfe/gescom/tb/doc/'+$mtypdoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){



                                if($mtypdoc==='FR'){
                                    t.datatablefacturefournisseurnonreglee=response.data.m
                                }

                                if($mtypdoc==='F'){
                                    t.datatablefactureclientnonreglee=response.data.m
                                }

                                if($mtypdoc==='BPL'){
                                    t.datatablebpl=response.data.m
                                }
                                if($mtypdoc==='BCF'){
                                    t.datatablebcf=response.data.m
                                }



                        }
                        else{

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },



            init(){
                this.loadcountdoc();
                this.loadreturndoc("FR");
                this.loadreturndoc("F");
                this.loadreturndoc("BPL");
                this.loadreturndoc("BCF");
                //this.inittableauelistedocday();



            },










            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            this.loadcountdoc();
            this.loadreturndoc("FR");
            this.loadreturndoc("F");
            this.loadreturndoc("BPL");
            this.loadreturndoc("BCF");
            //this.inittableauelistedocday();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>