<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>


                        </div>


                        <div class="box-body !p-0">


                            <div class="mt-3 mb-3 ">

                                <div class="grid lg:grid-cols-2 gap-6 w-full">
                                    <div class="space-y-2">
                                        <label >&nbsp;&nbsp;</label>
                                        <input class="w-full" type="date"  @change="refresh" v-model="datedebut" placeholder="Date début">
                                    </div>

                                    <div class="space-y-2">
                                        <label >&nbsp;&nbsp;</label>
                                        <input class="w-full" type="date" @change="refresh" v-model="datefin" placeholder="Date fin">
                                    </div>

                                    <div class="space-y-2">
                                        <label >&nbsp;&nbsp;</label>
                                        <input v-model="searchword" class="form-control form-control-lg w-full" type="text"
                                               placeholder="Rechercher un magasin en saisissant son nom."
                                               aria-label=".form-control-lg example">
                                    </div>

                                    <div class="space-y-2">
                                        <label >&nbsp;&nbsp;</label>
                                        <input v-model="searchwordcaisse" class="form-control form-control-lg w-full" type="text"
                                               placeholder="Rechercher une caisse en saisissant son nom."
                                               aria-label=".form-control-lg example">
                                    </div>


                                    <div class="space-y-2">
                                        <label >&nbsp;&nbsp;</label>
                                        <input v-model="searchwordcaisse" class="form-control form-control-lg w-full" type="text"
                                               placeholder="Rechercher un article en saisissant son nom."
                                               aria-label=".form-control-lg example">
                                    </div>


                                    <div class="space-y-2">
                                        <label >&nbsp;&nbsp;</label>
                                        <input v-model="searchwordcollaborateur" class="form-control form-control-lg w-full" type="text"
                                               placeholder="Rechercher un collaborateur en saisissant son nom."
                                               aria-label=".form-control-lg example">
                                    </div>








                                </div>

                            </div>


                            <div class="border-b-0 border-gray-200 dark:border-white/10">
                                <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">

                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                            id="CAJ-1" data-hs-tab="#CAJ" aria-controls="CAJ">
                                        CHIFFRE D'AFFAIRES JOUNALIER
                                    </button>

                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                            id="CADG-1" data-hs-tab="#CADG" aria-controls="CADG">
                                        CHIFFRE D'AFFAIRES GLOBAL (PAR ARTICLE)
                                    </button>

                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                            id="CAD-1" data-hs-tab="#CAD" aria-controls="CAD">
                                        CHIFFRE D'AFFAIRES DETAILLES (PAR ARTICLE)
                                    </button>

                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                            id="CAF-1" data-hs-tab="#CAF" aria-controls="CAF">
                                        CHIFFRE D'AFFAIRES PAR FACTURE (FACTURE REGLEE OU PARTIELEMENT REGLEE)
                                    </button>


                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                            id="CAFGB-1" data-hs-tab="#CAFGB" aria-controls="CAFGB">
                                        CHIFFRE D'AFFAIRES PAR FACTURE PAR COLLABORATEUR
                                    </button>



                                </nav>
                            </div>

                            <div class="">

                                <div id="CAJ"  role="tabpanel"
                                     aria-labelledby="CAJ-1"
                                     class="">
                                    <div class="grid lg:grid-cols-2 gap-6 row">

                                        <button @click="imprimeretatimprimercajournalier('CHIFFRE D\'AFFAIRES JOUNALIER','caj')"
                                            class="ti-btn ti-btn-primary-full w-full"><i class="ri-printer-line"></i> Aperçu avant impression</button>

                                    <button    @click="ExportExcel('caj','xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>

                                    </div>

                                    <table ref="caj" class="table table-striped table-bordered table-hover
                                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th>DOCUMENT</th>
                                            <th>DATE</th>
                                            <th>CAISSE</th>
                                            <th>CA</th>
                                            <th>REMISE</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="tableCAparcaisseparjours.length">
                                            <template v-for="(datavalue,index) in tableCAparcaisseparjours" :key="index">

                                                <tr>

                                                    <td  data-label="DOCUMENT  : "   >{{ datavalue.document }}</td>
                                                    <td  data-label="DATE  : "   >{{ datavalue.dateop }}</td>
                                                    <td  data-label="CAISSE  : "   >{{ datavalue.nomcaisse }}</td>
                                                    <td  data-label="CA  : " >{{ formatMoney(datavalue.montanttotal) }}</td>
                                                    <td  data-label="REMISE  : " >{{ formatMoney(datavalue.remisetotal) }}</td>

                                                </tr>

                                            </template>

                                            <tr>
                                                <td colspan="3"></td>
                                                <td>{{formatMoney(totalca1)}}</td>
                                                <td>{{formatMoney(totalremise1)}}</td>
                                            </tr>

                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="10"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>


                                    </table>

                                </div>


                                <div id="CADG"  role="tabpanel"
                                     aria-labelledby="CADG-1"
                                     class="hidden">

                                    <div class="grid lg:grid-cols-2 gap-6 row">
                                    <button @click="imprimeretatCApararticleparjoursglobal('CHIFFRE D\'AFFAIRES GLOBAL (PAR ARTICLE)','cadg')"
                                            class="ti-btn ti-btn-primary-full w-full"><i class="ri-printer-line"></i> Aperçu avant impression</button>

                                    <button    @click="ExportExcel('cadg','xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>
                                    </div>

                                    <table ref="cadg" class="table table-striped table-bordered table-hover
                                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th>DOCUMENT</th>
                                            <th>CAISSE</th>
                                            <th>MAGASIN</th>
                                            <th>ARTICLE</th>
                                            <th>QTE</th>
                                            <th>PU</th>
                                            <th>REMISE</th>
                                            <th>MONTANT</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="tableCApararticleparjoursglobal.length">
                                            <template v-for="(datavalue,index) in tableCApararticleparjoursglobal" :key="index">

                                                <tr>

                                                    <td  data-label="DOCUMENT  : "   >{{ datavalue.document }}</td>
                                                    <td  data-label="CAISSE  : "   >{{ datavalue.nomcaisse }}</td>
                                                    <td  data-label="MAGASIN  : "   >{{ datavalue.magasin }}</td>
                                                    <td  data-label="ARTICLE  : "   >{{ datavalue.article }}</td>
                                                    <td  data-label="QTE  : "   >{{ formatqte_pu(datavalue.qte) }}</td>
                                                    <td  data-label="PU  : "   >{{ formatqte_pu(datavalue.pu) }}</td>
                                                    <td  data-label="REMISE  : " >{{ formatMoney(datavalue.remisetotal) }}</td>
                                                    <td  data-label="MONTANT  : " >{{ formatMoney(datavalue.montanttotal) }}</td>

                                                </tr>

                                            </template>

                                            <tr>
                                                <td colspan="6"></td>
                                                <td>{{formatMoney(totalremise3)}}</td>
                                                <td>{{formatMoney(totalca3)}}</td>
                                            </tr>


                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="10"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>


                                    </table>

                                </div>


                                <div id="CAD"  role="tabpanel"
                                     aria-labelledby="CAD-1"
                                     class="hidden">

                                    <div class="grid lg:grid-cols-2 gap-6 row">

                                    <button @click="imprimeretatCApararticleparjoursdetailles('CHIFFRE D\'AFFAIRES DETAILLES (PAR ARTICLE) ','cad')"
                                            class="ti-btn ti-btn-primary-full  w-full"><i class="ri-printer-line"></i> Aperçu avant impression</button>

                                    <button    @click="ExportExcel('cad','xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>

                                    </div>



                                    <table ref="cad" class="table table-striped table-bordered table-hover
                                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th>DOCUMENT</th>
                                            <th>N° DOCUMENT</th>
                                            <th>DATE</th>
                                            <th>CAISSE</th>
                                            <th>COLLABORATEUR</th>
                                            <th>MAGASIN</th>
                                            <th>ARTICLE</th>
                                            <th>QTE</th>
                                            <th>PU</th>
                                            <th>REMISE</th>
                                            <th>MONTANT</th>
                                            <th>DATE SYSTEME</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="tableCApararticleparjoursdetailles.length">
                                            <template v-for="(datavalue,index) in tableCApararticleparjoursdetailles" :key="index">

                                                <tr>

                                                    <td  data-label="DOCUMENT  : "   >{{ datavalue.document }}</td>
                                                    <td  data-label="N° DOCUMENT  : "   >{{ datavalue.docref }}</td>
                                                    <td  data-label="DATE  : "   >{{ datavalue.dateop }}</td>
                                                    <td  data-label="CAISSE  : "   >{{ datavalue.nomcaisse }}</td>
                                                    <td  data-label="COLLABORATEUR  : "   >{{ datavalue.collaborateur }}</td>
                                                    <td  data-label="MAGASIN  : "   >{{ datavalue.magasin }}</td>
                                                    <td  data-label="ARTICLE  : "   >{{ datavalue.article }}</td>
                                                    <td  data-label="QTE  : "   >{{ formatqte_pu(datavalue.qte) }}</td>
                                                    <td  data-label="PU  : "   >{{ formatqte_pu(datavalue.pu) }}</td>
                                                    <td  data-label="REMISE  : " >{{ formatMoney(datavalue.remisetotal) }}</td>
                                                    <td  data-label="MONTANT  : " >{{ formatMoney(datavalue.montanttotal) }}</td>
                                                    <td  data-label="DATE SYSTEME  : " >{{ datavalue.datesystem}}</td>

                                                </tr>

                                            </template>

                                            <tr>
                                                <td colspan="7"></td>
                                                <td >{{totalqte}}</td>
                                                <td ></td>

                                                <td>{{formatMoney(totalremise2)}}</td>
                                                <td>{{formatMoney(totalca2)}}</td>
                                                <td ></td>
                                            </tr>


                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="15"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>


                                    </table>

                                </div>

                                <div id="CAF"  role="tabpanel"
                                     aria-labelledby="CAF-1"
                                     class="hidden">

                                    <div class="grid lg:grid-cols-2 gap-6 row">
                                    <button @click="imprimeretatimprimercaparfactureparcollaborateur('CHIFFRE D\'AFFAIRES PAR FACTURE (FACTURE REGLEE OU PARTIELEMENT REGLEE)','caf')"
                                            class="ti-btn ti-btn-primary-full"><i class="ri-printer-line"></i> Aperçu avant impression</button>

                                    <button    @click="ExportExcel('caf','xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>

                                    </div>


                                    <table ref="caf" class="table table-striped table-bordered table-hover
                                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th>DOCUMENT</th>
                                            <th>N° DOCUMENT</th>
                                            <th>DATE</th>
                                            <th>CAISSE</th>
                                            <th>COLLABORATEUR</th>
                                            <th>REMISE</th>
                                            <th>MONTANT</th>
                                            <th>MONTANT RESTANT</th>
                                            <th>DATE SYSTEME</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="tableCAparfactureparcollaborateur.length">
                                            <template v-for="(datavalue,index) in tableCAparfactureparcollaborateur" :key="index">

                                                <tr>

                                                    <td  data-label="DOCUMENT  : "   >{{ datavalue.document }}</td>
                                                    <td  data-label="N° DOCUMENT  : "   >{{ datavalue.docref }}</td>
                                                    <td  data-label="DATE  : "   >{{ datavalue.dateop }}</td>
                                                    <td  data-label="CAISSE  : "   >{{ datavalue.nomcaisse }}</td>
                                                    <td  data-label="COLLABORATEUR  : "   >{{ datavalue.collaborateur }}</td>
                                                    <td  data-label="REMISE  : " >{{ formatMoney(datavalue.mtntremise) }}</td>
                                                    <td  data-label="MONTANT  : " >{{ formatMoney(datavalue.montanttotal) }}</td>
                                                    <td  data-label="MONTANT RESTANT  : " >{{ formatMoney(datavalue.montanttotalrestant) }}</td>
                                                    <td  data-label="DATE SYSTEME  : " >{{ datavalue.datesystem}}</td>

                                                </tr>


                                            </template>

                                            <tr>
                                                <td colspan="5"></td>

                                                <td>{{formatMoney(totalREMISE)}}</td>
                                                <td>{{formatMoney(totalmontant)}}</td>
                                                <td>{{formatMoney(totalmontantrestant)}}</td>
                                                <td ></td>
                                            </tr>


                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="10"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>


                                    </table>

                                </div>

                                <div id="CAFGB"  role="tabpanel"
                                     aria-labelledby="CAFGB-1"
                                     class="hidden">
                                    <div class="grid lg:grid-cols-2 gap-6 row">
                                    <button @click="imprimeretatimprimercaparfactureparcollaborateurglobal('CHIFFRE D\'AFFAIRES PAR FACTURE PAR COLLABORATEUR','cafgb')"
                                            class="ti-btn ti-btn-primary-full"><i class="ri-printer-line"></i> Aperçu avant impression</button>

                                    <button    @click="ExportExcel('cafgb','xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>

                                    </div>

                                    <table ref="cafgb" class="table table-striped table-bordered table-hover
                                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th>DOCUMENT</th>
                                            <!--th>N° DOCUMENT</th-->
                                            <th>DATE</th>
                                            <th>CAISSE</th>
                                            <th>COLLABORATEUR</th>
                                            <th>REMISE</th>
                                            <th>MONTANT</th>
                                            <th>MONTANT RESTANT</th>
                                            <!--th>DATE SYSTEME</th-->

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="tableCAparfactureparcollaborateurglobal.length">
                                            <template v-for="(datavalue,index) in tableCAparfactureparcollaborateurglobal" :key="index">

                                                <tr>

                                                    <td  data-label="DOCUMENT  : "   >{{ datavalue.document }}</td>
                                                    <!--td  data-label="N° DOCUMENT  : "   >{{ datavalue.docref }}</td-->
                                                    <td  data-label="DATE  : "   >{{ datavalue.dateop }}</td>
                                                    <td  data-label="CAISSE  : "   >{{ datavalue.nomcaisse }}</td>
                                                    <td  data-label="COLLABORATEUR  : "   >{{ datavalue.collaborateur }}</td>
                                                    <td  data-label="REMISE  : " >{{ formatMoney(datavalue.mtntremise) }}</td>
                                                    <td  data-label="MONTANT  : " >{{ formatMoney(datavalue.montanttotal) }}</td>
                                                    <td  data-label="MONTANT RESTANT  : " >{{ formatMoney(datavalue.montanttotalrestant) }}</td>
                                                    <!--td  data-label="DATE SYSTEME  : " >{{ datavalue.datesystem}}</td-->

                                                </tr>


                                            </template>

                                            <tr>
                                                <td colspan="4"></td>

                                                <td>{{formatMoney(totalREMISEglobal)}}</td>
                                                <td>{{formatMoney(totalmontantglobal)}}</td>
                                                <td>{{formatMoney(totalmontantrestantglobal)}}</td>
                                                <!--td ></td-->
                                            </tr>


                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="10"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>


                                    </table>


                                </div>




                            </div>






                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>

    <modalprintrecap ref="modalprintrecap"></modalprintrecap>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";*/
    import {
        currencyFormatDE, currencyFormatMonney,
        currentDate,
        currentDateTime,
        ExportExcel, FormatNumber,
        getYear,
        impression, isValidDateString, NumbertoLettre,
        randomString
    } from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modalprintrecap from "@/components/views/pages/caisse/rapport/modal_printrecap";
    import qrcode from 'qrcode.vue'
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            soustitre(){
                this.taillemodel='A4';  return this.titlemodal+``
            },
            viewall(){
                if (this.mmois==="" || this.manne===""){

                    return true

                }
                else{

                    return false

                }


            },


            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){


                let mvalue=this.datatable,$dataretun=[];

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                if (this.searchnomcaisse!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.namebanque.toLowerCase().includes(t.searchnomcaisse.toLowerCase())

                    })

                }

                this.totalmontant=0



                mvalue.forEach(item => {
                    this.totalmontant=parseInt(item.montantcheque)+parseInt(this.totalmontant)

                })


                return mvalue ;


            },



            tableCAparcaisseparjours(){
                let mvalue=this.datatableCAparcaisseparjours;

                if (this.searchwordcaisse!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.nomcaisse.toLowerCase().includes(t.searchwordcaisse.toLowerCase())
                    })

                }


                this.totalca1=0
                this.totalremise1=0

                let t=this
                mvalue.forEach(function (item) {
                    t.totalca1=parseFloat(t.totalca1)+parseFloat(item.montanttotal)
                    t.totalremise1=parseFloat(t.totalremise1)+parseFloat(item.remisetotal)
                })



                this.dataexcel=mvalue;
                this.dataetat=mvalue;
                return   mvalue ;
            },

            tableCApararticleparjoursdetailles(){
                let mvalue=this.datatableCApararticleparjoursdetailles;


                if (this.searchword!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.magasin.toLowerCase().includes(t.searchword.toLowerCase())
                    })

                }

                if (this.searchwordcaisse!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.nomcaisse.toLowerCase().includes(t.searchwordcaisse.toLowerCase())
                    })

                }



                if (this.searchwordarticle!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.article.toLowerCase().includes(t.searchwordarticle.toLowerCase())
                    })

                }



                if (this.searchwordcollaborateur!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.collaborateur.toLowerCase().includes(t.searchwordcollaborateur.toLowerCase())
                    })

                }


                this.totalca2=0
                this.totalremise2=0
                this.totalqte=0

                let t=this
                mvalue.forEach(function (item) {
                    t.totalca2=parseFloat(t.totalca2)+parseFloat(item.montanttotal)
                    t.totalremise2=parseFloat(t.totalremise2)+parseFloat(item.remisetotal)
                    t.totalqte=parseFloat(t.totalqte)+parseFloat(item.qte)
                })



                this.dataexcel=mvalue;
                this.dataetat=mvalue;
                return   mvalue ;
            },

            tableCApararticleparjoursglobal(){
                let mvalue=this.datatableCApararticleparjoursglobal;

                if (this.searchword!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.magasin.toLowerCase().includes(t.searchword.toLowerCase())
                    })

                }

                if (this.searchwordcaisse!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.nomcaisse.toLowerCase().includes(t.searchwordcaisse.toLowerCase())
                    })

                }


                if (this.searchwordarticle!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.article.toLowerCase().includes(t.searchwordarticle.toLowerCase())
                    })

                }


                this.totalca3=0
                this.totalremise3=0

                let t=this
                mvalue.forEach(function (item) {
                    t.totalca3=parseFloat(t.totalca3)+parseFloat(item.montanttotal)

                    t.totalremise3=parseFloat(t.totalremise3)+parseFloat(item.remisetotal)
                })

                this.dataexcel=mvalue;
                this.dataetat=mvalue;
                return   mvalue ;
            },

            tableCAparfactureparcollaborateur(){
                let mvalue=this.datatableCAparfactureparcollaborateur;


                if (this.searchwordcollaborateur!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.collaborateur.toLowerCase().includes(t.searchwordcollaborateur.toLowerCase())
                    })

                }


                if (this.searchwordcaisse!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.nomcaisse.toLowerCase().includes(t.searchwordcaisse.toLowerCase())
                    })

                }



                this.totalmontant=0
                this.totalmontantrestant=0
                this.totalREMISE=0

                let t=this
                mvalue.forEach(function (item) {
                    t.totalmontant=parseFloat(t.totalmontant)+parseFloat(item.montanttotal)
                    t.totalmontantrestant=parseFloat(t.totalmontantrestant)+parseFloat(item.montanttotalrestant)
                    t.totalREMISE=parseFloat(t.totalREMISE)+parseFloat(item.mtntremise)

                })

                return  mvalue;
            },

            tableCAparfactureparcollaborateurglobal(){
                let mvalue=this.datatableCAparfactureparcollaborateurglobal;


                if (this.searchwordcollaborateur!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.collaborateur.toLowerCase().includes(t.searchwordcollaborateur.toLowerCase())
                    })

                }


                if (this.searchwordcaisse!==''){
                    let t=this;
                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return post.nomcaisse.toLowerCase().includes(t.searchwordcaisse.toLowerCase())
                    })

                }



                this.totalmontantglobal=0
                this.totalmontantrestantglobal=0
                this.totalREMISEglobal=0

                let t=this
                mvalue.forEach(function (item) {
                    t.totalmontantglobal=parseFloat(t.totalmontantglobal)+parseFloat(item.montanttotal)
                    t.totalmontantrestantglobal=parseFloat(t.totalmontantrestantglobal)+parseFloat(item.montanttotalrestant)
                    t.totalREMISEglobal=parseFloat(t.totalREMISEglobal)+parseFloat(item.mtntremise)
                    //console.log('t.totalmontant ',t.totalmontant)
                })
                // console.log('t.mvalue ',mvalue)


                return  mvalue;
            },





        },
        components: {
            /*templatesite,
            appheader,
            appsidebar,
            appmenu,*/
            qrcode,
            modalprintrecap,

        },

        data() {
            return {
                opened:[],
                mfontsize:10,
                taillemodel:'A4',
                mgetyear:getYear(),
                datenow:currentDate(),
                isdisplay:false,
                viewperiode:false,
                titlemodal:"",
                titlename:"",
                datatable:[],
                datasuiviereglement:[],
                viewdrop:false,
                tabvalue:{},
                selectedaffichage:'',
                datejournee:"",
                datedebut:"",
                typreglement:"",
                numpidcaisse:"",
                datefin:"",
                searchnomcaisse:"",
                searchbeneficiaire:"",
                typedoc:"",

                datatableCAparcaisseparjours:[],
                datatableCApararticleparjoursdetailles:[],
                datatableCApararticleparjoursglobal:[],
                datatableCAparfactureparcollaborateur:[],
                datatableCAparfactureparcollaborateurglobal:[],
                datatablesearch:[],

                searchparamdindication:'',
                searchparamdindication1:'',
                searchparamdindication2:'',
                searchparamdindication3:'',
                searchword:'',
                searchmagasin:'',
                searchwordcaisse:'',
                searchwordarticle:'',
                searchwordcollaborateur:'',


                totalca1:0,
                totalremise1:0,
                totalca2:0,
                totalremise2:0,
                totalqte:0,
                totalca3:0,
                totalremise3:0,
                totalmontant:0,
                totalmontantrestant:0,
                totalREMISE:0,
                totalmontantglobal:0,
                totalmontantrestantglobal:0,
                totalREMISEglobal:0,





                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],

                textToEncode: '',  // Initial text to encode in QR code
                size: 100,              // Size of the QR code
                level: 'L'              // Error correction level ('L', 'M', 'Q', 'H')
            };
        },
        methods: {



            formatqte_pu(value){
                return FormatNumber(value)

            },




            imprimeretatimprimercajournalier(valuetitre,tableref){
                let $tableref=this.$refs[tableref];


                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    listecatalogue:this.listecatalogue,
                    totalca1:this.totalca1,
                    totalremise1:this.totalremise1,
                    titresignataire:'CAISSIER',
                    nomdusignataire:this.$store.state.appusername,
                    tableref:$tableref.toString(),
                    titresponsable:'GERANT',
                    nomduresponsable:'',


                }

                this.$refs.modalprintrecap.show(tabvalue,$tableref);



            },

            imprimeretatCApararticleparjoursdetailles(valuetitre,tableref){

                let $tableref=this.$refs[tableref];

                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    tableCApararticleparjoursdetailles:this.tableCApararticleparjoursdetailles,
                    totalqte:this.totalqte,
                    totalremise2:this.totalremise2,
                    totalca2:this.totalca2,
                    titresignataire:'CAISSIER',
                    nomdusignataire:this.$store.state.appusername,
                    tableref:$tableref,
                    titresponsable:'GERANT',
                    nomduresponsable:'',


                }

                this.$refs.modalprintrecap.show(tabvalue,$tableref);
            },

            imprimeretatCApararticleparjoursglobal(valuetitre,tableref){

                let $tableref=this.$refs[tableref];

                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    tableCApararticleparjoursglobal:this.tableCApararticleparjoursglobal,
                    totalqte:this.totalqte,
                    totalremise3:this.totalremise3,
                    totalca3:this.totalca3,
                    titresignataire:'CAISSIER',
                    nomdusignataire:this.$store.state.appusername,
                    table:$tableref,
                    titresponsable:'GERANT',
                    nomduresponsable:'',


                }
                this.$refs.modalprintrecap.show(tabvalue,$tableref);

            },

            imprimeretatimprimercaparfactureparcollaborateur(valuetitre,tableref){

                let $tableref=this.$refs[tableref];

                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    tableCAparfactureparcollaborateur:this.tableCAparfactureparcollaborateur,
                    totalREMISE:this.totalREMISE,
                    totalmontant:this.totalmontant,
                    totalmontantrestant:this.totalmontantrestant,
                    titresignataire:'CAISSIER',
                    nomdusignataire:this.$store.state.appusername,
                    table:$tableref,
                    titresponsable:'GERANT',
                    nomduresponsable:'',


                }

                this.$refs.modalprintrecap.show(tabvalue,$tableref);

            },

            imprimeretatimprimercaparfactureparcollaborateurglobal(valuetitre,tableref){

                let $tableref=this.$refs[tableref];

                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    tableCAparfactureparcollaborateur:this.tableCAparfactureparcollaborateurglobal,
                    totalREMISE:this.totalREMISEglobal,
                    totalmontant:this.totalmontantglobal,
                    totalmontantrestant:this.totalmontantrestantglobal,
                    titresignataire:'CAISSIER',
                    nomdusignataire:this.$store.state.appusername,
                    table:$tableref,
                    titresponsable:'GERANT',
                    nomduresponsable:'',


                }

                this.$refs.modalprintrecap.show(tabvalue,$tableref);
            },


            formatMonney(value){
                return currencyFormatMonney(value)

            },

            numbertolettre(chiffre){

                return NumbertoLettre(chiffre)

            },


            btnviewdrop(){

                if(this.viewdrop===true){

                    this.viewdrop=false;

                }else{

                    this.viewdrop=true;

                }



            },


            ExportExcel(tableref,type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs[tableref];
                ExportExcel(type,name, fn, dl,elt);

            },



            async print4 () {


                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape" ){

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/stylelandscape1.css',
                        process.env.VUE_APP_URL+'print/stylelandscape.css'
                    ]


                }

                if (this.taillemodel==="A4" || this.taillemodel==="A5" ){


                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/styleportrait.css',
                        // process.env.VUE_APP_URL+'print/custom_print_a4.css',
                    ]

                }

                const printContent = this.$refs.printdoc.innerHTML;
                impression(printContent,$style,window.document.title)

            },



            formatMoney(value){
                return currencyFormatDE(value)

            },


            formatnumber(value){
                return FormatNumber(value)

            },



            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },




            printline(value)
            {
              console.log("value **************** ",value)
            },
            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

            async show(titlemodal,numpidcaisse,datedouverture){


                this.titlemodal=titlemodal



                if (datedouverture!==""){

                    this.viewperiode=false;
                    this.datejournee=datedouverture
                    this.datedebut=datedouverture
                    this.datefin=datedouverture
                    this.numpidcaisse=numpidcaisse

                    this.refresh()

                }
                else{

                    this.viewperiode=true;
                    this.datedebut=currentDate()
                    this.datefin=currentDate()
                    this.numpidcaisse=numpidcaisse
                    this.refresh()


                }

                this.isdisplay=true;
            },


            async refresh(){

                if (!isValidDateString(this.datedebut) || !isValidDateString(this.datefin)){return }

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/c/rapportpointc/'+this.datedebut+'/'+this.datefin+'/'+this.numpidcaisse+'/'+this.$store.state.appsociete+'/8954fddpap18rapportpointcaisse519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                       if(res.data.success){

                           t.datatableCAparcaisseparjours=res.data.m.d1;
                           t.datatableCApararticleparjoursdetailles=res.data.m.d2;
                           t.datatableCApararticleparjoursglobal=res.data.m.d3;
                           t.datatableCAparfactureparcollaborateur=res.data.m.d4;
                           t.datatableCAparfactureparcollaborateurglobal=res.data.m.d5;

                        }
                        else{
                            t.datatableCAparcaisseparjours=[];
                            t.datatableCApararticleparjoursdetailles=[];
                            t.datatableCApararticleparjoursglobal=[];
                            t.datatableCAparfactureparcollaborateur=[];
                            t.datatableCAparfactureparcollaborateurglobal=[];


                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },


            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .opened {
        background-color: red;
    }


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }



    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>