<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">
                    <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                        <div>
                            {{messagesucces}}
                        </div>
                    </div>

                    <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                        <div>
                            {{messageerror}}
                        </div>
                    </div>


                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="taxedimposition-item-2" data-hs-tab="#taxedimposition-2" aria-controls="taxedimposition-2">
                                   Tarifications,Taxe,TVA
                                </button>

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                        id="tarificationstock-item-1" data-hs-tab="#tarificationstock-1" aria-controls="tarificationstock-1">
                                    Tarification par dépôt
                                </button>


                                <!--button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="fournisseur-item-2" data-hs-tab="#fournisseur-2" aria-controls="fournisseur-2">
                                    Fournisseurs
                                </button-->

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="comptecomptable-item-2" data-hs-tab="#comptecomptable-2" aria-controls="comptecomptable-2">
                                    Compte comptable
                                </button>


                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="information-item-2" data-hs-tab="#information-2" aria-controls="information-2">
                                    Paramètre d'indication, Information libre
                                </button>



                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="restaurantitem-2" data-hs-tab="#restaurant-2" aria-controls="restaurant-2">
                                    Restaurant
                                </button>


                            </nav>
                        </div>

                        <div class="">


                            <div id="hs-tab-js-behavior-1" role="tabpanel"
                                 aria-labelledby="hs-tab-js-behavior-item-1" class="">


                                <div class="ti-custom-validation mt-5" >


                                    <div class="mt-3 grid lg:grid-cols-1 gap-6">



                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">INTITULE</label>
                                            <input v-model="Items.libelle" type="text" class="firstName my-auto ti-form-input  rounded-sm" placeholder="intitulé de l'article"   required>

                                        </div>



                                    </div>

                                    <div class="mt-3 grid lg:grid-cols-3 gap-6">



                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">SUIVIE DE STOCK</label>
                                            <select v-model="Items.suividestock" :disabled="checkusearticle" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option value="0">Non</option>
                                                <option value="1">Oui</option>
                                            </select>
                                        </div>



                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Seuil de sécurité(Stock minimum)</label>
                                            <input v-model="Items.datajsonarticles.stockminimum" :disabled="disabledstockminimum" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                        </div>


                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Stock max</label>
                                            <input v-model="Items.datajsonarticles.stockmaximum" :disabled="disabledstockminimum" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                        </div>




                                    </div>


                                    <div class="mt-3 grid lg:grid-cols-2 gap-6">



                                        <div class="space-y-2">

                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-10  col-span-10" >
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">unité</label>

                                                    <input placeholder="Veuillez sélectionner une unité"  v-if="Items.datajsonarticles.unite===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.datajsonarticles.unite.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-2">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                    <button @click="btnmodalfichierdebase('unite')"     class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>




                                            </div>



                                        </div>



                                        <div class="space-y-2">
                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-10  col-span-10" >
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">catalogue</label>

                                                    <input placeholder="Veuillez sélectionner un Catalogue"  v-if="Items.datajsonarticles.catalogue===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.datajsonarticles.catalogue.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-2">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                    <button @click="btnmodalfichierdebase('cat')"    class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>




                                            </div>



                                        </div>





                                    </div>


                                    <div class="mt-3 grid lg:grid-cols-3 gap-6">


                                        <div class="space-y-2">
                                           <select v-model="Items.datajsonarticles.perisable" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                               <option value="false">Article non périsable</option>
                                               <option value="true">Article périsable</option>
                                            </select>
                                        </div>

                                        <div class="space-y-2">
                                           <select v-model="Items.datajsonarticles.ensommeil" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                               <option value="false">Article non en sommeil</option>
                                               <option value="true">Article en sommeil</option>
                                            </select>
                                        </div>


                                        <div class="space-y-2">
                                           <select v-model="Items.datajsonarticles.typearticle" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                               <option value="Article">Article</option>
                                               <option value="matierspremiere">Matière première</option>
                                            </select>
                                        </div>




                                    </div>


                                    <div class="mt-3 grid lg:grid-cols-2 gap-6">



                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">SUIVIE DE EMBALLAGES</label>
                                            <select v-model="Items.datajsonarticles.paramdindication.param3.suiviedemballage" :disabled="checkusearticle"
                                                    class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option value="Non">Non</option>
                                                <option value="Oui">Oui</option>
                                            </select>
                                        </div>



                                        <div class="space-y-2">
                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-10  col-span-10" >
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">emballage</label>

                                                    <input placeholder="Veuillez sélectionner un Emballage"  v-if="Items.datajsonarticles.paramdindication.param3.emballage===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.datajsonarticles.paramdindication.param3.emballage.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-2">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                    <button :disabled="checksuiviedemballage" @click="btnmodalfichierdebase('emballage')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>




                                            </div>



                                        </div>





                                    </div>


                                    <div class="mt-3 grid lg:grid-cols-2 gap-6">



                                        <div class="space-y-2">


                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">magasin / Dépôt / entrepôt principal</label>

                                            <select v-model="Items.datajsonarticles.magasin" :disabled="checkusearticle" class="my-auto ti-form-input  rounded-sm ti-form-select
                                                rounded-sm !py-2 !px-3">
                                                <option  :value="null">Sélectionner l'entrepôt principal</option>
                                                <option v-for="(datavalue,index) of otherdata.magasinprincipal" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                            </select>


                                        </div>



                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Code barre</label>
                                            <input v-model="Items.reference" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                        </div>



                                    </div>



                                </div>
                            </div>


                         <div id="taxedimposition-2"  role="tabpanel"
                              aria-labelledby="hs-tab-js-behavior-item-2" class="hidden">


                             <div class="alert alert-info mt-2 text-center">
                                 <strong style="color: black;" >
                                     NB : Si la TVA n'est pas applicable à cet article, veuillez sélectionner la Taxe d'imposition qui convient.
                                 </strong>
                             </div>


                             <div class="mt-3 grid lg:grid-cols-4 gap-6">


                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">prix d'achat</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixachat" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">prix de vente (default)</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">prix promo</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixpromo" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente1}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente1" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente2}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente2" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente3}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente3" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente4}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente4" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente5}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente5" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente6}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente6" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">{{datainputlibelleforms.prixvente7}}</label>
                                     <input v-model="Items.datajsonarticles.tarification.prixvente7" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                 </div>

                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"  >Saisie de prix de vente</label>
                                     <select @change="onChange ($event)" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3"
                                             v-model="Items.datajsonarticles.tarification.typeprixvente"  >
                                         <option  value="1">Prix de vente TTC</option>
                                         <option value="0">Prix de vente HT</option>
                                     </select>
                                 </div>




                                 <div class="space-y-2">
                                     <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"  >TVA</label>

                                     <select :disabled="checkusearticle" class="form-control"
                                             id="tptva"
                                             v-model="Items.datajsonarticles.tptva"  >
                                         <option  value="0">La TVA n'est pas applicable à cet article</option>
                                         <option value="1">La TVA est applicable à cet article</option>
                                     </select>
                                 </div>


                                 <div class="space-y-2">

                                 <div v-if="Items.pid===''" style="width: 100%;max-height: 180px;overflow: auto">
                                     <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                     <table style="width: 100%"  class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable">
                                         <tbody>
                                         <tr>
                                             <td colspan="5">

                                                 <button style="width: 100%" @click="btnmodalgestiontarifdevente(createdatapv,0,'create')" class="ti-btn ti-btn-teal ti-btn-border-down border-0">GESTION DES TARIFS DE VENTE</button>
                                             </td>
                                         </tr>

                                         <tr v-for="(pv,j) in createdatapv" :key="j">
                                             <td data-label="Type de prix">
                                                 <template v-if="pv.mtyprix==='prixvente'">{{datainputlibelleforms.prixvente}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente1'">{{datainputlibelleforms.prixvente1}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente2'">{{datainputlibelleforms.prixvente2}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente3'">{{datainputlibelleforms.prixvente3}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente4'">{{datainputlibelleforms.prixvente4}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente5'">{{datainputlibelleforms.prixvente5}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente6'">{{datainputlibelleforms.prixvente6}}</template>
                                                 <template v-if="pv.mtyprix==='prixvente7'">{{datainputlibelleforms.prixvente7}}</template>

                                             </td>
                                             <td data-label="Min">{{pv.min}}</td>
                                             <td data-label="Max">{{pv.max}}</td>
                                             <td data-label="Prix de vente">{{pv.pu}}</td>
                                             <td data-label="Action ">

                                                 <button v-on:click="retirerselectedpv(j,createdatapv)" type="button"
                                                         class="ti-btn ti-btn-danger-full mb-1"><i class="ri-delete-column"></i></button>


                                             </td>
                                         </tr>



                                         </tbody>
                                     </table>
                                 </div>

                                 </div>




                             </div>


                             <div style="width: 100%;height: 15px"></div>

                             <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-4  col-span-12" >
                                        <label >Taxe d'imposition :</label>
                                        <select v-model="Items.datajsonarticles.normalisation.taxedimposition" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option  value="A">Exonéré</option>
                                            <option value="B">Taxable (18%)</option>
                                            <option value="C">Exportation de produits taxable</option>
                                            <option value="D">TVA régime d'exception</option>
                                            <option value="E">Régime fiscal TPS</option>
                                            <option value="F">Réservé (en cas de taxe de séjour, l'article doit être nommé 'TAXE DE SEJOUR')</option>

                                        </select>


                                    </div>

                                    <div class="xl:col-span-4  col-span-12" >
                                        <label >Taux d'imposition :</label>

                                        <input  type="text" class="form-control" disabled v-model="taxedimposition"  >

                                    </div>

                                    <div class="xl:col-span-4  col-span-12" >
                                        <label for="taxespecifique">Taxe spécifique :</label>

                                        <input v-model="Items.datajsonarticles.normalisation.tauxtaxespecifique" type="text" id="taxespecifique" class="form-control"   >

                                    </div>




                                    <div class="xl:col-span-12  col-span-12 mt-5">

                                        <label for="desctaxespecifique">Description du TS appliqué :</label>

                                        <input  type="text" id="desctaxespecifique" v-model="Items.datajsonarticles.normalisation.descriptiondutsapplique" class="form-control"   >


                                    </div>




                                </div>


                        </div>



                            <div id="tarificationstock-1"  role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-2" class="hidden">

                                <div class="alert alert-info text-center mt-3">
                                    <p style="color: black;font-size: 15px;"> Vous avez la possibilité d'inclure des tarifs et de définir des paramètres de stock particuliers pour chaque entrepôt / Dépôt / Officine concernant ce produit.</p>
                                </div>

                                <div class="box">
                                    <div class="box-header justify-between">
                                        <div class="box-title"> Liste des tarifs

                                        </div>

                                        <div class="prism-toggle">
                                            <button @click="btnmodaltarificationstock" type="button" class="ti-btn !py-1 !px-2 ti-btn-primary !font-medium !text-[0.75rem]">
                                                Ajouter
                                            </button>

                                        </div>

                                    </div>
                                    <div class="box-body">

                                        <div class=" xl:col-span-6  col-span-12 mt-4 mb-4">

                                            <div class="hs-dropdown ti-dropdown">
                                                <button class="ti-btn ti-btn-primary-full ti-dropdown-toggle !py-2"
                                                        type="button" id="dropdownMenuButton1b"
                                                        aria-expanded="false">
                                                    AFFICHER LES COLONNES<i
                                                        class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                </button>
                                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                    aria-labelledby="dropdownMenuButton1b">


                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">

                                                                <label class="custom-control-label" for="activecolums5">
                                                                    <input v-model="activecolums5" type="checkbox" class="custom-control-input" id="activecolums5">
                                                                    &nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente1}}
                                                                </label>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums1" type="checkbox" class="custom-control-input" id="activecolums1">
                                                                <label class="custom-control-label" for="activecolums1">&nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente2}}</label>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums2" type="checkbox" class="custom-control-input" id="activecolums2">
                                                                <label class="custom-control-label" for="activecolums2">&nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente3}}</label>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums3" type="checkbox" class="custom-control-input" id="activecolums3">
                                                                <label class="custom-control-label" for="activecolums3">&nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente4}}</label>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums4" type="checkbox" class="custom-control-input" id="activecolums4">
                                                                <label class="custom-control-label" for="activecolums4">&nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente5}}</label>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums6" type="checkbox" class="custom-control-input" id="activecolums6">
                                                                <label class="custom-control-label" for="activecolums6">&nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente6}}</label>
                                                            </div>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums7" type="checkbox" class="custom-control-input" id="activecolums7">
                                                                <label class="custom-control-label" for="activecolums7">&nbsp;&nbsp;&nbsp; {{datainputlibelleforms.prixvente7}}</label>
                                                            </div>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a class="ti-dropdown-item" href="#">
                                                            <div class="custom-control custom-checkbox">
                                                                <input v-model="activecolums8" type="checkbox" class="custom-control-input" id="activecolums8">
                                                                <label class="custom-control-label" for="activecolums8">&nbsp;&nbsp;&nbsp; Gestion des tarifs de vente</label>
                                                            </div>
                                                        </a>
                                                    </li>





                                                </ul>
                                            </div>
                                        </div>

                                        <div class="table-responsive">

                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Entrepôt</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Stock min</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Stock max</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Prix de vente</th>
                                                <th v-if="activecolums5" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente1}}</th>
                                                <th v-if="activecolums1" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente2}}</th>
                                                <th v-if="activecolums2" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente3}}</th>
                                                <th v-if="activecolums3" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente4}}</th>
                                                <th v-if="activecolums4" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente5}}</th>
                                                <th v-if="activecolums6" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente6}}</th>
                                                <th v-if="activecolums7" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{datainputlibelleforms.prixvente7}}</th>
                                                <th v-if="activecolums8" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Gestion des tarifs de vente</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                                            </tr>
                                            </thead>
                                            <tbody>


                                            <template v-if="jsonarticles.length">

                                                <tr style="font-weight: 600;/*background: khaki;*/"
                                                    v-for="(datavalue,index) in jsonarticles" :key="index">

                                                    <td  data-label="Entrepôt  : "   >{{ datavalue.mg.name }}</td>
                                                    <td  data-label="Stock min  : " >{{ datavalue.stockminimum }}</td>
                                                    <td  data-label="Stock max  : " >{{ datavalue.stockmaximum }}</td>
                                                    <td  data-label="Prix de vente  : " >{{ datavalue.pu }}</td>
                                                    <td v-if="activecolums5" :data-label="datainputlibelleforms.prixvente1">{{ datavalue.pu1 }}</td>
                                                    <td v-if="activecolums1" :data-label="datainputlibelleforms.prixvente2">{{ datavalue.pu2 }}</td>
                                                    <td v-if="activecolums2" :data-label="datainputlibelleforms.prixvente3">{{ datavalue.pu3 }}</td>
                                                    <td v-if="activecolums3" :data-label="datainputlibelleforms.prixvente4">{{ datavalue.pu4 }}</td>
                                                    <td v-if="activecolums4" :data-label="datainputlibelleforms.prixvente5">{{ datavalue.pu5 }}</td>
                                                    <td v-if="activecolums6" :data-label="datainputlibelleforms.prixvente6">{{ datavalue.pu6 }}</td>
                                                    <td v-if="activecolums7" :data-label="datainputlibelleforms.prixvente7">{{ datavalue.pu7 }}</td>
                                                    <td v-if="activecolums8" data-label="Gestion des tarifs de vente  : " >

                                                        <table style="width: 100%" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable">
                                                            <tbody>
                                                            <tr>
                                                                <td colspan="5">
                                                                    <button style="width: 100%" @click="btnmodalgestiontarifdevente(datavalue.pv,index,'add')" class="ti-btn ti-btn-teal ti-btn-border-down border-0">GESTION DES TARIFS DE VENTE</button>
                                                                </td>
                                                            </tr>

                                                            <tr v-for="(pv,j) in datavalue.pv" :key="j">
                                                                <td data-label="Type de prix">

                                                                    <template v-if="pv.mtyprix==='prixvente'">{{datainputlibelleforms.prixvente}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente1'">{{datainputlibelleforms.prixvente1}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente2'">{{datainputlibelleforms.prixvente2}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente3'">{{datainputlibelleforms.prixvente3}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente4'">{{datainputlibelleforms.prixvente4}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente5'">{{datainputlibelleforms.prixvente5}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente6'">{{datainputlibelleforms.prixvente6}}</template>
                                                                    <template v-if="pv.mtyprix==='prixvente7'">{{datainputlibelleforms.prixvente7}}</template>

                                                                </td>
                                                                <td data-label="Min">{{pv.min}}</td>
                                                                <td data-label="Max">{{pv.max}}</td>
                                                                <td data-label="Prix de vente">{{pv.pu}}</td>
                                                                <td data-label="Action ">

                                                                    <button v-on:click="retirerselectedpv(j,datavalue.pv)" type="button"
                                                                            class="ti-btn ti-btn-danger-full mb-1"><i class="ri-delete-column"></i></button>


                                                                </td>
                                                            </tr>



                                                            </tbody>
                                                        </table>

                                                    </td>

                                                    <td  data-label="Action  : "  >

                                                        <button v-if="datavalue.pidligne!==''"  :title="'modifier '+datavalue.mg.name"
                                                                v-on:click="mshow=true;modifierpu(datavalue,index)" type="button"
                                                                class="ti-btn ti-btn-success-full mb-1"><i class="ri-pen-nib-line"></i>Modifier</button>


                                                        <button v-if="datavalue.pidligne===''"
                                                                :title="'retirer '+datavalue.mg.name"
                                                                v-on:click="retirerselectedmagasin(index,datavalue.mgp,datavalue.bd,datavalue.mg.pid)" type="button"
                                                                class="ti-btn ti-btn-danger-full mb-1"><i class="ri-delete-row"></i>Supprimer</button>


                                                    </td>


                                                </tr>


                                            </template>



                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>
                                            </template>


                                            </tbody>
                                        </table>

                                        </div>



                                    </div>

                                </div>



                            </div>


                            <!--div id="fournisseur-2"  role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-2" class="hidden">






                             </div-->

                            <div id="information-2"  role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-2" class="hidden">



                                <div class="mt-3 grid lg:grid-cols-1 gap-6">



                                    <div class="space-y-2">

                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Paramètre d'indication</label>

                                        <select v-model="Items.datajsonarticles.paramdindication.param" :disabled="checkusearticle" class="my-auto ti-form-input  rounded-sm ti-form-select
                                                rounded-sm !py-2 !px-3">
                                            <option  :value="null">Sélectionner un paramètre d'indication</option>
                                            <option v-for="(datavalue,index) of otherdata.paramcom" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                        </select>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre</label>
                                        <input v-model="Items.datajsonarticles.information.info" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 1</label>
                                        <input v-model="Items.datajsonarticles.information.info1" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>





                                    </div>

                                <div class="mt-3 grid lg:grid-cols-2 gap-6">


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 2</label>
                                        <input v-model="Items.datajsonarticles.information.info2" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 3</label>
                                        <input v-model="Items.datajsonarticles.information.info3" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>




                                </div>


                          </div>

                            <div id="comptecomptable-2"  role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-2" class="hidden">

                                <div class="mt-3 grid lg:grid-cols-1 gap-6">



                                    <div class="space-y-2">

                                        <div class="grid grid-cols-12  gap-x-6 mt-3">

                                            <div class="xl:col-span-10  col-span-10" >
                                                <input placeholder="Veuillez sélectionner un compte de vente local"  v-if="Items.datajsonarticles.comptetva.compteventelocal===null" type="text" class="form-control" disabled>
                                                <input v-else :value="Items.datajsonarticles.comptetva.compteventelocal.numero+' '+Items.datajsonarticles.comptetva.compteventelocal.libelle" type="text" class="form-control" disabled>
                                            </div>
                                            <div class="xl:col-span-2  col-span-2">
                                                <button @click="btnmodalcompta('ventelocal')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                            </div>




                                        </div>



                                    </div>



                                    <div class="space-y-2">

                                        <div class="grid grid-cols-12  gap-x-6 mt-3">

                                            <div class="xl:col-span-10  col-span-10" >
                                                <input placeholder="Veuillez sélectionner un compte de vente export"  v-if="Items.datajsonarticles.comptetva.compteventeexport===null" type="text" class="form-control" disabled>
                                                <input v-else :value="Items.datajsonarticles.comptetva.compteventeexport.numero+' '+Items.datajsonarticles.comptetva.compteventeexport.libelle" type="text" class="form-control" disabled>
                                            </div>
                                            <div class="xl:col-span-2  col-span-2">

                                                <button @click="btnmodalcompta('venteexport')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                            </div>




                                        </div>



                                    </div>



                                </div>



                            </div>




                            <div id="restaurant-2"  role="tabpanel" aria-labelledby="restaurantitem-2" class="hidden">

                                <div class="grid grid-cols-12 gap-x-6">



                                    <div class="xl:col-span-4 col-span-12">
                                        <div class="box">
                                            <div class="box-header justify-between">
                                                <div class="box-title">
                                                    Ajouter les Accompagnements
                                                </div>


                                            </div>
                                            <div class="box-body !p-0 mybodyheight400px">

                                                <div class="row">



                                                    <div class="col-md-8">

                                                        <select v-model="selectaccompagnement" class="ti-form-select rounded-sm !py-2 !px-3"
                                                                id="selectaccompagnement">
                                                            <option :value="null">Sélectionner un accompagnement</option>
                                                            <option v-for="(vline, index) in otherdata.accompagnement" :key="index" :value="vline">{{ vline.name }}</option>
                                                        </select>


                                                    </div>
                                                    <div class="col-md-4 mt-3">
                                                        <button @click="btnajoutaccompagnement" class="ti-btn ti-btn-primary-full">Ajouter</button>
                                                    </div>
                                                </div>


                                                <div class="table-responsive">

                                                    <div class="flex flex-col h-screen">
                                                        <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">

                                                            <ul class="list-group list-group-flush max-h-[22.5rem]">
                                                                <li class="list-group-item !border-t-0 !border-s-0 !border-e-0 bottomline" v-for="(datavalue,j) of dataaccompagnement" :key="j">
                                                                    <div class="flex items-center flex-grow leading-none">

                                                                        <div class="leading-none flex-grow">
                                                                            <span class="mt-2 mb-2 font-semibold block mb-2">{{datavalue.name}}</span>
                                                                        </div>
                                                                        <div class="text-end me-3">
                                                                <span class="block font-semibold mb-1">
                                                                    <button @click="removeline(j)" class="ti-btn ti-btn-danger-full"><i class="ri-delete-bin-2-line"></i></button>
                                                                </span>

                                                                        </div>

                                                                    </div>
                                                                </li>



                                                            </ul>






                                                        </div>
                                                    </div>

                                                </div>




                                            </div>
                                        </div>
                                    </div>










                                </div>



                            </div>




                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'"  class="ti-btn ti-btn-primary-full" @click="saveItems">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" @click="updateItems">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modaltarificationstock @elementSelectionnetarificationstock="handleElementSelectionnetarificationstock" ref="modaltarificationstock"></modaltarificationstock>
    <modalfichierdebase @elementSelectionnefichierdebase="handleElementSelectionnefichierdebase" ref="modalfichierdebase"></modalfichierdebase>
    <modalcompta @elementSelectionnecompta="handleElementSelectionnecompta" ref="modalcompta"></modalcompta>
    <modalgestiontarifdevente @elementSelectionnegestiontarifdevente="handleElementSelectionnegestiontarifdevente" ref="modalgestiontarifdevente"></modalgestiontarifdevente>
    <modalmodifcationtarifentrepot @elementSelectionnemodifcationtarifentrepot="handleElementSelectionnemodifcationtarifentrepot" ref="modalmodifcationtarifentrepot"></modalmodifcationtarifentrepot>

</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";*/
   // import allfunction from "@/allfunction";
    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modaltarificationstock from "@/components/views/pages/stock/articles/modal_tarificationstock";
    import modalfichierdebase from "@/components/views/pages/stock/articles/modal_fichierdebase";
    import modalcompta from "@/components/views/pages/stock/articles/modal_compta";
    import modalgestiontarifdevente from "@/components/views/pages/stock/articles/modal_gestiontarifdevente";
    import modalmodifcationtarifentrepot from "@/components/views/pages/stock/articles/modal_modifcationtarifentrepot";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{

            disabledidunitettc: function (){
                let typeprixvente=  this.Items.datajsonarticles.tarification.typeprixvente+1

                let mvalue=false

                if (typeprixvente>1){
                    mvalue= false;
                    //   this.Items.datajsonarticles.tarification.typttc=1.18
                }
                else{
                    mvalue=true;
                    //this.Items.datajsonarticles.tarification.typttc=1
                }

                return mvalue;

            },

            useItem: function (){

                if (this.Items.usear===0){
                    return false;
                    //  return "";
                }else{
                    return true;
                    // return "disabled";
                }

            },

            checkusearticle: function (){

                if (this.Items.nbreusearticle>0){
                    // this.addpumagasinprincipal=1;
                    return true;
                    //  return "";
                }else{
                    //  this.addpumagasinprincipal=0;
                    return false;
                    // return "disabled";
                }

            },

            checksuiviedemballage: function (){
                if (this.Items.nbreusearticle>0){

                    return true;
                }
                else{


                    if (this.Items.datajsonarticles.paramdindication.param3.suiviedemballage==="Non"){

                        this.Items.datajsonarticles.paramdindication.param3.emballage=null
                        this.Items.datajsonarticles.paramdindication.param3.typeoperation='default'

                        return true;
                        //  return "";
                    }
                    else{
                        this.Items.datajsonarticles.paramdindication.param3.typeoperation='endetail'
                        return false;
                        // return "disabled";
                    }



                }

            },

            checkVal: function (){

                if (this.Items.pid===""){
                    return false;
                    //  return "";
                }else{
                    return true;
                    // return "disabled";
                }

            },


            taxedimposition:function () {



                if (this.Items.datajsonarticles.normalisation.taxedimposition==="B" ||
                    this.Items.datajsonarticles.normalisation.taxedimposition==="D" ){

                    this.Items.datajsonarticles.normalisation.tauxtaxedimposition=0.18;
                     //this.Items.datajsonarticles.tarification.typeprixvente=1;
                    ///this.Items.datajsonarticles.tarification.typttc=1.18;
                    this.Items.datajsonarticles.tptva=1;
                }
                else{
                    this.Items.datajsonarticles.normalisation.tauxtaxedimposition=0;
                    // this.Items.datajsonarticles.tarification.typeprixvente=0;
                    // this.Items.datajsonarticles.tarification.typttc=1;
                    this.Items.datajsonarticles.tptva=0;
                }




                return this.Items.datajsonarticles.normalisation.tauxtaxedimposition;



            },

            disabledstockminimum:function(){

                if (this.Items.suividestock===0 || this.Items.suividestock==='0'){

                    if (this.Items.pid===""){
                        this.Items.datajsonarticles.stockminimum=0;
                    }



                    return true;

                }else {

                    if (this.Items.pid===""){
                        this.Items.datajsonarticles.stockminimum=0;
                    }
                    return false;

                }



            }




        },
        components: {

            modaltarificationstock,
            modalcompta,
            modalgestiontarifdevente,
            modalmodifcationtarifentrepot,
            modalfichierdebase

        },

        data() {
            return {
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                titlemodal:"",
                typedoc:"",
                typeaction:"add",
                Items: {},

                nbremg:false,
                datainputlibelleforms:[],
                alldata:[],
                listefrs:[],
                jsonaccompagnement:[],
                jsonarticles:[],
                newsjsonarticles:[],
                createdatapv:[],
                datapv:[],
                mdataclose:[],
                mypv:{},
                indexpv:'',
                selectaccompagnement:null,
                dataaccompagnement:[],




                activecolums1:false,
                activecolums2:false,
                activecolums3:false,
                activecolums4:false,
                activecolums5:false,
                activecolums6:false,
                activecolums7:false,
                activecolums8:true,



                otherdata:[],

            };
        },
        methods: {

            removeline(index){


                this.dataaccompagnement.splice(index,1)


            },

            btnajoutaccompagnement(){

                if (this.selectaccompagnement===null){return}

                let checkexist=0;
                for (let valueanalyse of this.dataaccompagnement) {


                    if (valueanalyse.pid===this.selectaccompagnement.pid){
                        checkexist=1;

                        break;
                    }

                }



                if (checkexist===0){

                    this.dataaccompagnement.push(this.selectaccompagnement)


                    console.log("dataaccompagnement  ",this.dataaccompagnement)




                    this.selectaccompagnement=null
                }else{          toastr['error']( 'Cet accompagnement existe déjà dans la liste. Veuillez en sélectionner un autre.',"<b></b>");}




            },

            btnmodaltarificationstock(){

                if (this.Items.datajsonarticles.magasin===null ){

                    toastr['error']( "Veuillez sélectionner l'entrpôt principal (Onglet Général)","<b></b>");

                    return
                }

               /* if (this.Items.datajsonarticles.tarification.prixachat===0 ||
                    this.Items.datajsonarticles.tarification.prixvente===0 ){

                    toastr['error']( "Veuillez saisir le prix d'achat et le prix de vente par défaut (Onglet tarification, Taxe et Tva)","<b></b>");

                    return
                }*/


                let listedatable=this.otherdata.magasin,
                    titlemodal="";

                this.$refs.modaltarificationstock.show(titlemodal,listedatable,this.datainputlibelleforms,
                    this.Items.datajsonarticles.tarification.prixachat,this.jsonarticles,this.newsjsonarticles);

            },

            handleElementSelectionnetarificationstock(elementSelectionne) {

                if (elementSelectionne.typ==="add"){
                    this.jsonarticles.push(elementSelectionne.value)
                    this.newsjsonarticles.push(elementSelectionne.value)
                }




            },

            handleElementSelectionnefichierdebase(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent


                if (elementSelectionne.typ==="unite"){
                     this.Items.datajsonarticles.unite=elementSelectionne.value
                    this.otherdata.unite=elementSelectionne.datatable
                 }

                 if (elementSelectionne.typ==="cat"){
                     this.Items.datajsonarticles.catalogue=elementSelectionne.value
                     this.otherdata.catalogue=elementSelectionne.datatable
                 }

                 if (elementSelectionne.typ==="emballage"){
                     this.Items.datajsonarticles.paramdindication.param3.emballage=elementSelectionne.value
                     this.otherdata.emballage=elementSelectionne.datatable
                 }


            },

            btnmodalfichierdebase(typ){

                let listedatable=[],
                    titlemodal="",
                    typedoc=typ;


                if (typ==="emballage"){

                    listedatable=this.otherdata.emballage
                    titlemodal="Liste des emballages"
                }


                if (typ==="unite"){

                    listedatable=this.otherdata.unite
                    titlemodal="Liste des unités"
                }

                if (typ==="cat"){

                    listedatable=this.otherdata.catalogue
                    titlemodal="Liste des catalogues"
                }

                this.$refs.modalfichierdebase.show(titlemodal,listedatable,typedoc);

            },

            handleElementSelectionnecompta(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent


                if (elementSelectionne.typ==="ventelocal"){
                     this.Items.datajsonarticles.comptetva.compteventelocal=elementSelectionne.value
                    //this.otherdata.unite=elementSelectionne.datatable
                 }

                 if (elementSelectionne.typ==="venteexport"){
                     this.Items.datajsonarticles.comptetva.compteventeexport=elementSelectionne.value
                     //this.otherdata.catalogue=elementSelectionne.datatable
                 }




            },

            btnmodalcompta(typ){

                let listedatable=[],
                    titlemodal="Plan comptable",
                    typedoc=typ;


                if (typ==="ventelocal"){

                    listedatable=this.otherdata.compteventelocal
                    //titlemodal="Liste des emballages"
                }


                if (typ==="venteexport"){

                    listedatable=this.otherdata.compteventeexport
                   // titlemodal="Liste des unités"
                }





                this.$refs.modalcompta.show(titlemodal,listedatable,typedoc);

            },

            handleElementSelectionnegestiontarifdevente(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent

                if (elementSelectionne.typ==="add"){
                      this.jsonarticles[elementSelectionne.index].pv=elementSelectionne.value

                }

                if (elementSelectionne.typ==="create"){
                    this.createdatapv=elementSelectionne.value

                }




            },

            btnmodalgestiontarifdevente(datapv,index,btnaction){

                let  titlemodal="GESTION DES TARIFS DE VENTE";

                //this.datapv=datapv

                this.$refs.modalgestiontarifdevente.show(titlemodal,this.datainputlibelleforms,datapv,index,btnaction);

            },

            handleElementSelectionnemodifcationtarifentrepot(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent

                /*if (elementSelectionne.typ==="add"){
                    this.jsonarticles[elementSelectionne.index].pv=elementSelectionne.value

                }

                if (elementSelectionne.typ==="create"){
                    this.createdatapv=elementSelectionne.value

                }*/

                this.jsonarticles[elementSelectionne.index]=elementSelectionne.value
            },

            modifierpu(value,index){
                this.selectedlignemagasin=value
                this.mshow=true;

                let  titlemodal="MODIFICATION DES TARIFICATIONS ET STOCK : "+value.mg.name;

                //this.datapv=datapv

                this.$refs.modalmodifcationtarifentrepot.show(titlemodal,this.datainputlibelleforms,value,index);


            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,mItems,datainputlibelleforms,otherdata,jsonarticles=[],dataaccompagnement=[]){


                this.titlemodal=titlemodal
                this.typeaction=typeaction
               // this.typedoc=typedoc
                this.Items=mItems
                this.selectaccompagnement=null
                this.jsonarticles=jsonarticles
                this.datainputlibelleforms=datainputlibelleforms
                this.otherdata=otherdata
                this.dataaccompagnement=dataaccompagnement
                this.messagesucces="";
                this.messageerror="";

                this.jsonaccompagnement=[]
 
                this.isdisplay=true;

            },

              async  saveItems(){


                  this.$store.dispatch('lancerchargement',true)
                  if ( this.Items.datajsonarticles.magasin===null
                      || this.Items.unite===null ||   this.Items.libelle===""){


                      this.$store.dispatch('lancerchargement',false)
                      toastr['error']( "Veuillez saisir toutes les informations (l'entrepôt, le nom de l'article, l'unite de comptage)","<b></b>");

                  }
                  else {




                      if (this.newsjsonarticles.length===0){


                          this.newsjsonarticles.push({
                              'pidligne':'',
                              'mgp':1,
                              'bd':0,
                              'pv':this.createdatapv,
                              'mg':this.Items.datajsonarticles.magasin,
                              'pu':this.Items.datajsonarticles.tarification.prixvente,
                              'pu1':this.Items.datajsonarticles.tarification.prixvente1,
                              'pu2':this.Items.datajsonarticles.tarification.prixvente2,
                              'pu3':this.Items.datajsonarticles.tarification.prixvente3,
                              'pu4':this.Items.datajsonarticles.tarification.prixvente4,
                              'pu5':this.Items.datajsonarticles.tarification.prixvente5,
                              'pu6':this.Items.datajsonarticles.tarification.prixvente6,
                              'pu7':this.Items.datajsonarticles.tarification.prixvente7,
                              'pa':this.Items.datajsonarticles.tarification.prixachat,
                          })

                          this.jsonarticles.push({
                              'pidligne':'',
                              'mgp':1,
                              'bd':0,
                              'pv':this.createdatapv,
                              'mg':this.Items.datajsonarticles.magasin,
                              'pu':this.Items.datajsonarticles.tarification.prixvente,
                              'pu1':this.Items.datajsonarticles.tarification.prixvente1,
                              'pu2':this.Items.datajsonarticles.tarification.prixvente2,
                              'pu3':this.Items.datajsonarticles.tarification.prixvente3,
                              'pu4':this.Items.datajsonarticles.tarification.prixvente4,
                              'pu5':this.Items.datajsonarticles.tarification.prixvente5,
                              'pu6':this.Items.datajsonarticles.tarification.prixvente6,
                              'pu7':this.Items.datajsonarticles.tarification.prixvente7,
                              'pa':this.Items.datajsonarticles.tarification.prixachat,
                          })
                          this.addpumagasinprincipal=1;
                      }


                      this.Items.jsonarticles=this.jsonarticles
                      this.Items.newsjsonarticles=this.newsjsonarticles

                      this.Items.articlesenrestaurant.accompagnement=this.dataaccompagnement;


                      const t = this;
                      let url=process.env.VUE_APP_API_URL+'/egl745874pstock771714dfe/ar/add/'+this.$store.state.appsociete+'/'+randomString();
                      let response =   axios  .post(url,this.Items,{
                          headers: {
                              'X-Requested-With': 'XMLHttpRequest',
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer ' + JwtService.getToken(),
                              'csrftoken': this.$store.state.appsociete
                          } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                      })

                      await response
                          .then(function (res){


                              if(res.data.success){
                                  t.$store.dispatch('beartoken',res.data.beartoken)
                                  t.Items.pid=res.data.mkey
                                  t.Items.codebarre=res.data.kref


                                  if (t.Items.perisable==="false" || t.Items.perisable===false ){t.Items.perisable="Non";}else{t.Items.perisable="Oui";}
                                  if (t.Items.datajsonarticles.normalisation.taxedimposition==="A"){t.Items.taxedimposition="Exonéré";}
                                  if (t.Items.datajsonarticles.normalisation.taxedimposition==="B"){t.Items.taxedimposition="Taxable (18%)";}
                                  if (t.Items.datajsonarticles.normalisation.taxedimposition==="E"){t.Items.taxedimposition="Régime fiscal TPS";}
                                  if (t.Items.datajsonarticles.normalisation.taxedimposition==="D"){t.Items.taxedimposition="TVA régime d'exception";}
                                  if (t.Items.datajsonarticles.normalisation.taxedimposition==="C"){t.Items.taxedimposition="Exportation de produits taxable";}


                                  t.mdataclose.push(t.Items)

                                  //********************************************************************************************************************//
                                  //********************************************************************************************************************//

                                  t.Items=  {
                                      pid:'',libelle:'',reference:'',suividestock:0,
                                      articletype:0,
                                      nbreusearticle:0,
                                      usear:0,
                                      stockminimum:0
                                      ,lignetitre:''
                                      ,lignedescription:''
                                      ,lignedescriptiontotal:''
                                      ,lignephotodegarde:''
                                      ,lignesommeil:''
                                      ,lignein_stock:''
                                      ,lignecategorie:''
                                      ,lignesous_categorie:''
                                      ,lignemontant:''
                                      ,ligneremise:''
                                      ,lignenbredevue:''
                                      ,ligneboutique:''
                                      ,ligneboutiquepid:'',

                                      datajsonarticles:{
                                          typearticle:"Article",
                                          perisable:false,
                                          ensommeil:false,
                                          tptva:1,
                                          stockminimum:0,
                                          stockmaximum:0,
                                          magasin:null,
                                          unite:null,
                                          catalogue:null,
                                          comptetva:{compteventeexport:null,comptefacturation:null,
                                              compteventelocal:null},
                                          normalisation:{taxedimposition:"E",tauxtaxedimposition:null,
                                              tauxtaxespecifique:null,descriptiondutsapplique:null},
                                          tarification:{prixvente1:0,prixvente2:0,prixvente3:0,prixvente4:0,prixvente5:0,
                                              prixvente6:0,prixvente7:0,
                                              prixachat:0,prixvente:0,typeprixvente:1,prixpromo:0, typttc:1.18,},
                                          information:{info:'',info1:'',info2:'',info3:''},
                                          paramdindication:{param:null,param1:null,
                                              param2:[],
                                              param3:{
                                                  suiviedemballage:'Non',
                                                  typeoperation:'default',
                                                  emballage:null
                                              }},
                                      },
                                      articlesenligne:{
                                          titreenligne:'...',
                                          montantenligne:0,
                                          montantremiseenligne:0,
                                          minidescriptionenligne:'...',
                                          totaldescriptionenligne:'...',
                                          categorieenligne:null,
                                          souscategorieenligne:null,
                                          lesphotosenligne:[],
                                          articledisponible:true,
                                          miseenligne:false,
                                      },
                                      articlesenrestaurant:{},
                                      articlesclinique:{},

                                  };
                                  t.createdatapv=[];
                                  t.datapv=[];
                                  t.mypv={};
                                  t.jsonarticles=[];
                                  t.newsjsonarticles=[];
                                  t.dataaccompagnement=[];
                                  //t.alldata=res.data.otherdata;
                                  toastr['success']( res.data.message,"<b></b>");
                                  t.$store.dispatch('lancerchargement',false)


                              }
                              else{


                                  let errormessage="";

                                  t.messageerror="";
                                  t.messagesucces="";
                                  if (res.data.datamessage.length>0){

                                      for (let message of res.data.datamessage){

                                          if (errormessage===""){

                                              errormessage=message;

                                          }else{

                                              errormessage+=" , "+message;

                                          }


                                      }


                                      t.messageerror=errormessage;

                                  }
                                  else{
                                      t.messageerror=res.data.message
                                  }


                                  toastr['error'](t.messageerror,"<b></b>");
                                  t.$store.dispatch('lancerchargement',false)




                              }


                          })
                          .catch(

                              error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                  "<b></b>")
                          )
                          .finally(() =>  this.$store.dispatch('lancerchargement',false))






                  }


              },


              async  updateItems(){
                  this.$store.dispatch('lancerchargement',true)


                  if ( this.Items.datajsonarticles.magasin===null
                      || this.Items.unite===null ||   this.Items.libelle===""){


                      this.$store.dispatch('lancerchargement',false)
                      toastr['error']( "Veuillez saisir toutes les informations (l'entrepôt, le nom de l'article, l'unite de comptage)","<b></b>");

                      return
                  }


                  this.Items.jsonarticles=this.jsonarticles
                  this.Items.newsjsonarticles=this.newsjsonarticles

                  this.Items.articlesenrestaurant={
                      accompagnement :this.dataaccompagnement
                  };



                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/egl745874pstock771714dfe/ar/maj/'+this.$store.state.appsociete+'/'+randomString();
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept':'application/json',
                          'Content-Type':'application/json',
                          'Access-Control-Allow-Origin': '*',
                          'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                          'Access-Control-Allow-Headers': 'Content-Type',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");


                                t.close()

                            }
                            else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },




            onChange (event){

                let typeprixvente=  parseInt(event.target.value)+1

                if (typeprixvente>1){
                    // this.magasinItem.datajsonarticles.tarification.typttc=1.18;
                }
                else{

                    //   this.magasinItem.datajsonarticles.tarification.typttc=1;

                }

            },



            retirerselectedmagasin: function(index,mgp,bd,pid){

                if (bd===1 ){

                    toastr['error']( "Impossible de retirer cet entrpôt.","<b></b>");

                    return
                }


                if (bd===1 && mgp===1){

                    toastr['error']( "Impossible de retirer cet entrpôt.","<b></b>");
                    return
                }

                if (bd===0 && mgp===1){

                    this.addpumagasinprincipal=0
                }

                let t=this
                this.jsonarticles.splice(index,1)

                this.newsjsonarticles.forEach(function (post,i) {

                    if (post.mg.pid===pid){

                        t.newsjsonarticles.splice(i,1)
                    }

                })


            },



            retirerselectedpv: function(index,value){

               value.splice(index,1)



            },




        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .text-capitalize{
        text-transform: uppercase;
    }

    :disabled {
        background-color: rgb(var(--light));
        color: rgb(var(--default-text-color));
    }

    .space-y-2{
        margin-top: 10px;
    }


    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>