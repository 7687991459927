import moment from 'moment';
import { NumberToLetter } from 'convertir-nombre-lettre';
import * as xlsx from 'xlsx/xlsx.mjs';
import qrcode from 'qrcode.vue'



export const generateQR = async (text) => {
    // eslint-disable-next-line no-useless-catch
    try {

        return await qrcode.toDataURL(text);
    } catch (err) {
        throw err;
    }
};


export const ExportExcel=(type,name, fn, dl,elt) =>{

    //  let name="SheetJSTableExport"
    //var elt = this.$refs.exportable_table;
    var wb = xlsx.utils.table_to_book(elt, {sheet:"Sheet JS"});
    return dl ?
        xlsx.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
        xlsx.writeFile(wb, fn || ((name + '.') + (type || 'xlsx')));



};


export const ExportExcelMULTISheet=(type,name, fn, dl,elt) =>{

    //  let name="SheetJSTableExport"
    //var elt = this.$refs.exportable_table;
    // var wb
    // var wb = {SheetNames:[], Sheets:{}};
    // var wb = {SheetNames:[], Sheets:{}};


    // var ws = xlsx.utils.table_to_sheet(elt);
    // var ws2 = xlsx.utils.table_to_sheet(elt);
    var wb = xlsx.utils.book_new();

    // xlsx.utils.book_append_sheet(wb, ws, "Sheet 1");
    // xlsx.utils.book_append_sheet(wb, ws2, "Sheet 2");

    for (let i = 0; i < elt.length; i++) {
        xlsx.utils.book_append_sheet(wb, xlsx.utils.table_to_sheet(elt[i].melt), elt[i].name);

    }

    return dl ?
        xlsx.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
        xlsx.writeFile(wb, fn || ((name + '.') + (type || 'xlsx')));




};


export const randomString = (length=280) => {


    var chars = "0123456789abcdefghijklmnopqrstuvwxyz0123456789ABCDEFG755lkjjhjHIJKLMNOPQRSTUVWXYZ1234567890";

    var output = "";
    var x = 0;
    var i = 0;
    for(x = 0; x < length; x++) {
        i = Math.floor(Math.random() * 62);
        output += chars.charAt(i);
    }



    return output;
};


export const currentDate = () => {
    let now = moment();
    let mdate=now.format("YYYY-MM-DD");
    //let mheure=now.format("HH:mm:ss");

    // console.log(mdate_heure);
    return mdate
};

export const currencyFormatDE = num => {
    var number=Number(num);
    return (
        number
            //.toFixed(2) // always two decimal digits
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1'+' ') + ' FCFA'
    ) // use . as a separator
};

export const FormatNumber = num => {
    var number=Number(num);
    return (
        number
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
    ) // use . as a separator
};




 export  const getYear1955 = () => {
   // let now = moment();
    var ladate=new Date()
    let arraydate=[];
    let str = 0;
    const startappYear = 1955;
    const currentYear = ladate.getFullYear();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};

 export  const addBusinessDays=(originalDate, numDaysToAdd,isokSunday,isokSaturday) =>{
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;
    let clonedate=new Date(originalDate)

    //const myDate = moment(originalDate, 'yyyy-mm-dd').toDate();
    //const myDate = moment(clonedate).format('YYYY-MM-DD');
    const myDate = moment(clonedate);
    // const newDate = originalDate.clone();
    //const newDate = myDate;
    //const newDate = new Date(originalDate);

    while (daysRemaining > 0) {
        //newDate.add(1, 'days');

        myDate.add(1, 'days');

        if (myDate.day() === Sunday || myDate.day() === Saturday) {

            if (isokSunday===1){

                daysRemaining--;

            }

            if (isokSaturday===1){

                daysRemaining--;

            }

        }
        else{
            daysRemaining--;

        }




    }

    //return newDate;
    return myDate.format('YYYY-MM-DD');
};

  export const currentDateTime = () => {
    let now = moment();
    let mdate=now.format("YYYY-MM-DD");
    let mheure=now.format("HH:mm:ss");
    let mdate_heure=mdate+' à '+mheure;
    // console.log(mdate_heure);
    return mdate_heure
};


  export const formatDate_yyyy_mm_dd = (mdate) => {
    return moment(mdate).format('YYYY-MM-DD');
};

  export const formattime_hh_mm_ss = (mtime) => {
    return moment(mtime,'HHmmss').format("HH:mm:ss");
};

  export const add_day_to_date = (mdate,nbreday) => {
    return moment(mdate).add(parseInt(nbreday), 'days').format("YYYY-MM-DD");
};

 export const checkDateValidity = (mdate)  =>{

    return moment(mdate, "YYYY-MM-DD", true).isValid();

}

 export const checkTimeValidity = (mtime)  =>{

    return  moment(mtime, "HH:mm:ss", true).isValid();

}

 export  const returndifftotwodate = (mdatetimearrive,mdatetimesortie) => {

    const dateC = moment(mdatetimearrive);
    const dateB = moment(mdatetimesortie);


    let res=dateB.diff(dateC);


    return res;
};


  export const getYear2020 = () => {
    let now = moment();
    let arraydate=[];
    let str = 0;
    const startappYear = 2020;
    const currentYear = now.year();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};


 export  const currentYear = () => {
    let now = moment();
    let mdate=now.format("YYYY");
    /// let mheure=now.format("HH:mm:ss");

    // console.log(mdate_heure);
    return mdate
};


 export  const currentMonth = () => {
    let now = moment();
    let mdate=now.format("MM");
    /// let mheure=now.format("HH:mm:ss");

    // console.log(mdate_heure);
    return mdate
};

 export const returnMonthbynumber=val =>{
    var answer = "";




// Only change code below this line
    switch (val) {

        case "01": answer=("Janvier");
            break;
        case "02": answer=("Février");
            break;
        case "03": answer=("Mars");
            break;
        case "04": answer=("Avril");
            break;
        case "05": answer=("Mai");
            break;
        case "06": answer=("Juin");
            break;
        case "07": answer=("Juillet");
            break;
        case "08": answer=("Août");
            break;
        case "09": answer=("Septembre");
            break;
        case "10": answer=("Octobre");
            break;
        case "11": answer=("Novembre");
            break;
        case "12": answer=("Décembre");
            break;}

// Only change code above this line
    return answer;
}


export const cloneobjet = myobjet => {

    return JSON.parse(JSON.stringify(myobjet));
}

export const isNumeric = num => {
    return !isNaN(parseFloat(num)) && isFinite(num);
};

export const array_search = ($search, $array,property) => {


    if (!$array || !Array.isArray($array)) {
        return -1
        // throw new Error('Invalid input: listecaisseacees.accescaisse should be an array');
    }

    return $array.findIndex(element => element[property] === $search);
};


export const getYear = () => {
    let now = moment();
    let arraydate=[];
    let str = 0;
    const startappYear = 2020;
    const currentYear = now.year();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};

export const getnombredejours = (datelicence,datenow) => {

    // let now = moment();
    //let mdate=now.format("YYYY-MM-DD");
    let date2 = moment(datelicence);
    //var current = moment().startOf('day');
    var current = moment(datenow).startOf('day');

//Difference in number of days
    let nbredejour=   moment.duration(date2.diff(current)).asDays();

    return nbredejour;
}


export const impression = (printContent,$styles,$windowsname='Print') => {


    return new Promise((resolve) => {
    //const printWindow = window.open('', '', 'height=500,width=800');


    const width = 800;
    const height = 500;
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height / 2) - (height / 2);
    const printWindow = window.open('', '', `width=${width},height=${height},top=${top},left=${left}`);





    printWindow.document.write(`<html><head><title>${$windowsname}</title>`);

    // Inclure les styles nécessaires
    //printWindow.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">');


    for (let $line of $styles){

        printWindow.document.write(`<link rel="stylesheet" href="${$line}">`);

    }
    // printWindow.document.write(`<link rel="stylesheet" href="${process.env.VUE_APP_URL+'print/bootstrap.min.css'}">`);
   // printWindow.document.write(`<link rel="stylesheet" href="${process.env.VUE_APP_URL+'print/bootstrap.min.css'}">`);
   // printWindow.document.write(`<link rel="stylesheet" href="${process.env.VUE_APP_URL+'print/bootstrap.min.css'}">`);

    printWindow.document.write('</head><body >');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    // Ajouter un écouteur d'événement pour fermer la fenêtre après l'impression
    printWindow.onafterprint = function () {
        // Action à effectuer après l'impression
        // Par exemple, retourner un message de succès
        resolve(true);

        // Fermer la fenêtre d'impression si nécessaire
      //  printWindow.close();
    };

    printWindow.print();
    });

}

export const isset = (accessor) => {
    try {
        // Note we're seeing if the returned value of our function is not
        // undefined or null
        return accessor !== undefined && accessor !== null
    } catch (e) {
        // And we're able to catch the Error it would normally throw for
        // referencing a property of undefined
        return false
    }


}

export const isValidDateString = (datestring) => {

    const checkdate=moment(datestring,"YYYY-MM-DD",true);

    return checkdate.isValid();


}


export const groupByKey=(array, key)=> {
    return array
        .reduce((hash, obj) => {
            if(obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
        }, {})
};


export const currencyFormatDEnotdevice = num => {
    var number=Number(num);
    return (
        number
            //.toFixed(2) // always two decimal digits
            /*.toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')*/

            .toFixed(4) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1'+'')


    ) // use . as a separator
}


export const currencyFormatMonney = num => {
    var number=Number(num);
    return (
        number
            //.toFixed(2) // always two decimal digits
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1'+' ') + ''
    ) // use . as a separator
}

export const NumbertoLettre = chiffre => {
    var number=Number(chiffre);
    return NumberToLetter(number)
}




export const moisEnLettre = mois => {
    switch (mois) {
        case 1:
            return "Janvier";
        case 2:
            return "Février";
        case 3:
            return "Mars";
        case 4:
            return "Avril";
        case 5:
            return "Mai";
        case 6:
            return "Juin";
        case 7:
            return "Juillet";
        case 8:
            return "Août";
        case 9:
            return "Septembre";
        case 10:
            return "Octobre";
        case 11:
            return "Novembre";
        case 12:
            return "Décembre";
        default:
            return "Mois invalide";
    }
}




export const  triecroissant  = (mvalue,key) => {
    // Sort by alphabetical order

    let orderedStories = mvalue.sort((a, b) => {
        // if (this.sortBy == 'alphabetically') {


        /* let fa = a[key].toLowerCase(),
             fb = b[key].toLowerCase()*/

        let fa = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key],
            fb = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
        // }

    })

    return orderedStories;

}

export const  triedecroissant  = (mvalue,key) => {
    // Sort by alphabetical order

    let orderedStories = mvalue.sort((a, b) => {

        let fa = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key],
            fb = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];


        if (fa > fb) {
            return -1
        }
        if (fa < fb) {
            return 1
        }
        return 0


    })

    return orderedStories;

}

export const   getPeriod = (param) => {
    // Date de fin : aujourd'hui
    let dateFin = moment();

    // Variable pour la date de début
    let dateDebut;

    // Vérifier le paramètre et calculer la date de début
    const unit = param.slice(-1);  // Récupère l'unité (d, w, M)
    const amount = parseInt(param.slice(0, -1));  // Récupère le nombre

    /*
    switch (unit) {
        case 'd':
           // dateDebut = moment();
           // dateDebut = moment().subtract(amount, 'days');

            dateDebut = moment().startOf('day');
            dateFin = moment().endOf('day');

            break;
        case 'W':
           // dateDebut = moment().subtract(amount, 'weeks');

            dateDebut = moment().subtract(amount, 'weeks').startOf('isoWeek');
            dateFin = moment().subtract(amount, 'weeks').endOf('isoWeek');

            break;
        case 'M':
            //dateDebut = moment().subtract(amount, 'months');

            dateDebut = moment().subtract(amount, 'months').startOf('month');
            dateFin = moment().subtract(amount, 'months').endOf('month');

            break;
        case 'Y':
           // dateDebut = moment().subtract(amount, 'years');

            dateDebut = moment().subtract(amount, 'years').startOf('year');
            dateFin = moment().subtract(amount, 'years').endOf('year');

            break;
        default:
            //dateDebut = moment();
            dateDebut = moment().startOf('day');
            dateFin = moment().endOf('day');

           // throw new Error('Paramètre invalide. Utilisez "d" pour jour, "w" pour semaine, ou "M" pour mois.');
    }*/

    switch (unit) {
        case 'd':
            dateDebut = moment().startOf('day');
            dateFin = moment().endOf('day');
            break;
        case 'W':
            if (amount === 1) {
                dateDebut = moment().startOf('isoWeek');
                dateFin = moment().endOf('isoWeek');
            } else {
                dateDebut = moment().subtract(amount - 1, 'weeks').startOf('isoWeek');
                dateFin = moment().endOf('isoWeek');
            }
            break;
        case 'M':
            if (amount === 1) {
                dateDebut = moment().startOf('month');
                dateFin = moment().endOf('month');
            } else {
                dateDebut = moment().subtract(amount - 1, 'months').startOf('month');
                dateFin = moment().endOf('month');
            }
            break;
        case 'Y':
            if (amount === 1) {
                dateDebut = moment().startOf('year');
                dateFin = moment().endOf('year');
            } else {
                dateDebut = moment().subtract(amount - 1, 'years').startOf('year');
                dateFin = moment().endOf('year');
            }
            break;
        default:
            dateDebut = moment().startOf('day');
            dateFin = moment().endOf('day');
        // throw new Error('Paramètre invalide. Utilisez "d" pour jour, "W" pour semaine, "M" pour mois, ou "Y" pour année.');
    }

    return {
        dateDebut: dateDebut.format('YYYY-MM-DD'),
        dateFin: dateFin.format('YYYY-MM-DD')
    };
}







/*
module.exports = {

    randomString,
    currentDate
    returnMonthbynumber,
     addBusinessDays,
    currentDateTime,
    formatDate_yyyy_mm_dd,
   formattime_hh_mm_ss,
    add_day_to_date,
    checkDateValidity,
    checkTimeValidity,
    returndifftotwodate,
    getYear2020,
    getYear1955,
    currentYear,


}
*/
