<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>


            <appmenu></appmenu>


            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div >

                        <div class="xl:col-span-12 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        <h2><strong>Exercice: 2023-2024</strong></h2>

                                    </div>
                                    <div class="prism-toggle">
                                        <!--h2><strong>{{$store.state.dateouverturecaisse}}</strong></h2-->
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div style="background-color: #f2f4f5" class="grid grid-cols-12 mt-7 gap-6">

                                        <div style="    cursor: pointer;" class="xxl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
                                            <div class="box custom-box text-white">
                                                <div class="box-header justify-between">

                                                    <div class="box-title text-uppercase">
                                                        Liste des écritures en attente de validation
                                                    </div>


                                                </div>
                                                <div class="box-body">


                                                    <div style="width: 100%;margin-bottom: 10px" class="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                                                        <input v-model="searchwordallprod" class="form-control" placeholder="Search Table" id="searchDatatable2">
                                                    </div>

                                                    <div class="box-body !p-0 mybodyheight300px tableFixHeadindexcomponnent tableFixHeadindexcomponnent300">
                                                        <div class="table-responsive">

                                                            <!-- component -->
                                                            <div class="flex flex-col h-screen">
                                                                <div class="flex-grow overflow-auto">


                                                                    <table style="height: 200px;overflow-y: scroll;" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Libellé</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Journal</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Saisie par</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Date opération</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="filterdatatable.length">

                                                                            <tr style="font-weight: 600;/*background: khaki;*/"
                                                                                v-for="(datavalue,index) in filterdatatable" :key="index">

                                                                                <!--td  data-label="Magasin  : "   >{{ datavalue.nommaga }}</td-->
                                                                                <td  data-label="Article  : " >{{ datavalue.libelle }}</td>
                                                                                <td style="text-align: center"  data-label="Stock  : " >{{ datavalue.rstck }}</td>
                                                                                <td style="text-align: center"  data-label="Prix de vente  : " >{{ formatNumber(datavalue.pu) }}</td>
                                                                            </tr>




                                                                        </template>

                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="9"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>

                                                                        </template>




                                                                        </tbody>


                                                                    </table>







                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>







                                    </div>




                                </div>
                                <div class="box-footer border-t-0 hidden">

                                </div>
                            </div>
                        </div>













                </div>
            </div>
        </template>

    </templatesite>


</template>

<script>



    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/comptabilite/menu";
    import * as JwtService from "@/common/jwt.service";
    import {currencyFormatDE, randomString} from "@/allfunction";
    const axios =require('axios');
    
    


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,



        },
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },

        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },




            filterdatatable(){
                let mvalue=[];

               /* if (this.searchwordallprod!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return    post.libelle.toLowerCase().includes(t.searchwordallprod.toLowerCase())
                    })
                }*/

                return mvalue ;
            },


/*
            accesoperationbancaire(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="operationbancaire"){


                        mvalue=(macces.acces !== 0);
                    }

                });



                return mvalue
            },

            accescreationbcdinternecaisse(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="creationbcdinternecaisse"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesenregistrerbordereauxdebanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="enregistrerbordereauxdebanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesreglementclt(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="reglementclt"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesreglementfrs(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="reglementfrs"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesreglementbcd(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="reglementbcd"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },


            accesdepotencompteclt(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="depotencompteclt"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesretourdefondsdecaisse(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="retourdefondsdecaisse"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },


            accestransfertdefondscaisse(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="transfertdefondscaisse"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },


            acceslistedepotsenbanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="listedepotsenbanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            acceslistedesapprovisionnementscaissebanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="listedesapprovisionnementscaissebanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesannulerfacture(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="annulerfacture"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },



            accesfacturation(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="facturationcaisse"){


                        mvalue=(macces.acces !== 0);
                    }



                });



                return mvalue
            },

            accessoldecaissebanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="voirsoldebanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });

                return mvalue
            },

            accessavancefournisseur(){
                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="avancefournisseur"){


                        mvalue=(macces.acces !== 0);
                    }

                });

                return mvalue
            },


            accessavanceclient(){
                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="avanceclient"){


                        mvalue=(macces.acces !== 0);
                    }

                });

                return mvalue
            },


            accesdepense(){

                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="depensecaisse"){


                        mvalue=(macces.acces !== 0);
                    }



                });


                return mvalue
            },

            accestableauxdestock(){

                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="tableauxdestock"){


                        mvalue=(macces.acces !== 0);
                    }



                });


                return mvalue
            },


            accesmycaisse(){

                if (Object.keys(this.$store.state.caisseinfo).length>0)
                {
                    return true
                }else{
                    return false
                }
            }
     */






        },

        data() {
            return {
                searchword:"",
                searchwordallprod:"",
                datatablefindecontratpermannent:[],
                datatablearticle:[],
                datatabledocday:[],
                listedescaisses:[],
                datatablestockminimum:[],
                dateouverture:null,
                intitule:"",
                totaldepense:0,
                totalrecette:0,
                montantencaisse:0,



                allgraphs: [],



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],

            };
        },
        methods: {



            formatNumber(num) {

                return  currencyFormatDE(num)
            },




            async inittableauarticle() {

                // accesfacturation


               // let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                let $caisseencour=(Object.keys(this.$store.state.caisseinfo).length>0 && this.accestableauxdestock)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/otherallproddksdk/'+$caisseencour+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                 await response
                    .then(response => {

                        if (response.data.success){
                            t.datatablearticle=[]
                            t.datatablearticle=response.data.m

                        }
                        else{
                            t.datatablearticle=[]
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },


            closemodalfacturation(value){


                this.datatablearticle=value
                this.loadsoldecaisse();

            },

            closemodal(){
                this.loadsoldecaisse();
                this.loadsuiviecaissegraph();
                this.loadtop10article();
            },




            async loadsoldecaisse() {


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }

             const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/caisse/fkdfkdozojdjsolde/'+this.$store.state.paramsystem.apikey+'/'+$caisseencour+'/'+this.$store.state.appsociete+'/895418init519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.$store.dispatch('totaldepense',response.data.m.totaldepense);
                            t.$store.dispatch('totalrecette',response.data.m.totalrecette);
                            t.$store.dispatch('montantencaisse',response.data.m.solde);
                        }
                        else{

                            t.$store.dispatch('totaldepense',0);
                            t.$store.dispatch('totalrecette',0);
                            t.$store.dispatch('montantencaisse',0);
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally()



            },


            async loadsuiviecaissegraph() {


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }

             const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/graph/gg/'+$caisseencour+'/'+this.$store.state.appsociete+'/8954graph18ss519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.series4=response.data.xaxis;
                            t.chartOptions4.xaxis.categories=response.data.allgraphs
                            t.chartOptions4.colors=response.data.colors

                        }
                        else{

                            t.series4=[];
                            t.chartOptions4.xaxis.categories=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally()



            },

            async loadtop10article() {


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }


             const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/graph/gg/'+$caisseencour+'/'+this.$store.state.appsociete+'/8954toparticless519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {


                        if (response.data.success){

                            t.allgraphs=response.data.allgraphs

                        }
                        else{


                            t.allgraphs=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally()



            },

            init(){
               // this.inittableauarticle();
                this.loadsoldecaisse();
                this.loadsuiviecaissegraph();
                this.loadtop10article();

            },



            async initcaisse(){

                this.$store.dispatch('lancerchargement',true)
                const t = this;

                t.dateouverture=null;
                t.intitule="";
                t.totaldepense=0;
                t.totalrecette=0;
                t.montantencaisse=0;
                var caisse={
                    'dateouverture':this.dateouverture,
                    'cc':this.$store.state.caisseencour,
                }



                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/caisse/fkdfkdozojdj/'+this.$store.state.appsociete+'/895418init519151911515/'+randomString();
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })
                 await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            t.$store.dispatch('dateouverturecaisse',res.data.m.dateouverture);
                            t.$store.dispatch('ouverturedelacaisse',1);
                            t.$store.dispatch('caisseinfo',res.data.m.caisseinfo);

                            t.$store.dispatch('niveauaccescaisse',res.data.m.vue.niveaudacces);
                            t.$store.dispatch('intitulecaisse',res.data.m.vue.intitule);
                            /*t.$store.dispatch('totaldepense',res.data.caisse.vue.totaldepense);
                            t.$store.dispatch('totalrecette',res.data.caisse.vue.totalrecette);
                            t.$store.dispatch('montantencaisse',res.data.caisse.vue.solde);*/
                            t.$store.dispatch('typc',res.data.m.vue.typc);

                            t.listedescaisses=res.data.m.listedescaisses

                            //t.allbk=res.data.caisse.allbk


                            /*
                               t.$refs.refmenucaisse.updatevariable(t.allbk,t.accessoldecaissebanque);

                            t.dateouverture=res.data.caisse.dateouverture;
                               t.intitule=res.data.caisse.vue.intitule;
                               t.totaldepense=res.data.caisse.vue.totaldepense;
                               t.totalrecette=res.data.caisse.vue.totalrecette;
                               t.montantencaisse=res.data.caisse.vue.solde;
                               t.typc=res.data.caisse.vue.typc;*/

                            t.inittableauarticle()

                        }else{

                            //t.$store.dispatch('beartoken',res.data.beartoken)
                            toastr['error']( res.data.message,"<b></b>");
                           // t.loading=false
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() =>   t.$store.dispatch('lancerchargement',false))




            },



            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {


            /*
          if(this.$store.state.caisseencour===''){

                toastr['error']( "Veuillez sélectionner une caisse valide.")


                this.$router.push({ name: 'tcaisse' });
            }
            else{
                this.initcaisse();

                this.init()
            }

            */








        }






    }
</script>

<style scoped>

    .custom-box{
        padding: 10px;
    }

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>