<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">
                    <div class="md:flex block items-center justify-between my-[1.5rem] page-header-breadcrumb">
                        <div>


                        </div>
                        <div class="btn-list md:mt-0 mt-2">
                            <button @click="init" type="button" class="ti-btn bg-primary text-white btn-wave !font-medium !me-[0.375rem] !ms-0 !text-[0.85rem] !rounded-[0.35rem] !py-[0.51rem] !px-[0.86rem] shadow-none">
                                <i class="ri-refresh-fill  inline-block"></i>Actualiser
                            </button>
                        </div>
                    </div>


                    <div class="grid grid-cols-12 gap-6">



                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        Liste des articles qui ont atteint leur stocks minimum
                                    </div>

                                </div>
                                <div class="box-body ">
                                    <div class="table-responsive mybodyheight300px">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                            <table class="table whitespace-nowrap min-w-full">
                                            <thead>
                                             <tr class="border-b border-defaultborder">
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MAGASIN/DEPÔT
                                                <br> ENTREPÔT</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ARTICLE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">STOCK</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template  v-if="loadingtablestockminimum">

                                            <template v-if="filterdatatablestockminimum.length">

                                                <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatablestockminimum" :key="index">



                                                    <td  data-label="MAGASIN  : "   >{{ datavalue.nommaga }}</td>
                                                    <td  data-label="ARTICLE  : "   >{{ datavalue.libelle }}</td>
                                                    <td  data-label="STOCK  : "   >{{ datavalue.stock }}</td>

                                                </tr>



                                            </template>

                                            <template v-else>
                                                <tr >
                                                    <td colspan="10"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>
                                            </template>
                                            <template v-else>

                                                <tr >
                                                    <td colspan="20">
                                                        <div class="animate-pulse">
                                                            <div class="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-300 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-200 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-300 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-200 mb-6 rounded"></div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>



                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0">

                                    <div class="flex flex-col box-footer border-t-0 ">
                                        <div class="flex items-center flex-wrap overflow-auto">
                                            <div class="mb-2 sm:mb-0">

                                            </div>
                                            <div class="ms-auto">


                                                <nav aria-label="Page navigation">
                                                    <ul class="ti-pagination mb-0">
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Prev</button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                    :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                                {{ pageNumber }}
                                                            </button>
                                                        </li>
                                                        <li class="page-item">
                                                            <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>





                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        Liste des documents de stock du {{$store.state.appserverdate}}
                                    </div>

                                </div>
                                <div class="box-body">
                                    <div class="table-responsive mybodyheight300px">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                            <table class="table whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr class="border-b border-defaultborder">

                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°DOC</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">TYPE DOC</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MAGASIN/DEPÔT
                                                    <br> ENTREPÔT</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SAISIE PAR</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template  v-if="loadingtablestockdocstock">

                                            <template v-if="datatabledocday.length">

                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datatabledocday" :key="index">

                                                    <td  data-label="ACTION  : "   >

                                                        <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                                class=" ti-btn ti-btn-primary ">
                                                            <i class="ri-folder-open-fill"></i> Ouvrir
                                                        </button>
                                                    </td>
                                                    <td  data-label="N° DOC  : "   >{{ datavalue.numdoc }}</td>
                                                    <td  data-label="TYPE DOC  : "   >{{ datavalue.magname }}</td>
                                                    <td  data-label="MAGASIN/DEPÔT/ENTREPÔT  : "   >{{ datavalue.magname }}</td>
                                                    <td  data-label="SAISIE PAR  : "   >{{ datavalue.saisiepar }}</td>



                                                </tr>



                                            </template>
                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>

                                            </template>
                                            <template v-else>

                                                <tr >
                                                    <td colspan="20">
                                                        <div class="animate-pulse">
                                                            <div class="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-300 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-200 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-300 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-200 mb-6 rounded"></div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>



                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0 hidden">

                                </div>
                            </div>
                        </div>


                    </div>






                </div>
            </div>
        </template>

    </templatesite>


    <modalopenform ref="modalopenform"  ></modalopenform>

</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/stock/menu";


    import * as JwtService from "@/common/jwt.service";
    import {randomString} from "@/allfunction";
    import modalopenform from "@/components/views/pages/stock/mvtstock/modal_openform";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalopenform,

        },
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },

        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatablestockminimum(){

                let mvalue=this.datatablestockminimum;

                return this.paginate(mvalue) ;

            },
        },

        data() {
            return {
                searchword:"",
                datatablefindecontratpermannent:[],
                datatabledocday:[],
                datatablestockminimum:[],

                loadingtablestockminimum:false,
                loadingtablestockdocstock:false,




                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],

            };
        },
        methods: {

            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },

            async inittableaustock() {

                const t = this;
                t.loadingtablestockminimum=false;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874ar771714dfe/stock/tb/alerte/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                 await response
                    .then(response => {

                        if (response.data.success){

                            t.datatablestockminimum=response.data.m
                            t.loadingtablestockminimum=true;


                        }
                        else{
                            t.loadingtablestockminimum=true;
                            //if (response.data.message!==""){toastr['error']( response.data.message,"<b></b>");}
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(function () {

                    })



            },

            /*

            async inittableauexpressiondebesoin() {
                const  tokenid=JwtService.getTokenID();
                const  expirein=JwtService.getTokenEXPIRESIN();
                let url=this.$store.state.baseurlapi+"arexp4pfii41258845/"+this.$store.state.beartoken
                let t=this;
                // this.$store.dispatch('lancerchargement',true)
                let response=await  axios
                    .get(url, {
                        headers: {
                            'xccsrftoken':tokenid,
                            'incsrftoken':expirein,
                            'btkcsrftoken':t.$store.state.appsociete,
                        }
                    })
                    .then(response => {

                        if (response.data.success){

                            t.listedesbesoins=response.data.datas
                            t.loaddatatable2=true;

                        }else{
                            t.loaddatatable2=true;
                            if (response.data.message!==""){toastr['error']( response.data.message,"<b></b>");}
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(function () {

                    })



            },

            */

            async inittableauelistedocday() {


                const t = this;
                t.loadingtablestockdocstock=false;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/stock/day/'+this.$store.state.appserverdate+'/'+this.$store.state.appserverdate+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.datatabledocday=response.data.m;
                            t.loadingtablestockdocstock=true;

                        }else{
                            t.loadingtablestockdocstock=true;
                           // if (response.data.message!==""){toastr['error']( response.data.message,"<b></b>");}
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(function () {

                    })



            },

            init(){
                this.inittableaustock();
                this.inittableauelistedocday();

            },








            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

           this.inittableaustock();
           this.inittableauelistedocday();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>