<template>


    <nav class="menutop bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">&nbsp;&nbsp;</span>
            </a>
            <button @click="toggleNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" :aria-expanded="isNavbarOpen.toString()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div :class="{ 'hidden': !isNavbarOpen, 'block': isNavbarOpen }" class="w-full md:block md:w-auto" id="navbar-dropdown">
                <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">




                    <li  >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block
                            py-2 px-3 text-gray-900 rounded hover:bg-gray-100
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700
                            md:p-0 dark:text-white md:dark:hover:text-blue-500
                            dark:hover:bg-gray-700 dark:hover:text-white
                            md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Fichier<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        -
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        -
                                    </a>
                                </li>







                            </ul>
                        </div>

                    </li>

                    <li  >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block
                            py-2 px-3 text-gray-900 rounded hover:bg-gray-100
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700
                            md:p-0 dark:text-white md:dark:hover:text-blue-500
                            dark:hover:bg-gray-700 dark:hover:text-white
                            md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                   Définitions <i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        Compte comptable
                                    </a>
                                </li>

                                <div class="dropdown-divider"></div>

                                <li>
                                    <a @click="btnmodaldefinitioncltfrs('clt')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Client
                                    </a>
                                </li>

                                <li>
                                    <a  @click="btnmodaldefinitioncltfrs('frs')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Fournisseur
                                    </a>
                                </li>

                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        Caisse
                                    </a>
                                </li>


                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        Paramétre d'indication de la caisse
                                    </a>
                                </li>



                                <div class="dropdown-divider"></div>

                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        Centre de responsabilité
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Nature des immobilisations
                                    </a>
                                </li>


                            </ul>
                        </div>

                    </li>

                    <li  >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block
                            py-2 px-3 text-gray-900 rounded hover:bg-gray-100
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700
                            md:p-0 dark:text-white md:dark:hover:text-blue-500
                            dark:hover:bg-gray-700 dark:hover:text-white
                            md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Traitements<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a @click="btnmodalindexsaisiejournaux" class="ti-dropdown-item" href="javascript:void(0);">
                                        Saisie dans les journaux
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Validation des écritures saisies
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Lettrage
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Pointage
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Report à nouveau
                                    </a>
                                </li>


                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Clôture des reports à nouveau
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Vérification des équilibres fondamentaux
                                    </a>
                                </li>


                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Clôture périodique
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Déduction et Réintégration fiscales
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Charges supplétives avant résultats intermédiaire
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Calcul du résultat et génération des états financiers
                                    </a>
                                </li>


                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Arrété définitif des comptes de l'exercice
                                    </a>
                                </li>






                            </ul>
                        </div>

                    </li>



                    <li  >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block
                            py-2 px-3 text-gray-900 rounded hover:bg-gray-100
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700
                            md:p-0 dark:text-white md:dark:hover:text-blue-500
                            dark:hover:bg-gray-700 dark:hover:text-white
                            md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Edition<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li  >
                                   <div class="hs-dropdown ti-dropdown">
                                       <a class="ti-dropdown-item" href="javascript:void(0);">
                                           Journaux <i  class="ri-arrow-right-s-line align-middle ms-1 inline-block"></i>
                                       </a>
                                       <ul class="submenu hs-dropdown-menu ti-dropdown-menu hidden"
                                           aria-labelledby="dropdownMenuButton1">

                                           <li><a href="javascript:void(0);"  class="ti-dropdown-item"  >Détail des lignes saisies </a></li>
                                           <li><a href="javascript:void(0);"  class="ti-dropdown-item"  >Pièces déséquilibrées </a></li>
                                           <li><a href="javascript:void(0);"  class="ti-dropdown-item"  >Cummul des pièces saisies </a></li>

                                           <div class="dropdown-divider"></div>

                                           <li><a href="javascript:void(0);"  class="ti-dropdown-item"  >Cummul par comptes individuels</a></li>
                                           <li><a href="javascript:void(0);"  class="ti-dropdown-item"  >Cummul par comptes de synthèse</a></li>


                                           <div class="dropdown-divider"></div>

                                           <li><a href="javascript:void(0);"  class="ti-dropdown-item"  >Cummul par tiers</a></li>
                                           <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Cummul par centres analytiques</a></li>

                                       </ul>
                                   </div>
                                </li>



                                <li  >
                                 <div class="hs-dropdown ti-dropdown">
                                     <a class="ti-dropdown-item" href="javascript:void(0);">
                                         Comptes généraux <i  class="ri-arrow-right-s-line align-middle ms-1 inline-block"></i>
                                     </a>
                                     <ul class="submenu hs-dropdown-menu ti-dropdown-menu hidden"
                                         aria-labelledby="dropdownMenuButton1">

                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Balance générale des comptes </a></li>
                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Balance de synthèse </a></li>
                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Balance agée </a></li>

                                         <div class="dropdown-divider"></div>

                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Grand-livre des comptes</a></li>
                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Historique des comptes sur plusieurs exercices</a></li>

                                         <div class="dropdown-divider"></div>

                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Journal des reports</a></li>
                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Balance d'ouverture</a></li>
                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Situation des comptes</a></li>
                                         <div class="dropdown-divider"></div>

                                         <li><a  href="javascript:void(0);" class="ti-dropdown-item"  >Balance détaillée analytiquement</a></li>







                                     </ul>
                                 </div>
                                </li>

                                <li >
                                   <div  class="hs-dropdown ti-dropdown">
                                       <a class="ti-dropdown-item" href="javascript:void(0);">
                                           Tiers <i  class="ri-arrow-right-s-line align-middle ms-1 inline-block"></i>
                                       </a>
                                       <ul class="submenu hs-dropdown-menu ti-dropdown-menu hidden"
                                           aria-labelledby="dropdownMenuButton1">



                                           <li><a @click="btnmodaleditionscafrsclients ('CLT')"  class="ti-dropdown-item"  >CA client sur une période </a></li>
                                           <li><a @click="btnmodalcaparproduitparmois()"  class="ti-dropdown-item"  >CA par produit sur une période </a></li>
                                           <li><a @click="btnmodalcaparmoisbydoc()"  class="ti-dropdown-item"  >CA sur une periode par mois </a></li>
                                          <div class="dropdown-divider"></div>
                                           <li><a  class="ti-dropdown-item"  >Balance des tiers </a></li>
                                           <li><a  class="ti-dropdown-item"  >Grand-livre </a></li>
                                           <div class="dropdown-divider"></div>

                                           <li><a  class="ti-dropdown-item"  >Balance d'ouverture</a></li>
                                           <li><a  class="ti-dropdown-item"  >Situation des comptes</a></li>

                                           <div class="dropdown-divider"></div>

                                           <li><a  class="ti-dropdown-item"  >Décomposition des soldes <br> fournisseurs par nature</a></li>
                                           <li><a  class="ti-dropdown-item"  >Décomposition des soldes <br> clients par nature</a></li>
                                           <div class="dropdown-divider"></div>
                                           <li><a  class="ti-dropdown-item"  >Relévé de compte</a></li>
                                           <li><a  class="ti-dropdown-item"  >Justification de solde</a></li>

                                       </ul>
                                   </div>
                                </li>


                                <li >
                                    <div  class="hs-dropdown ti-dropdown">
                                        <a class="ti-dropdown-item" href="javascript:void(0);">
                                            Fiche de déclarations <i  class="ri-arrow-right-s-line align-middle ms-1 inline-block"></i>
                                        </a>
                                        <ul class="submenu hs-dropdown-menu ti-dropdown-menu hidden"
                                            aria-labelledby="dropdownMenuButton1">
                                            <li><a @click="btnmodaldeclarationaib('aib')"  class="ti-dropdown-item"  >Fiche de déclaration d'aib sur vente</a></li>
                                            <li><a @click="btnmodaldeclarationaib('tva')"  class="ti-dropdown-item"  >Fiche de déclaration de la tva sur vente</a></li>
                                            <div class="dropdown-divider"></div>

                                        </ul>
                                    </div>
                                </li>






                            </ul>
                        </div>

                    </li>





                    <li  >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block
                            py-2 px-3 text-gray-900 rounded hover:bg-gray-100
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700
                            md:p-0 dark:text-white md:dark:hover:text-blue-500
                            dark:hover:bg-gray-700 dark:hover:text-white
                            md:dark:hover:bg-transparent"

                                 aria-expanded="false">
                                Générer des écritures<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                       Générer des écritures
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </li>

                    <li  >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block
                            py-2 px-3 text-gray-900 rounded hover:bg-gray-100
                            md:hover:bg-transparent md:border-0 md:hover:text-blue-700
                            md:p-0 dark:text-white md:dark:hover:text-blue-500
                            dark:hover:bg-gray-700 dark:hover:text-white
                            md:dark:hover:bg-transparent"

                                 aria-expanded="false">
                                Paramétre<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">

                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        Journaux comptables
                                    </a>
                                </li>

                                <li>
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                      Plan comptable général
                                    </a>
                                </li>

                                <li>
                                    <a @click="btnmodalparametregenererecriture"  class="ti-dropdown-item" href="javascript:void(0);">
                                       Génération des écritures comptable
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </li>



                </ul>
            </div>
        </div>
    </nav>

    <moadalcafrsclients ref="moadalcafrsclients"></moadalcafrsclients>
    <moadalcaparmoisbydoc ref="moadalcaparmoisbydoc"></moadalcaparmoisbydoc>
    <modalcaparproduitparmois ref="modalcaparproduitparmois"></modalcaparproduitparmois>
    <modaldeclarationaib ref="modaldeclarationaib"></modaldeclarationaib>
    <modalparametregenererecriture ref="modalparametregenererecriture"></modalparametregenererecriture>

    <modaldefinitioncltfrs ref="modaldefinitioncltfrs"></modaldefinitioncltfrs>
    <modalindexsaisiejournaux ref="modalindexsaisiejournaux"></modalindexsaisiejournaux>

</template>


<script>

    import modaldeclarationaib from "@/components/views/pages/comptabilite/editions/modal_declarationaib";
    import moadalcafrsclients from "@/components/views/pages/comptabilite/editions/modal_ca_frs_clients";
    import moadalcaparmoisbydoc from "@/components/views/pages/comptabilite/editions/modal_ca_par_mois_by_doc";
    import modalcaparproduitparmois from "@/components/views/pages/comptabilite/editions/modal_ca_par_produit_par_mois";
    import modalparametregenererecriture from "@/components/views/pages/comptabilite/parametre/modal_parametre_generer_ecriture";



    import modaldefinitioncltfrs from "@/components/views/pages/comptabilite/definition/cltfrs/index";
    import modalindexsaisiejournaux from "@/components/views/pages/comptabilite/saisies/journaux/index";




    export default {
        /* eslint-disable */
        name: "mymenu",
        data() {
            return {
                isNavbarOpen: false,
                isDropdownOpen: true
            };
        },
        components: {
            moadalcafrsclients,
            moadalcaparmoisbydoc,
            modalcaparproduitparmois,
            modaldeclarationaib,
            modalparametregenererecriture,


            modaldefinitioncltfrs,
            modalindexsaisiejournaux,
        },
            methods: {




            btnmodaldefinitioncltfrs(value) {

                let titlemodal


                if (value==='clt'){
                    titlemodal="Liste des clients"
                }

                if (value==='frs'){
                    titlemodal="Liste des fournisseurs"
                }

                this.$refs.modaldefinitioncltfrs.show(titlemodal,value);
            },


            btnmodaldeclarationaib(value) {

                this.$refs.modaldeclarationaib.show(value);
            },


            btnmodalindexsaisiejournaux() {

                this.$refs.modalindexsaisiejournaux.show();
            },


            btnmodaleditionscafrsclients(value) {

                this.$refs.moadalcafrsclients.show(value);
            },


            btnmodalcaparproduitparmois() {

                this.$refs.modalcaparproduitparmois.show();

            },


            btnmodalcaparmoisbydoc() {

                this.$refs.moadalcaparmoisbydoc.show();

            },


            btnmodalparametregenererecriture() {

                this.$refs.modalparametregenererecriture.show();

            },


            toggleNavbar() {
                this.isNavbarOpen = !this.isNavbarOpen;
            },


            toggleDropdown() {
                //console.log("1122")

                this.isDropdownOpen = !this.isDropdownOpen;

                //console.log("1122 this.isDropdownOpen ",this.isDropdownOpen)
            }
        }
    };
</script>

<style scoped>

    li {
        cursor: pointer;
    }

     /*@import url('https://cdn.tailwindcss.com');
    @import url("@/assets/css/tailwindv2_0_1.css");*/

    .menutop{
        /*margin-top: 15px!important;*/
        margin-top: 4% !important;
    }

     button:focus {

         outline: 0px auto -webkit-focus-ring-color!important;
     }

     .submenu{
         min-width: 18rem;
     }

     @media screen and (max-width: 800px) {
         .menutop{
             /*margin-top: 15px!important;*/
             margin-top: 12.5% !important;
         }
     }





</style>